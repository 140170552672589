import React from 'react';
import { Tooltip } from '@material-ui/core';
import useDimension from '../../customHooks/useDimension';
import InfoIcon from '../../assets/images/icons/info_icon.svg';

export default function TooltipInfo({ text, html = null }) {
  const dimension = useDimension();

  return (
    <Tooltip enterTouchDelay={50} placement={dimension.width > 768 ? 'right-end' : 'top'}
      title={
        <React.Fragment>
          <div className="content-tooltip">
            { html
              ? <span dangerouslySetInnerHTML={{ __html: html }}/>
              : <p>{text}</p>
            }
          </div>
        </React.Fragment>
      }>
      <span className="tooltip"><img src={InfoIcon}/></span>
    </Tooltip>

  );
}
