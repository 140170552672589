import React, { useState, useRef, useImperativeHandle } from 'react';
import { MdDeleteForever } from 'react-icons/md';

const forwardModuleUploadDocs = React.forwardRef(ModuleUploadDocs);
export default forwardModuleUploadDocs;

function ModuleUploadDocs(props, ref) {
  const inputFiles = useRef();
  const [fileName, setFileName] = useState(props.defaultFilename || null);
  const [errorSize, setErrorSize] = useState('');

  useImperativeHandle(ref, () => ({
    setFileName: (fileName) => setFileName(fileName),
  }));

  function upload() {
    inputFiles.current.click();
  }

  async function handleChange(e) {
    const file = e.target.files[0];
    let messageError = '';

    if (file) {
      const fileSize = e.target.files.item(0).size;
      const fileMb = fileSize / 1024 ** 2;
      if (fileMb >= 4) {
        messageError = 'Le fichier doit faire moins de 4Mo';
        setErrorSize(messageError);
        return;
      }
    }

    if (props.handleChange) {
      props.handleChange(props.isMultiple ? e.target.files : e.target.files[0]);
    }

    let fileName;
    if (props.isMultiple) {
      const arr = Array.from(e.target.files);
      const names = arr.map((f) => f.name);

      fileName = names.join(' / ');
    } else {
      fileName = file.name;
    }

    setErrorSize(messageError);
    setFileName(fileName);
  }

  const removeFile = () => {
    props.handleChange(null);
    setFileName();
  }

  return (
    <div className="upload-doc">
      { fileName && (
        <p className="filename">
          {fileName}
          {props.isClearable && <span className='remove-file' onClick={removeFile}><MdDeleteForever size={24} /></span>}
        </p>
      )}
      <input
        accept={props.accept || "image/png, image/jpeg, application/pdf"}
        className="hide"
        onChange={(e) => handleChange(e)}
        ref={inputFiles}
        type="file"
        multiple={props.isMultiple ? props.isMultiple : false}
      />
      <button onClick={upload}>
        {props.buttonText
          ? props.buttonText
          : <>{props.isMultiple ? 'Joindre des fichiers' : 'Joindre un fichier'}</>
        }
      </button>
      {errorSize && (
        <p className='error-file'>{errorSize}</p>
      )}
      {props.children}
    </div>
  );
}
