import React from 'react';

export default function ButtonClose({ closingAction }) {
  return (
    <div className={'button-close'} onClick={closingAction}>
      <div className="dash"></div>
      <div className="dash"></div>
      <div className="dash"></div>
      <div className="dash"></div>
    </div>
  );
}
