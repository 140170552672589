import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useInitCompany from '../../customHooks/useInitCompany';

import Loader from '../partials/Loader';

export default function HandleRole(props) {
  const initCompany = useInitCompany();
  const history = useHistory();

  useEffect(() => {
    if (!initCompany.type || !initCompany.role) return;

    if (initCompany.type === 'fournisseur' || initCompany.type === 'magasin') {
      history.push('/salon');
    }

    // if(initCompany.type === "fournisseur"){
    //   history.push("/subscribe")
    //   // if(initCompany.role === "Member"){
    //   //   history.push("/salon")
    //   // }else{
    //   //   history.push("/subscribe")
    //   // }
    // }

    // if(initCompany.type === "magasin"){
    //   history.push("/store/inscription")
    // }
  }, [initCompany.type, initCompany.role]);

  return (
    <Loader/>
  );
}
