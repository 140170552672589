import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { API_URL } from '../../../../actions/index.js';
import Block from './Block';
import Loader from '../../../partials/Loader';

import { getAllAnimations } from '../../../../actions/animation';
import { useGlobalContext } from '../../../GlobalContext';

export default function Animations() {
  const [context, dispatch] = useGlobalContext();
  const { animationReducer } = context;
  const [animations, setAnimations] = useState();

  // ACTIONS
  const _getAllAnimations = () => getAllAnimations(dispatch);

  useEffect(() => {
    _getAllAnimations();
  }, []);

  useEffect(() => {
    if (animationReducer.isLoading || !animationReducer.animations) return;
    const found = animationReducer.animations.filter((animation) => (
      animation.isValid === 'valid'
    ));

    const sort = _.orderBy(found, (d) => d?.company?.domains[0], 'desc');

    const animMultimedia = [];
    const animMaison = [];
    const animMultimediaMaison = [];
    const animExpertise = [];
    const animEconomat = [];
    const animOccasions = [];
    const rest = [];

    found.forEach((animation) => {
      if (
        animation.company.domains.includes('MULTIMEDIA')
        && animation.company.domains.includes('MAISON')
      ) {
        animMultimediaMaison.push(animation);
      } else if (animation.company.domains.includes('MULTIMEDIA')) {
        animMultimedia.push(animation);
      } else if (animation.company.domains.includes('MAISON')) {
        animMaison.push(animation);
      } else if (animation.company.domains.includes('EXPERTISE')) {
        animExpertise.push(animation);
      } else if (animation.company.domains.includes('ECONOMAT')) {
        animEconomat.push(animation);
      } else if (animation.company.domains.includes('OCCASION')) {
        animOccasions.push(animation);
      } else {
        rest.push(animation);
      }
    });

    setAnimations([
      ..._.orderBy(animMultimedia, 'company.name', 'asc'),
      ..._.orderBy(animMultimediaMaison, 'company.name', 'asc'),
      ..._.orderBy(animMaison, 'company.name', 'asc'),
      ..._.orderBy(animExpertise, 'company.name', 'asc'),
      ..._.orderBy(animEconomat, 'company.name', 'asc'),
      ..._.orderBy(animOccasions, 'company.name', 'asc'),
      ..._.orderBy(rest, 'company.name', 'asc'),
    ]);
  }, [animationReducer.animations]);

  return (
    <div className="content animations">
      {animations
        ? <>{animations.map((animation) => (
          <React.Fragment key={`animation-${animation._id}`}>
            <Block
              stand={animation.showroomNumber}
              provider={animation.company.name}
              banner={animation.banner}
              files={animation.files}
              domains={animation.company.domains}
            >
              <div>
                {animation.banner
                  && <p className='banner'>{animation.banner}</p>
                }
                {animation.files
                  && <>
                    {animation.files.map((file, i) => (
                      <React.Fragment key={`animation-img-${animation._id}-${i}`}>
                        {!/\w+(.pdf)$/.test(file)
                          ? <div className="container-img"><img src={`${API_URL}/files/${file}`} alt={`img-animation-${animation.company.name}`} /></div> : null
                        }
                      </React.Fragment>
                    ))}
                  </>
                }
              </div>

            </Block>
          </React.Fragment>
        ))}
        </> : <Loader />
      }

    </div>
  );
}
