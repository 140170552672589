import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';

export default function InputNumber(props) {
  const [state, toggle] = useState(true);
  const { x } = useSpring({ from: { x: 0 }, x: state ? 1 : 0, config: { duration: 300 } });

  function handleClick(e, action) {
    e.stopPropagation();
    e.preventDefault();
    if (props.disabled) return;

    let x = parseInt(props.value);
    if (action === 'inc') {
      x++;
    } else if (action === 'decr' && props.value > 0) {
      x--;
    }
    toggle(!state);
    props.handleChange(x);
  }

  function handleChange(e, val) {
    e.stopPropagation();
    e.preventDefault();
    if (props.disabled) return;
    toggle(!state);

    if (val < 0 || val === '') {
      props.handleChange(0);
    } else {
      props.handleChange(parseInt(val));
    }
  }

  return (
    <div className={props.disabled ? 'input-number disabled' : 'input-number'}>
      {!props.disabled && <button onClick={(e) => handleClick(e, 'decr')}>-</button>}
      <animated.input
        disabled={props.disabled}
        style={{
          transform: x
            .interpolate({
              range: [0, 0.5, 1],
              output: [1, 1.15, 1],
            })
            .interpolate((x) => `scale(${x})`),
        }}
        type="number"
        onChange={(e) => handleChange(e, e.target.value)}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        min={'0'} value={props.value}
      />
      {!props.disabled && <button onClick={(e) => handleClick(e, 'inc')}>+</button>}
      <p className="unit">colis</p>

    </div>
  );
}
