import React from 'react';
import Checkbox from '../../partials/form_fields/Checkbox';
import useDimension from '../../../customHooks/useDimension';

export default function Conditions(props) {
  const dimension = useDimension();

  function toggleCheckbox(val) {
    props.setIsChecked(!val);
    if (!val) {
      const timer = setTimeout(() => {
        if (props.goTo) props.goTo(1);
        clearTimeout(timer);
      }, 700);
    }
  }

  return (
    <div className="conditions">
      <div className="content">
        {!props.mobile
          ? <h3>
            Information montage / demontage
          </h3> : null
        }
        <p>
          Les consignes de montage / démontage ainsi que les macarons d’accès au site sont
           disponibles dans les fichiers utiles ci-dessous.<br/>
          Vous trouverez ci-contre un planning pour faciliter l’installation de votre stand.
        </p>
        <p className="medium">
          Merci d’en prendre connaissance attentivement et de cocher la case ci-dessous.
        </p>
        { dimension.width < 1200
          && <Checkbox
            value={props.isChecked}
            toggleCheckbox={(val) => toggleCheckbox(val)}
            label={'J’ai bien pris connaissance des conditions ExpoNantes sur la gestion des déchets et m’engage à les respecter.'}
          />
        }

      </div>
    </div>
  );
}
