import React, {
  useMemo, useState, useEffect, useRef,
} from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { withLocalize } from 'react-localize-redux';
import { Switch, Route } from 'react-router-dom';
import translations from '../assets/translations/translation.json';
import { useGlobalContext } from './GlobalContext';
import usePrevious from '../customHooks/usePrevious';
import { getUser } from '../actions/auth';
import { getCompanyList } from '../actions/company';
import { getExhibition } from '../actions/exhibition';

import HandleRole from './HandleRole';
import Redirect from './pages/Redirect';

// MAGASIN
import StoreSwitch from './pages/Store/StoreSwitch';
import ListProviders from './pages/Store/ListProviders/ListProviders';
import Orders from './pages/Store/Orders/Orders';
import Order from './pages/Store/Orders/Order';
import Badges from './pages/Store/Badges/Badges';
import Programme from './pages/Store/Programme/Programme';
import Avis from './pages/Avis/Avis';
import Plan from './pages/Plan/Plan';

import QuizzSwitch from './pages/Store/Quizz/QuizzSwitch';
import ValidateOnMobile from './pages/Store/Orders/ValidateOnMobile';
import ProductsSwitch from './pages/Store/Products/ProductsSwitch';

// FOURNISSEUR
import Subscribe from './pages/Provider/Subscription/Subscribe';
import Contributors from './pages/Provider/Contributors/Contributors';
import StandForm from './pages/Provider/Stand/StandForm';
import ProductsProvider from './pages/Provider/ProductsProvider/ProductsProvider';
import AddProduct from "./pages/Provider/EditProduct";

import Animations from './pages/Animations/Animations';

import Informations from './pages/Informations/Informations';
import Trophy from './pages/Trophy/Trophy';
import Salon from './pages/Salon/Salon';
import Montage from './pages/Montage/Montage';

import CGU from './pages/CGU';

import ResetPassword from './Auth/ResetPassword';
import ForgotPassword from './Auth/ForgotPassword';

import Login from './Auth/Login';
import LoginAcdlec from './Auth/LoginAcdlec';

import SelectType from './Auth/SelectType';

import IconClose from '../assets/images/icons/icon-close.svg';

const OrderReadOnly = ({ props }) => <Order readOnly {...props} />;

const RedirectSalon = (props) => {
  useEffect(() => {
    props.history.push('/salon');
  }, [props]);
  return null;
}

const ContainerSwitch = (props) => {
  const [context, dispatch] = useGlobalContext();
  const { authReducer, exhibitionReducer } = context;
  const { exhibition } = exhibitionReducer;
  let offersActive = null;
  if (exhibition) offersActive = exhibition.offersActive;

  // ACTIONS
  const _getUser = () => getUser(dispatch);
  const _getCompanyList = () => getCompanyList(dispatch);
  const _getExhibition = () => getExhibition(dispatch);

  useEffect(() => {
    _getExhibition();

    props.initialize({
      languages: [
        { name: 'English', code: 'en' },
        { name: 'French', code: 'fr' },
      ],
      translation: translations,
      options: { renderToStaticMarkup },
    });
  }, []);

  useEffect(() => {
    const id = localStorage.getItem('id');
    const token = localStorage.getItem('token');
    const company = localStorage.getItem('company');

    if (!authReducer.user
      && (id && token && company)
    ) {
      _getUser();
      _getCompanyList();
    }
  }, [authReducer.user]);

  return useMemo(
    () => (
      <>
        {context.themeReducer.reglementQuizz && <ReglementQuizz />}
        <Switch>
          <Route exact path="/" component={SelectType} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/acdlec/" component={LoginAcdlec} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/subscribe" render={
            () => <HandleRole companyType="fournisseur"><Subscribe {...props} /></HandleRole>
          } />
          <Route exact path="/contributors" render={
            () => <HandleRole companyType="fournisseur"><Contributors {...props} /></HandleRole>
          } />
          <Route exact path="/stand/:step/:part?" render={
            (props) => <HandleRole companyType="fournisseur"><StandForm {...props} /></HandleRole>
          } />
          <Route exact path="/animations" render={
            () => <HandleRole companyType="fournisseur"><Animations {...props} /></HandleRole>
          } />
          <Route exact path="/montage" render={
            () => <HandleRole companyType="fournisseur"><Montage {...props} /></HandleRole>
          } />
          <Route exact path="/products-provider" render={
            () => <HandleRole companyType="fournisseur"><ProductsProvider {...props} /></HandleRole>
          } />
          <Route exact path="/edit-product-provider/:id?" render={
            () => <HandleRole companyType="fournisseur"><AddProduct {...props} /></HandleRole>
          } />
          <Route exact path="/store/:page?/:subpage?" render={
            () => <HandleRole companyType="magasin"><StoreSwitch {...props} /></HandleRole>
          } />
          <Route exact path="/products/:page?" render={
            () => {
              if (offersActive === null) return null;
              if (typeof offersActive === 'boolean' && offersActive === false) return <RedirectSalon {...props} />;
              return <HandleRole companyType="magasin"><ProductsSwitch {...props} /></HandleRole>;
            }
          } /> 
          <Route exact path="/orders" render={
            () => <HandleRole companyType="magasin"><Orders {...props} /></HandleRole>
          } />
          <Route exact path="/order/detail/:id" render={
            () => {
              if (offersActive === null) return null;
              if (typeof offersActive === 'boolean' && offersActive === false) return <RedirectSalon {...props} />;
              return <HandleRole companyType="magasin"><OrderReadOnly {...props} /></HandleRole>
            }
          } />
          <Route exact path="/order/:id" render={
            () => {
              if (offersActive === null) return null;
              if (typeof offersActive === 'boolean' && offersActive === false) return <RedirectSalon {...props} />;
              return <HandleRole companyType="magasin"><Order {...props} /></HandleRole>
            }
          } />
          <Route exact path="/list-providers" render={
            () => <HandleRole companyType="magasin"><ListProviders {...props} /></HandleRole>
          } />
          <Route exact path="/programme/:page?" render={
            () => <HandleRole companyType="magasin"><Programme {...props} /></HandleRole>
          } />
          <Route exact path="/badges" render={
            () => <HandleRole><Badges {...props} /></HandleRole>
          } />
          {/*
          <Route exact path="/quizz/:page?/:subpage?" render={
            () => <HandleRole companyType="magasin"><QuizzSwitch {...props} /></HandleRole>
          } />
          */}
          <Route exact path="/plan" render={
            () => <HandleRole ><Plan {...props} /></HandleRole>
          } />
          {/* <Route exact path="/avis/:page?" render={
            () => <HandleRole ><Avis {...props} /></HandleRole>
          } /> */}

          <Route exact path="/trophy" render={
            () => <HandleRole companyType="magasin"><Trophy {...props} /></HandleRole>
          } />
          <Route exact path="/informations/:page?" render={
            () => <HandleRole><Informations {...props} /></HandleRole>
          } />
          <Route exact path="/salon" render={
            () => <HandleRole><Salon {...props} /></HandleRole>
          } />
          <Route exact path="/cgu" component={CGU} />
          <Route exact path="/redirect" component={Redirect} />
        </Switch>
      </>
    )
    // eslint-disable-next-line
  , [
    offersActive,
    props.location.pathname,
    context.themeReducer.reglementQuizz
  ],
  );
};

const ReglementQuizz = () => {
  const dispatch = useGlobalContext()[1];

  function close() {
    dispatch({
      type: 'MODAL_REGLEMENT_QUIZZ',
      payload: false,
    });
  }

  return (
    <div className="container-reglement">
      <div className="overlay" onClick={close}></div>
      <div className="modal">
        <img onClick={close} src={IconClose} alt="close réglement" />
        <h3>Règlement du jeu</h3>
        <p>
          Proin euismod metus vitae fringilla ullamcorper. Integer vulputate blandit est, sed placerat enim pulvinar eget. Sed tristique vehicula elit elementum venenatis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Fusce non tortor blandit, mattis nibh in, mattis risus. Vestibulum placerat, urna vitae vestibulum vulputate, nibh elit suscipit justo, id sollicitudin leo erat at purus. Duis bibendum sollicitudin justo. Sed vitae ipsum massa. Vestibulum quis ex sapien. Morbi turpis metus, cursus ac leo vitae, tincidunt pellentesque libero.
        </p>
        <p>
          Nulla hendrerit, metus a luctus malesuada, ipsum leo maximus nulla, interdum laoreet urna enim ut turpis. Donec eu rutrum diam. Quisque a velit eget arcu malesuada faucibus sit amet a mauris. Etiam pulvinar eros sit amet risus hendrerit, sed feugiat massa eleifend. Phasellus felis augue, rutrum eu sollicitudin sed, blandit et elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Sed aliquet urna eros, eget tristique neque vehicula ac. Fusce nunc sapien, bibendum eget condimentum sed, scelerisque vel elit. In hac habitasse platea dictumst.
        </p>
      </div>
    </div>
  );
};

export default withLocalize(ContainerSwitch);
