import React from 'react';
import { LocalizeProvider } from 'react-localize-redux';
import { HashRouter, Route } from 'react-router-dom';

// import { Router, Route, NavLink } from "react-router-dom";
// import history from '../history';

import '../scss/App.scss';
import { GlobalProvider } from './GlobalContext';
import ContainerSwitch from './ContainerSwitch';

import authReducer from '../reducers/authReducer';
import usersReducer from '../reducers/usersReducer';
import companyReducer from '../reducers/companyReducer';
import showRoomReducer from '../reducers/showRoomReducer';
import animationReducer from '../reducers/animationReducer';
import montageReducer from '../reducers/montageReducer';
import productsReducer from '../reducers/productsReducer';
import providerReducer from '../reducers/providerReducer';
import notificationsReducer from '../reducers/notificationsReducer';
import themeReducer from '../reducers/themeReducer';
import exhibitionReducer from '../reducers/exhibitionReducer';

const reducers = combineReducers({
  authReducer,
  usersReducer,
  companyReducer,
  animationReducer,
  showRoomReducer,
  montageReducer,
  productsReducer,
  providerReducer,
  notificationsReducer,
  themeReducer,
  exhibitionReducer,
});

function combineReducers(reducers) {
  const reducerKeys = Object.keys(reducers);

  return function combination(state = {}, action) {
    const nextState = {};
    for (let i = 0; i < reducerKeys.length; i++) {
      const key = reducerKeys[i];
      const reducer = reducers[key];
      const previousStateForKey = state[key];
      const nextStateForKey = reducer(previousStateForKey, action);
      nextState[key] = nextStateForKey;
    }

    return nextState;
  };
}

const initialState = reducers({}, { type: '__INIT__' });

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <GlobalProvider reducers={reducers} initialState={initialState}>
          <LocalizeProvider>
            <HashRouter>
              <Route path="/" render={(props) => (
                <ContainerSwitch {...props}/>
              )} />
            </HashRouter>
          </LocalizeProvider>
        </GlobalProvider>
      </div>
    );
  }

  // return (
  //   <div className="App">
  //     <Router history={history}>
  //       <Route path="/" render={(props)=>(
  //         <ContainerSwitch {...props}/>
  //       )} />
  //     </Router>
  //   </div>
  // )
}

export default App;
