/* eslint-disable */
import {
  getData,
  postData,
  putDataFormData,
  postDataFormData,
  // putData,
} from './index';

import {
  GET_ANIMATIONS,
  GET_ANIMATION,
  ADD_ANIMATION,
  UPDATE_ANIMATION,
  LOADING_ANIMATION,
  DELETE_FILE,
  ERROR_ANIMATION
} from "./types"

// ONLY USE BY STORE TO SHOW PROGRAMME ANIMATIONS
export async function getAllAnimations(dispatch,id){
  let url = '/animation'

  dispatch({
    type : LOADING_ANIMATION,
  })

  let animations;
  await getData( ERROR_ANIMATION, url, dispatch , true).then((response)=>{
    animations = response.data
  })

  dispatch({
    type : GET_ANIMATIONS,
    payload : animations
  })

}

export async function getAnimation(dispatch,id){
  let url = '/animation/'+id

  dispatch({
    type : LOADING_ANIMATION,
  })

  let animation;
  await getData( ERROR_ANIMATION, url, dispatch , true).then((response)=>{
    animation = response
  })

  dispatch({
    type : GET_ANIMATION,
    payload : animation
  })

}

export async function addAnimation(dispatch,data,type){
  let url = '/animation'

  dispatch({
    type : LOADING_ANIMATION,
    origin : type
  })

  let animation;
  await postDataFormData( ERROR_ANIMATION, true, url, dispatch , data).then((response)=>{
      animation = response
  })

  dispatch({
    type : ADD_ANIMATION,
    payload : {...animation, type}
  })

}

export async function deleteFile(dispatch,id,fileName){
  let url = '/animation/removefile/'+id

  let file;
  await postData( ERROR_ANIMATION, true, url, dispatch , {fileName}).then((response)=>{
    file = response
  })

  dispatch({
    type : DELETE_FILE,
    payload : id+"/"+fileName
  })
}

export async function updateAnimation(dispatch,data,type){
  let url = '/animation/'+data.get("id")

  dispatch({
    type : LOADING_ANIMATION,
    origin : type
  })

  let animation;
  await putDataFormData( ERROR_ANIMATION, true, url, dispatch , data).then((response)=>{
      animation = response
  })

  dispatch({
    type : UPDATE_ANIMATION,
    payload : {...animation, type}
  })

}
