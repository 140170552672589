import React, { useState, useEffect } from 'react';
import { Skeleton } from '@material-ui/lab';
import IconCocktail from '../../../assets/images/icons/icon-cocktail-white.svg';
import IconCocktailBlue from '../../../assets/images/icons/icon-cocktail.svg';

const ItemsLoading = ({ width = '100px' }) => (
  <li>
    <Skeleton variant="rect" height={37} width={width}
      style={{
        marginBottom: 10,
        marginRight: 10,
        borderRadius: 12,
      }}
    />
  </li>
);

export default function SelectItems(props) {
  const [selected, setSelected] = useState(props.selectedItems || []);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      return;
    }

    if (props.selectedItems === null) {
      setSelected([]);
    }

    if (props.selectedItems) {
      setSelected(props.selectedItems);
      // if (props.handleChange) {
      //   const error = fnError(props.selectedItems);
      //   // props.handleChange(props.selectedItems,error)
      // }
    }
  }, [props.selectedItems]);

  function fnError(arr) {
    if (!props.required || arr.length > 0) return false;
    if (arr.length === 0) return true;
  }

  function toggleItems(id, isSelected) {
    let arr;
    if (props.uniq) {
      if (!isSelected) {
        arr = [id];
      } else {
        arr = [];
      }
    } else {
      arr = [...selected];
      if (!isSelected) {
        arr.push(id);
      } else {
        arr = arr.filter((j) => j !== id);
      }
    }

    if (props.handleChange) {
      const error = fnError(arr);
      if (props.uniq) {
        if (!props.required) {
          props.handleChange(arr[0] === undefined ? null : arr[0], error);
        } else if (props.required && arr[0] !== undefined) {
          props.handleChange(arr[0], error);
        }
      } else {
        props.handleChange(arr, error);
      }
    }
  }

  function isSelected(id) {
    if (Array.isArray(selected)) {
      return selected.includes(id);
    }
    return selected === id;
  }

  function getClass(item) {
    let disabled = '';
    if (props.disabledItems) {
      disabled = props.disabledItems.includes(item) ? 'disabled' : '';
    }
    return isSelected(item) ? `selected ${disabled}` : ` ${disabled}`;
  }

  return (
    <div className="select-items">
      { !props.loading
        ? <ul>
          {props.items
            ? <>
            {props.items.map((item, i) => (
              <li
               key={`${item}-${i}`}
               className={getClass(item)}
               onClick={() => toggleItems(item, isSelected(item))}
               id={item}>
               <span>
                &nbsp;{item}&nbsp;
                {item === 'Cocktail'
                  ? <>
                  { isSelected(item)
                    ? <img key="cocktail selected" src={IconCocktail} alt="cocktail"/>
                    : <img key="cocktail" src={IconCocktailBlue} alt="cocktail"/>
                  }
                  </> : null
                }
               </span>
              </li>
            ))}
            </> : null
          }
        </ul>
        : <ul className="loading">
          <ItemsLoading width="90px" />
          <ItemsLoading width="110px" />
          <ItemsLoading width="60px" />
          <ItemsLoading width="90px" />
          <ItemsLoading width="60px" />
          <ItemsLoading width="50px" />
        </ul>
      }
    </div>
  );
}
