import _ from 'lodash';
import format from 'format-number';

export function getProductsInShoppingList(products, shoppingList, uniq) {
  const productsInShoppingList = [];

  products.forEach((product) => {
    const find = _.get(shoppingList, [product.company._id, product._id]);
    if (find && find.count > 0) {
      if (uniq) {
        productsInShoppingList.push(product);
      } else {
        const arr = new Array(find.count).fill(product, 0, find.count);
        productsInShoppingList.push(...arr);
      }
    }
  });

  return productsInShoppingList;
}

export const downloadProductsGencod = async (params) => {
  const config = {
    headers: {
      Authorization: `${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      // responseType: 'blob',
    },
    method: "POST",
    body: JSON.stringify(params),
  };

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/export/gencod`, config);
    const contentDisposition = response.headers.get('Content-Disposition');
    let fileName = 'products-gencod.xlsx';
    if (contentDisposition) {
      fileName = response.headers.get('content-disposition')
        .split(';')
        .find((n) => n.includes('filename='))
        .replace('filename=', '')
        .trim();
    }

    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (e) {
    console.log(e);
  }
};

export function fnFormat(number) {
  return format({ integerSeparator: ' ' })(number.toFixed(2));
}

export function fnFormatFixed(number) {
  let value = parseInt(number, 10);
  // USE ON DISCOUNT %
  // let value = format({integerSeparator: " "})(number.toFixed(1));
  value = format({ integerSeparator: ' ' })(value.toFixed(0));
  return value;
  // if(/.0/.test(value)){
  //   return value.replace(".0","")
  // }else{
  //   return value
  // }
}
