import axios from 'axios';
import { getData } from './index';

import {
  GET_PROVIDER,
  PROVIDER_LOADING,
  ERROR_PROVIDER,
} from './types';

export async function getProviders(dispatch, id) {
  const url = '/company/fournisseur';
  let providers;

  dispatch({
    type: PROVIDER_LOADING,
  });

  await getData(ERROR_PROVIDER, url, dispatch, true).then((response) => {
    providers = response.data.companies;
  });

  dispatch({
    type: GET_PROVIDER,
    payload: providers,
  });
}
