import React from 'react';

import Block from './Block';
import SupportContact from '../../partials/SupportContact';

export default function Hebergements() {
  return (
    <>
      <div className="content large">
        <div className="fullwidth">
          <p>Les hôtels ci-dessous, proches d'Exponantes vous proposent des avantages
             tarifaires sur<br/>
           présentation du code&nbsp;: <b>BTLec Ouest 2024</b></p>
        </div>
        <div className="col">
          <Block
            title = "Sure Hotel by Best Western Nantes Beaujoire"
          >
            <p>À 2,6 km du parc des expositions de la Beaujoire.<br/>
            27 rue du Chemin Rouge, 44300 Nantes</p>
            <p><b>104€</b> au lieu de 130€</p>
            <p className="tel">Téléphone: <a href="tel:02 14 00 07 89">02 14 00 07 89</a></p>
          </Block>
          <Block
            title = "Brit Hôtel 4*"
          >
            <p>1km du parc des Expositions de la Beaujoire<br/>
            45 Boulevard des Batignolles, 44300 Nantes</p>
            <p><b>170€ B&B</b> au lieu de 197,50€ (soit -14%)</p>
            <p className="tel">Téléphone: <a href="tel:02 40 50 07 07">02 40 50 07 07</a></p>
          </Block>
        </div>
        <div className="col">
          <Block
            title = "Ibis Beaujoire"
          >
            <p>À 1,7 km du parc des expositions de la Beaujoire.<br/>
            5 rue du Moulin de la halvèque, 44300 Nantes</p>
            <p>
              <b>140€</b> au lieu de 152€
            </p>
            <p className="tel">Téléphone: <a href="tel:02 40 93 22 22">02 40 93 22 22</a></p>
          </Block>
        </div>
        <div className="col">
          <Block
            title = "L'hôtel Zenitude la Beaujoire"
          >
            <p>À 2,6 km du parc des expositions de la Beaujoire.<br/>
            2 rue des Citrines 44300 Nantes</p>
            <p><b>10%</b> de remise sur le tarif annoncé</p>
            <p className="tel">Téléphone: <a href="tel:02 28 08 80 00">02 28 08 80 00</a></p>
            <p className="web"><a href='https://www.zenitude-hotel-residences.com/fr_FR/residence/nantes/138'>Site internet</a></p>
          </Block>
        </div>
      </div>
      <div className="support content large">
        <SupportContact breakingPoint={900}/>
      </div>
    </>
  );
}
