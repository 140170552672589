import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from '../../../../GlobalContext';
import { setFormShowroom } from '../../../../../actions/showroom';

import Shape from './Shape';
import ListOptions from './ListOptions';
import InputField from '../../../../partials/form_fields/InputField';

export default function Step3(props) {
  const history = useHistory();
  const [part, setPart] = useState(null);
  const [context, dispatch] = useGlobalContext();
  const { showRoomReducer } = context;

  // ACTIONS
  const _setFormShowroom = (data) => setFormShowroom(dispatch, data);

  useEffect(() => {
    setPart(history.location.hash);
  }, [history.location.hash]);

  function handleChange(val, field) {
    const updatedData = _.cloneDeep(showRoomReducer.formData);

    _.set(updatedData, field, val);
    _setFormShowroom(updatedData);
  }

  return (
    <div className="step step2">
      <section className="stand-info" >
        <InputField
          value={showRoomReducer?.formData?.name || ''}
          title={'Nom du stand'}
          placeholder={''}
          type={'string'}
          handleChange={(val) => handleChange(val, 'name')}
        />
        <p className='sub'>Si différent de la raison sociale</p>
      </section>
      { part === '' || part === '#1'
        ? <Shape part={part}/> : null
      }
      { part === '' || part === '#2'
        ? <ListOptions part={part} isLoading={props.isLoading}/> : null
      }
    </div>
  );
}
