import React, { useState, useEffect } from 'react';
import _ from 'lodash';
// import Header from '../../../partials/Header/Header';
import FormContributors from './FormContributors';
import LineUser from './LineUser';

import { useGlobalContext } from '../../../GlobalContext';

export default function Contributors() {
  const context = useGlobalContext()[0];
  const { companyReducer } = context;

  const [selectedUser, setSelectedUser] = useState();
  const [users, setUsers] = useState();
  const [isFormContactOpen, setIsFormContactOpen] = useState(false);

  useEffect(() => {
    if (!companyReducer.companyUsers) return;
    const sortedUsers = _.sortBy(companyReducer.companyUsers, (d) => d.role === 'Owner').reverse();
    setUsers(sortedUsers);
  }, [companyReducer.companyUsers]);

  function selectUser(id) {
    setSelectedUser(id);
    setIsFormContactOpen(false);
  }

  function toggleModal() {
    setSelectedUser(null);
    setIsFormContactOpen(!isFormContactOpen);
  }

  function cancel() {
    setSelectedUser(null);
    setIsFormContactOpen(false);
  }

  function renderListUsers() {
    if (!users) return null;
    return (
      <>
        { users.map((user, i) => (
          <LineUser key={`user-${i}`}
           user={user}
           isLoading={companyReducer.isUserLoading ? companyReducer.isUserLoading.map.get(user._id) : null}
           cancel={() => cancel()}
           selectUser={selectUser}
           selectedUser={selectedUser}
           edit={selectedUser === user._id}
          />
        ))}
      </>
    );
  }

  function fnIsLoading() {
    let isLoading = null;
    if (companyReducer.isUserLoading) {
      isLoading = companyReducer.isUserLoading.map.get('update-contributors') || companyReducer.isUserLoading.map.get('add') ? true : null;
    }
    return isLoading;
  }

  return (
    <>
      <div className="contributors">
        <div className="content large">
          <div className="title-page">
            <h2>Mes collaborateurs</h2>
          </div>
          <p className="section-title">Inscrire les collaborateurs présents</p>
          {renderListUsers()}

          {!isFormContactOpen && (users && users.length > 0) && !selectedUser
            ? <div className="centered">
              <div className="add-contact" onClick={toggleModal}>
                <p >Ajouter un contact</p>
                <div className="add" >+ </div>
              </div>
            </div> : null
          }

          { isFormContactOpen || (users && users.length === 0)
            ? <FormContributors
             isLoading={fnIsLoading()}
             cancel={() => cancel()}
            /> : null
          }
        </div>
      </div>
    </>
  );
}
