import React from 'react';
import { useGlobalContext } from '../../GlobalContext';
import ImgConfirm from '../../../assets/images/confirmation-montage.svg';

export default function RecapitulatifMontage(props) {
  const context = useGlobalContext()[0];
  const { montageReducer } = context;
  const { montage } = montageReducer;

  function renderTruck() {
    return (
      montage.truckType.map((truck, i) => <p className="truck" key={i}>- {truck}</p>)
    );
  }

  return (
    <div className="recapitulatif-montage">
      <div className="content">
        <p>
          Votre demande pour un montage débutant à
          <span> {montage.time} </span>
          par la société <span>{props.company} </span>
          avec :
          <div className="trucks">{renderTruck(montage.truckType)}</div>
          {montage.forkliftTruck ? 'Et avec' : 'Et sans'} chariot élévateur à bien été prise en compte !
        </p>
        <div className="col-3">
          <div>
            <p>Nombre de personnes<br />
              <span> {montage.contributorCount}</span>
            </p>
          </div>
          <div>
            <p>Temps de montage<br />
              <span> {montage.duration}</span>
            </p>
          </div>
          <div>
            <p>Volume <span className="sub">(en palettes)</span><br />
              <span> {montage.volume}</span>
            </p>
          </div>
        </div>
        <div className="col-2">
          <div className={montage.storage ? 'opt-active' : '' } >
            <p>
              Stockage
            </p>
          </div>
          <div className={montage.presence ? 'opt-active' : '' } >
            <p >
              Présence
            </p>
          </div>
        </div>
        <p className="dot">
          Vous pouvez (ou pourrez suivant la mise en ligne) télécharger les macarons d'accès livraison, plan d'accès et badges dans l'espace fichiers utiles.
        </p>
        <p className="dot">
          Le stationnement ne sera pas autorisé à l'arrière du Hall XXL. Des parkings (plan dans fichiers utiles) sont à votre disposition pour la durer de l'événement.
        </p>
        <div className="container-btn">
          <button onClick={props.toggleForm}>
            Modifier
          </button>
        </div>

        <img src={ImgConfirm} alt="Merci, montage validée" />
      </div>
    </div>
  );
}
