import React, { useState, useEffect } from 'react';
import { Swipeable } from 'react-swipeable';
import { useTransition, animated } from 'react-spring';
import {
  Switch, NavLink, Route, useHistory,
} from 'react-router-dom';
import useDimension from '../../../../customHooks/useDimension';
import usePrevious from '../../../../customHooks/usePrevious';

import Animations from './Animations';
import Conferences from './Conferences';

export default function Programme() {
  const history = useHistory();
  const dimension = useDimension();
  const [index, setIndex] = useState();
  const prevIndex = usePrevious(index);
  const [left, setLeft] = useState(0);
  const arrRoute = [
    '/programme',
    '/programme/conferences',
  ];

  const transitions = useTransition(history.location, (location) => location.pathname, {
    from: (d) => {
      const index = arrRoute.indexOf(d.pathname);
      const x = prevIndex > index ? '-10%' : '10%';
      return { opacity: 0, transform: `translate3d(${x},0,0)` };
    },
    enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
    leave: (d) => {
      const prevIndex = arrRoute.indexOf(d.pathname);
      // index = to
      const x = prevIndex < index ? '-10%' : '10%';
      return { opacity: 0, transform: `translate3d(${x},0,0)` };
    },
  });

  useEffect(() => {
    if (index === undefined || dimension.width > 767) {
      if (left !== 0) setLeft(0);
    } else {
      const links = document.querySelectorAll('.programme-navigation>a');
      let t = links[index].offsetLeft + (links[index].offsetWidth / 2);
      t -= (window.innerWidth / 2);
      setLeft(-t);
    }
  }, [index, dimension.width, left]);

  useEffect(() => {
    let index = arrRoute.indexOf(history.location.pathname);
    if (index === -1) index = 0;
    setIndex(index);
  }, [arrRoute, history.location.pathname]);

  function navLeft() {
    if (index === arrRoute.length - 1) return;
    let newIndex = index;
    newIndex++;
    history.push(arrRoute[newIndex]);
    setIndex(newIndex);
  }

  function navRight() {
    if (index === 0) return;
    let newIndex = index;
    newIndex--;
    history.push(arrRoute[newIndex]);
    setIndex(newIndex);
  }

  return (
    <Swipeable
      onSwipedLeft={(eventData) => navLeft(eventData)}
      onSwipedRight={(eventData) => navRight(eventData)}
    >
      <div className="programme">
        <div className="container-programme-navigation">
          <div className="programme-navigation" style={{ transform: `translateX(${left}px)` }}>
            <NavLink exact to="/programme">Animations</NavLink>
            <NavLink exact to="/programme/conferences">Conférences</NavLink>
          </div>
        </div>
        {
          transitions.map(({ item, props, key }) => (
            <animated.div key={key} className="container-programme" style={{ ...props }}>
              <Switch location={item}>
                <Route exact path={'/programme'} component={Animations}/>
                <Route exact path={'/programme/conferences'} component={Conferences}/>
              </Switch>
            </animated.div>
          ))
        }
      </div>
    </Swipeable>
  );
}
