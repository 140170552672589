import {
  GET_COMPANY, // RESPONSE GIVE SHOWROOM
  SET_FORM_SHOWROOM,
  CREATE_SHOWROOM,
  UPDATE_SHOWROOM,
  RESET_SUBMITTED,
  SHOWROOM_LOADING,
  ERROR_SHOWROOM,
} from '../actions/types';

// let test = {
//   "carpet": true,
//   "kit": false,
//   "kitCount": 3,
//   "power": true,
//   "powerkVa": "10",
//   "company": "5df9022b6f2be121e8a46313",
//   "user": "5dfb914ddab4421fa19c3be5",
//   "type": 1,
//   "dimension": 24,
//   "shape": "back",
//   "storage": true,
//   "water": true,
//   "customCarpet": true,
//   "direction": "l",
//   "waterType": [ "alimentation eau seul" ],
//   "standiste": {
//     "name": "standiste",
//     "email": "standiste@test.fr",
//     "phone": "02 93 02 93 02"
//   }
// }

const initialState = { formData: {}, error: null, submitted: null };

export default function showRoomReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COMPANY:
      const showroomData = action.payload.showrooms[0] ? action.payload.showrooms[0] : {};
      return { ...state, formData: showroomData };
    case CREATE_SHOWROOM:
      return { ...state, submitted: true, isLoading: false };
    case UPDATE_SHOWROOM:
      return { ...state, submitted: true, isLoading: false };
    case SET_FORM_SHOWROOM:
      return { ...state, formData: action.payload };
    case RESET_SUBMITTED:
      return { ...state, submitted: false };
    case SHOWROOM_LOADING:
      return { ...state, isLoading: true };
    case ERROR_SHOWROOM:
      return { ...state, error: action.payload.error };
    default:
      return { ...state };
  }
}
