import {
  GET_EXHIBITION,
  EXHIBITION_LOADING,
  ERROR_EXHIBITION,
} from '../actions/types';

const initialState = { error: null, isLoading: false, exhibition: null };

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_EXHIBITION:
      return { ...state, isLoading: false, exhibition: action.payload };
    case EXHIBITION_LOADING:
      return { ...state, isLoading: true };
    case ERROR_EXHIBITION:
      return { ...state, isLoading: false, error: action.payload };
    default:
      return { ...state };
  }
}
