import React, { useState } from 'react';

import _ from 'lodash';
import axios from 'axios';

import { NavLink } from 'react-router-dom';
import { API_URL } from '../../actions/index';

import InputField from '../partials/form_fields/InputField';

import LogoLeclerc from '../../assets/images/logo-leclerc.svg';
import LoginImg from '../../assets/images/login-img.png';
import IconMail from '../../assets/images/icons/mail_grey_icon.svg';

export default function ForgotPassword() {
  const [formData, setFormData] = useState({});
  const [error, setError] = useState();
  const [validatedEmail, setValidatedEmail] = useState();
  const [message, setMessage] = useState();

  function handleChangeField(val, err, field) {
    if (validatedEmail !== err) {
      setValidatedEmail(err);
    }

    const updatedData = _.cloneDeep(formData);
    _.set(updatedData, field, val);
    setFormData(updatedData);
  }

  function RequestForgotPassword() {
    const requestUrl = `${API_URL}/auth/forgot-password`;
    axios.post(requestUrl, { email: formData.email })
      .then(() => {
        setMessage('Un email vous a été envoyé avec un lien de réinitialisation.');
      })
      .catch(() => {
        setError({ message: "Aucun compte n'est associé à cette email." });
      });
  }

  function handlePressEnter(e) {
    if (e.key !== 'Enter') return;
    if (!validatedEmail) {
      RequestForgotPassword();
    }
  }

  return (
    <div className="page forgot-password" >
      <div className="content">
        <div className="left">
          <img src={LoginImg} alt="Salon multimedia et electro" />
        </div>
        <div className="form-login">
          <img src={LogoLeclerc} alt="logo leclerc" />
          <h1>BTLec Ouest</h1>
          <h2>Mot de passe oublié ?</h2>
          <div className="field-icon" onKeyPress={handlePressEnter}>
            <InputField
              value={''}
              title={''}
              placeholder={'Adresse mail'}
              type={'email'}
              format="email"
              disabled={false}
              handleChange={(val, err) => handleChangeField(val, err, 'email')}
            />
            <img src={IconMail} alt="adresse email" />
          </div>
          <div className="centered">
            <button className={validatedEmail ? 'disabled' : ''} onClick={RequestForgotPassword}>Réinitialiser mon mot de passe</button>
          </div>
          {error
            ? <p className="warn">{error.message}</p> : null
          }
          {message
            ? <p className="succeed">{message}</p> : null
          }
          <div className="centered">
            <NavLink to="/">Login</NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
