import React from 'react';
import checkboxChecked from '../../../assets/images/checkbox-checked.svg';
import checkbox from '../../../assets/images/checkbox.svg';

const Checkbox = (props) => (
      <div className="checkbox">
        {props.value
          ? <img src={checkboxChecked} alt="checkbox checked" onClick={() => props.toggleCheckbox(props.value)} />
          : <img src={checkbox} alt="checkbox" onClick={() => props.toggleCheckbox(props.value)} />
        }
        <label
          className={props.selected ? 'selected' : ''}
          onClick={() => props.toggleCheckbox(props.value)}
        >
          {props.label}
        </label>
      </div>
);

export default Checkbox;
