import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { getProviders } from '../../../../actions/provider';
import { useGlobalContext } from '../../../GlobalContext';

import selectStyle from '../../../partials/form_fields/selectStyle';
import InputField from '../../../partials/form_fields/InputField';
import Loader from '../../../partials/Loader';

export default function ListProviders() {
  const [context, dispatch] = useGlobalContext();
  const { providerReducer } = context;

  const [providers, setProviders] = useState();
  const [optionsDomains, setOptionsDomains] = useState();
  const [selectedDomain, setSelectedDomain] = useState();
  const [search, setSearch] = useState();

  const [wasMount, setWasMount] = useState(false);

  // ACTIONS
  const _getProviders = () => getProviders(dispatch);

  useEffect(() => {
    if (!providerReducer.providers) {
      _getProviders();
    } else {
      // SET OPTIONS SELECT
      const arrProviders = [];
      let domains = [];
      providerReducer.providers.forEach((provider) => {
        if (provider.showroomNumber || provider.showroomNumber === 0) {
          domains.push(...provider.domains);
          arrProviders.push(
            {
              name: provider.name,
              showroomNumber: provider.showroomNumber,
              domains: provider.domains,
            },
          );
        }
      });

      domains = [...new Set(domains)];
      const optionsDomains = domains.map((domain) => (
        { label: domain, value: domain }
      ));

      const sortedProviders = sortProviders(arrProviders);
      setWasMount(true);
      setOptionsDomains(optionsDomains);
      setProviders(sortedProviders);
    }
  }, [providerReducer.providers]);

  useEffect(() => {
    if (!wasMount) return;

    let arrProviders = [];
    providerReducer.providers.forEach((provider) => {
      if ((provider.showroomNumber === 0)
        && (selectedDomain && selectedDomain.value === 'EXPERTISE')
      ) {
        arrProviders.push({
          name: provider.name,
          showroomNumber: provider.showroomNumber,
          domains: provider.domains,
        });
      } else if ((provider.showroomNumber || provider.showroomNumber === 0) && selectedDomain
        && provider.domains.includes(selectedDomain.value)
      ) {
        arrProviders.push({
          name: provider.name,
          showroomNumber: provider.showroomNumber,
          domains: provider.domains,
        });
      } else if ((provider.showroomNumber || provider.showroomNumber === 0) && !selectedDomain) {
        arrProviders.push({
          name: provider.name,
          showroomNumber: provider.showroomNumber,
          domains: provider.domains,
        });
      }
    });

    if (search) {
      arrProviders = arrProviders.filter((provider) => (
        (provider.name.toLowerCase().indexOf(search) >= 0)
          || (provider.showroomNumber.toString().toLowerCase().indexOf(search) >= 0)
      ));
    }

    setProviders(sortProviders(arrProviders));
  }, [selectedDomain, search]);

  function sortProviders(providers) {
    const providerMultimedia = [];
    const providerMaison = [];
    const providerMultimediaMaison = [];
    const providerExpertise = [];
    const providerEconomat = [];
    const providerOccasions = [];
    const rest = [];

    providers = providers.filter((provider) => provider.showroomNumber !== 999);
    providers.forEach((provider) => {
      if (
        provider.domains.includes('MULTIMEDIA')
        && provider.domains.includes('MAISON')
      ) {
        providerMultimediaMaison.push(provider);
      } else if (provider.domains.includes('MULTIMEDIA')) {
        providerMultimedia.push(provider);
      } else if (provider.domains.includes('MAISON')) {
        providerMaison.push(provider);
      } else if (provider.domains.includes('EXPERTISE')) {
        providerExpertise.push(provider);
      } else if (provider.domains.includes('ECONOMAT')) {
        providerEconomat.push(provider);
      } else if (provider.domains.includes('OCCASION')) {
        providerOccasions.push(provider);
      } else {
        rest.push(provider);
      }
    });

    return ([
      ..._.orderBy(providerMultimedia, 'name', 'asc'),
      ..._.orderBy(providerMultimediaMaison, 'name', 'asc'),
      ..._.orderBy(providerMaison, 'name', 'asc'),
      ..._.orderBy(providerExpertise, 'name', 'asc'),
      ..._.orderBy(providerEconomat, 'name', 'asc'),
      ..._.orderBy(providerOccasions, 'name', 'asc'),
      ..._.orderBy(rest, 'name', 'asc'),
    ]);
  }

  function handleChange(val, target) {
    if (target === 'search') {
      setSearch(val.toLowerCase());
    } else if (target === 'domains') {
      setSelectedDomain(val);
    }
  }

  function renderPastille(provider) {
    let className;

    // ACHAT INDIRECT VERT
    if (provider.showroomNumber === 0) {
      className = 'pastille expert';
    } else if (provider.domains.includes('MULTIMEDIA') && provider.domains.includes('MAISON')) {
      className = 'pastille multimedia-maison';
    } else if (provider.domains[0] === 'MAISON') {
      className = 'pastille maison';
    } else if (provider.domains[0] === 'ACHATS INDIRECTS'
    || provider.domains.includes('EXPERTISE')
    || provider.domains.includes('ECONOMAT')) {
      className = 'pastille green';
    } else if (provider.domains[0] === 'MULTIMEDIA') {
      className = 'pastille multimedia';
    } else if (provider.domains.includes('OCCASION')) {
      className = 'pastille violet';
    }
    return <div className={className}></div>;
  }

  return (
    <div className="list-providers">
      <div className="content">
        {optionsDomains
          && <div className="container-filters">
            <div className="search-provider">
              <InputField
                id={'search-provider'}
                title={''}
                placeholder={'Recherche'}
                type={'string'}
                required={false}
                handleChange={(val) => handleChange(val, 'search')}
              />
            </div>
            <div className="select-domains">
              <Select
                onChange={(val) => handleChange(val, 'domains')}
                options={optionsDomains}
                value={selectedDomain}
                isClearable={true}
                styles={selectStyle}
                placeholder={"Choisissez l'univers..."}
              />
            </div>
          </div>
        }
        {providers
          ? <ul>
            {providers.map((provider, i) => (
              <li key={`provider-${i}`}>
                <div className={'showroom-number'}>
                  {provider.name === 'EXERTIS'
                    ? <p>
                      48 à 53
                    </p>
                    : <p>
                      {provider.showroomNumber === 0 ? 'Expertise' : provider.showroomNumber}
                    </p>
                  }

                  {renderPastille(provider)}
                </div>
                <p>{provider.name}</p>
              </li>
            ))}
          </ul> : <Loader />
        }
      </div>
    </div>
  );
}
