import { postData, putData } from './index';

import {
  SET_FORM_SHOWROOM,
  CREATE_SHOWROOM,
  UPDATE_SHOWROOM,
  SHOWROOM_LOADING,
  ERROR_SHOWROOM,
} from './types';

export async function setFormShowroom(dispatch, data) {
  dispatch({
    type: SET_FORM_SHOWROOM,
    payload: data,
  });
}

export async function createShowroom(dispatch, data) {
  const url = '/showroom';

  dispatch({
    type: SHOWROOM_LOADING,
  });

  let showroom;
  await postData(ERROR_SHOWROOM, true, url, dispatch, data).then((response) => {
    showroom = response.showroom;
  });

  dispatch({
    type: CREATE_SHOWROOM,
    payload: showroom,
  });
}

export async function updateShowroom(dispatch, data, id) {
  const url = `/showroom/${id}`;

  dispatch({
    type: SHOWROOM_LOADING,
  });

  let showroom;
  await putData(ERROR_SHOWROOM, true, url, dispatch, data).then((response) => {
    showroom = response.showroom;
  });

  dispatch({
    type: UPDATE_SHOWROOM,
    payload: showroom,
  });
}
