import React, { useState, useEffect, useRef } from 'react';
import { useGlobalContext } from '../../../GlobalContext';
import { getBadge, getAllBadges } from '../../../../actions/users';
import badgeAvatar from '../../../../assets/images/user-badge-avatar.svg';

export default function Badges() {
  const context = useGlobalContext()[0];
  const { companyReducer } = context;

  const [isBadgesLoading, setIsBadgesLoading] = useState();
  const objBadgesLoading = useRef({});
  const [timestamp, setTimestamp] = useState();
  const [badges, setBadges] = useState([]);

  useEffect(() => {
    const arrBadges = [
      ...companyReducer.companyUsers,
    ].filter((user) => user.presence.length > 0).map((user) => (
      { ...user, downloadBadge: () => downloadBadge(user) }
    ));
    setBadges(arrBadges);
  }, []);

  function downloadBadge(user) {
    objBadgesLoading.current[user._id] = true;
    setTimestamp(Date.now());
    getBadge(user._id, `Badge_${user.profile.lastName}_${user.profile.firstName}`).then(() => {
      objBadgesLoading.current[user._id] = false;
      setTimestamp(Date.now());
    });
  }

  useEffect(() => {
    const obj = {};
    badges.forEach((d) => {
      obj[d._id] = false;
    });
    objBadgesLoading.current = obj;
  }, []);

  function dowloadAllBadges() {
    const ids = badges.map((d) => d._id);
    setIsBadgesLoading(true);
    getAllBadges({ ids }).then(() => {
      setIsBadgesLoading(false);
    });
    // badges.forEach((badge) => badge.downloadBadge())
  }

  return (
    <>
    <div className="badges">
      <div className="content large">
        <div className="title-page">
          <h2>
            Génération des badges
            <p>Le badge doit être impérativement imprimé pour l'accès au salon.</p>
          </h2>
          <button className="hide-on-tablet" onClick={dowloadAllBadges}>
            Tout télécharger
            {isBadgesLoading && <span className="loader"></span>}
          </button>
        </div>
        { companyReducer.company?.type === 'magasin'
          && <p>
            Une 10aine de jours avant le salon, vous recevrez par courrier postal à l'attention de monsieur/madame l'adhérent(e) un badge pour chaque collaborateur inscrit.
            <span className="medium"> Vous devez obligatoirement être munis d'un badge papier pour accéder au salon (téléchargé ou reçu par courrier).</span>
          </p>
        }
        <button className="only-on-tablet" onClick={dowloadAllBadges}>
          Tout télécharger
          {isBadgesLoading && <span className="loader"></span>}
        </button>
        <div className="list-badges">
          { (objBadgesLoading && badges && badges.length > 0)
            && badges.map((user) => (
              <div className="line" key={`key ${user.profile.lastName} ${user.profile.firstName}`}>
                <div className="info-user">
                  <img className="hide-on-tablet" src={badgeAvatar} alt={`avatar ${user.profile.lastName} ${user.profile.firstName}`} />
                  <div className="name">
                    <p>{user.profile.lastName} {user.profile.firstName}</p>
                  </div>
                  <div className="hide-on-tablet">
                    <p>{user.profile.function}</p>
                  </div>
                </div>
                <button onClick={user.downloadBadge}>
                  Télécharger
                  {objBadgesLoading.current[user._id] && <span className="loader"></span>}
                </button>
              </div>
            ))
          }
        </div>
      </div>
    </div>
    </>
  );
}
