import React, { useState, useEffect, useRef } from 'react';
import { AiFillPicture } from 'react-icons/ai';
import Loader from '../../../../partials/Loader';
import styles from './image-item.module.scss';

const ImgItem = ({ src, alt, className }) => {
  const [img, setImg] = useState(null);
  const [error, setError] = useState(false);
  const timerRef = useRef();

  useEffect(() => {
    const constructorImg = new Image();
    const promise = new Promise((resolve, reject) => {
      constructorImg.onload = function () {
        resolve({
          src,
          width: constructorImg.width,
          height: constructorImg.height,
        });
      };
      constructorImg.onerror = function () {
        timerRef.current = setTimeout(() => {
          reject();
        }, 1000);
      };
      constructorImg.src = src;
    });

    const cancelablePromise = makeCancelable(promise);

    cancelablePromise.promise.then((res) => {
      setImg(res.src);
      setError(false);
    }).catch(() => {
      setError(true);
    });

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
      constructorImg.src = '';
      constructorImg.onload = null;
      constructorImg.onerror = null;
      setImg(null);
      setError(false);
      cancelablePromise.cancel();
    };
  }, [src]);

  function makeCancelable(promise) {
    let isCanceled = false;

    const wrappedPromise = new Promise((resolve, reject) => {
      promise
        .then((val) => (isCanceled ? reject(new Error({ isCanceled })) : resolve(val)))
        .catch((error) => (isCanceled ? reject(new Error({ isCanceled })) : reject(error)));
    });

    return {
      promise: wrappedPromise,
      cancel() {
        isCanceled = true;
      },
    };
  }

  function getClassName() {
    let res = className || styles.container;
    if (error) res += ' error';
    return res;
  }

  return (
    <div className={getClassName()}>
      { img && <img src={img} alt={alt} />}
      {error && <div className={styles['no-image']}><AiFillPicture size={40} color='#D3D3D3'/></div>}
      { (!img && !error) && <div className={`container-loader-img ${styles['container-loader']}`}><Loader /></div>}
    </div>
  );
};

export default ImgItem;
