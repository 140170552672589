import React from 'react';

import Block from './Block';
import DownloadFile from '../../partials/DownloadFile';
import SupportContact from '../../partials/SupportContact';
import ImgPlan from '../../../assets/images/plan.png';

export default function Transports() {
  return (
    <>
      <div className="transports content large">

        <div className="col">
          <DownloadFile img={ImgPlan} desc={"Plan d'accès à Exponantes"} url="https://www.exponantes.com/system/page_attachments/450/attachments/original_plan-exponantes.pdf"/>
          <Block
            title = "Taxi"
          >
            <p className="semibold">
              Allô taxi Nantes Atlantique<br/>
            </p>
            <p className="tel">
              Téléphone: <a className="semibold" href="tel:06 47 82 07 39">06 47 82 07 39</a>
              {' '}ou sur la plateforme en ligne <a style={{ textDecoration: 'underline' }} href="https://allotaxinantes.fr/" rel="noreferrer" target='_blank'>allotaxinantes.fr</a></p>
            <p className="semibold">
              Morgan VTC<br/>
            </p>
            <p className="tel">Téléphone: <a className="semibold" href="tel:06 87 93 54 79">06 87 93 54 79</a></p>
          </Block>
        </div>
        <div className="col">
          <Block
            title = "Voiture (5000&nbsp;places&nbsp;de&nbsp;parkings)"
          >
            <p className="semibold">Depuis Angers/Paris par l’A11</p>
            <p>Sortie 23 vers Nantes Est / Carquefou. Puis suivre La Beaujoire,
               route de St Joseph de Porterie.</p>
            <p className="semibold">Depuis Bordeaux/ La Rochelle/ Aéroport Nantes Atlantique&nbsp;:</p>
            <p>suivre direction Poitiers par le périphérique Est, puis Rennes
               et sortir porte 40 "Porte de la Beaujoire"</p>
            <p className="semibold">Depuis Rennes/Vannes/St Nazaire/La Baule&nbsp;:</p>
            <p>direction Poitiers et périphérique Est, puis sortie n°38, puis sortie
               porte 40 "Porte de la Beaujoire"</p>
          </Block>
        </div>
        <div className="col">
          {/*
            <Block
              title = "Avion (aéroport&nbsp;à&nbsp;25&nbsp;min)"
            >
              <p className="semibold">
                Des réductions attractives jusqu'à -15%, sur un large éventail
                 de tarifs publics sur tous les vols à code partagé dans le monde entier.
                  Vous pouvez dès aujourd'hui acheter vos billets
              </p>
              <a href="http://globalmeetings.airfranceklm.com/Search/promoDefault.aspx?vendor=AFR&promocode=37063AF&currentculture=fr-FR"><button className="secondary">Acheter</button></a>
              <p>
                Pour profiter de cette offre, il vous faut rentrer
                 le code d'identification: <b>37063AF</b>
              </p>
            </Block>
          */}
          <Block
            title = "Train (gare&nbsp;TGV&nbsp;à&nbsp;15&nbsp;min&nbsp;du&nbsp;parc)"
          >
            <p className="semibold">Arrivée en gare de Nantes&nbsp;:</p>
            <p>
              En tramway: Prendre la sortie "Gare Nord", station de tramway face à la gare.
            </p>
            <p>
              Prendre le tramway ligne 1 direction Beaujoire, descente terminus Beaujoire.
               Le parc des expositions est à votre
              gauche à la descente.
            </p>
            <p className="semibold">Location de voiture&nbsp;:</p>
            <p>
              prendre la sortie Sud, les principales enseignes de location de véhicules
               sont représentées.
            </p>
          </Block>
        </div>

      </div>
      <div className="support content large">
        <SupportContact breakingPoint={900}/>
      </div>
    </>
  );
}
