import React, { useState, useEffect } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import usePrevious from '../../../customHooks/usePrevious';
// import Header from '../../partials/Header/Header';
import EspaceAnimation from './EspaceAnimation';
import EspaceSupAnimation from './EspaceSupAnimation';

import ImgConfirm from '../../../assets/images/confirmation-animation.svg';
import ImgPole from '../../../assets/images/confirmation-pole.svg';

import ImgTrophy from '../../../assets/images/trophy.png';

import { useGlobalContext } from '../../GlobalContext';

export default function Animations() {
  const context = useGlobalContext()[0];
  const history = useHistory();
  const [part, setPart] = useState(null);

  const { animationReducer } = context;
  const [animation, setAnimation] = useState();
  const [animationSup, setAnimationSup] = useState();

  const [isAnimationLoading, setIsAnimationLoading] = useState(false);
  const wasIsAnimationLoading = usePrevious(isAnimationLoading);
  const [animationDone, setAnimationDone] = useState();
  const [resetAnimationDone, setResetAnimationDone] = useState();

  const [isAnimationSupLoading, setIsAnimationSupLoading] = useState(false);
  const wasIsAnimationSupLoading = usePrevious(isAnimationSupLoading);
  const [animationSupDone, setAnimationSupDone] = useState();
  const [resetAnimationSupDone, setResetAnimationSupDone] = useState();

  useEffect(() => {
    setPart(history.location.hash);
  }, [history.location.hash]);

  useEffect(() => {
    const animationFound = animationReducer.animations.find((d) => !d.dimensionP && !d.dimensionL);
    setAnimation(animationFound);

    const animationSupFound = animationReducer.animations.find((d) => d.dimensionP && d.dimensionL);

    setAnimationSup(animationSupFound);
  }, [animationReducer.animations]);

  useEffect(() => {
    setIsAnimationLoading(animationReducer.isLoading === true || animationReducer.isLoading === 'base');
    setIsAnimationSupLoading(animationReducer.isLoading === true || animationReducer.isLoading === 'sup');
  }, [animationReducer.isLoading]);

  // HANDLE ANIMATION DONE
  useEffect(() => {
    if (wasIsAnimationLoading && !isAnimationLoading) {
      setAnimationDone(true);
    }
  }, [wasIsAnimationLoading, isAnimationLoading]);

  // HANDLE ANIMATION SUP DONE
  useEffect(() => {
    if (wasIsAnimationSupLoading && !isAnimationSupLoading) setAnimationSupDone(true);
  }, [wasIsAnimationSupLoading, isAnimationSupLoading]);

  function toggleResetAnimationDone() {
    setResetAnimationDone(true);
    setTimeout(() => {
      setResetAnimationDone(false);
      setAnimationDone(false);
    }, 1200);
  }

  function toggleResetAnimationSupDone() {
    setResetAnimationSupDone(true);
    setTimeout(() => {
      setResetAnimationSupDone(false);
      setAnimationSupDone(false);
    }, 1200);
  }

  function renderClassAnimation(bool, done) {
    const reset = done ? 'reset' : '';
    return bool ? `form done ${reset}` : 'form';
  }

  function goto(url) {
    history.push(url);

    setResetAnimationDone(false);
    setAnimationDone(false);

    setResetAnimationSupDone(false);
    setAnimationSupDone(false);
  }

  return (
    <>
      <div className="animations">
        <div className="content large">
          <div className="title-page">
            <h2>Mes animations</h2>
          </div>
          { part === '#select'
            && <div className="mobile-navigation">
              <p>Maximisez les visites sur votre stand en valorisant son contenu</p>
              <div>
                <p>
                  Proposer une animation sur votre stand
                </p>
                <NavLink to="/animations#animation">
                  <button>Proposer</button>
                </NavLink>

              </div>
              <div>
                <p>
                  Acquerir un Pôle d’expérience
                </p>
                <p>
                  <span className="sub">(Pas de surcoût, mais soumis à validation BTLec Ouest)</span>
                </p>
                { animationSup && <p className="warn" >Vous avez une demande en cours !</p>}
                <NavLink to="/animations#pole">
                  <button>Acquerir</button>
                </NavLink>
              </div>
              <div className="container-trophy">
                <img src={ImgTrophy} alt="Election du plus beau stand" />
                <p>N’oubliez pas l’élection du plus beau stand&nbsp;!<br/>
                 Donnez aux magasins l’envie de voter pour votre espace. A vous de jouer !</p>
              </div>
            </div>
          }
          { part === '#animation' || part === ''
            ? <div className="col">
              { part === '#animation'
                && <a onClick={() => goto('/animations#select')}>
                  <button className="btn-back">
                    Retour
                  </button>
                </a>
              }
              <h3>Animation sur votre stand</h3>
              <div className={renderClassAnimation(animationDone, resetAnimationDone)} >
                <EspaceAnimation
                  isLoading={isAnimationLoading}
                  animation={animation}
                  animationDone={animationDone}
                />
                <div className={(animation && animation.push === false) ? 'confirm-success small' : 'confirm-success'}>
                  <h1>Merci !</h1>
                  <p>
                    Nous avons bien pris en compte votre demande.
                  </p>
                  <button onClick={toggleResetAnimationDone}>Modifier</button>
                  <img src={ImgConfirm} alt="Merci, animation validée" />
                </div>
              </div>
              { part === ''
                && <div className="container-trophy">
                  <img src={ImgTrophy} alt="Election du plus beau stand" />
                  <span>
                    <p>
                      N’oubliez pas l’élection du PLUS BEAU STAND !
                    </p>
                    <p>
                      Donnez aux magasins l’envie de voter
                       pour votre espace et gagnez un stand pour l’année prochaine !
                    </p>
                    <p>
                      A vous de vous surpasser...
                    </p>
                  </span>
                </div>
              }

            </div> : null
          }
          { part === '#pole' || part === ''
            ? <div className="col">
              { part === '#pole'
                && <a onClick={() => goto('/animations#select')}>
                  <button className="btn-back">
                    Retour
                  </button>
                </a>
              }
              <h3>Pôle d’expérience</h3>
              <div className={renderClassAnimation(animationSupDone, resetAnimationSupDone)} >
                <EspaceSupAnimation
                  isLoading={isAnimationSupLoading}
                  animation={animationSup}
                  animationDone={animationSupDone}
                />
                <div className={'confirm-success'}>
                  <h1>Merci !</h1>
                  <p>
                    Votre animation est en cours de validation par nos services !
                  </p>
                  <button onClick={toggleResetAnimationSupDone}>Modifier</button>
                  <img src={ImgPole} alt="Merci, animation validée" />
                </div>
              </div>
            </div> : null
          }
        </div>
      </div>
    </>
  );
}
