import axios from 'axios';
import {
  API_URL,
  getData,
  postData,
  putData,
  deleteData,
} from './index';

import {
  GET_COMPANY,
  GET_COMPANY_LIST,
  UPDATE_COMPANY,
  ADD_COMPANY_USER,
  UPDATE_COMPANY_USER,
  DELETE_COMPANY_USER,
  SET_FORM_COMPANY,
  SET_CONTACTS_COMPANY,
  COMPANY_USER_LOADING,
  COMPANY_LOADING,
  RESET_COMPANY_ERROR,
  RESET_COMPANY,
  ERROR_COMPANY,
} from './types';

export function resetCompany(dispatch) {
  dispatch({
    type: RESET_COMPANY,
  });
}

export function resetCompanyError(dispatch) {
  dispatch({
    type: RESET_COMPANY_ERROR,
  });
}

export async function addCompanyUser(dispatch, data) {
  const url = '/user';

  dispatch({
    type: COMPANY_USER_LOADING,
    payload: {
      key: 'add',
      action: 'add',
    },
  });

  let user;
  await postData(ERROR_COMPANY, true, url, dispatch, data).then((response) => {
    user = response;
  });

  dispatch({
    type: ADD_COMPANY_USER,
    payload: user,
  });
}

export async function updateCompanyUser(dispatch, data, id, actionLoad = null) {
  const url = `/user/${id}`;
  dispatch({
    type: COMPANY_USER_LOADING,
    payload: {
      key: actionLoad || id,
      action: 'update',
    },
  });

  let user;
  await putData(ERROR_COMPANY, true, url, dispatch, data).then((response) => {
    user = response;
  });

  dispatch({
    type: UPDATE_COMPANY_USER,
    payload: user,
    key: actionLoad || id,
  });
}

export async function deleteCompanyUser(dispatch, id) {
  const url = `/user/${id}`;

  dispatch({
    type: COMPANY_USER_LOADING,
    payload: {
      key: id,
      action: 'delete',
    },
  });

  let userId;
  await deleteData(ERROR_COMPANY, url, dispatch, true).then((response) => {
    userId = response.data.user;
  });

  dispatch({
    type: DELETE_COMPANY_USER,
    payload: userId,
  });
}

export async function getCompany(dispatch, id) {
  const url = `/company/${id}`;
  let company;

  dispatch({
    type: COMPANY_LOADING,
  });

  await getData(ERROR_COMPANY, url, dispatch, true).then((response) => {
    company = response.data;
  });

  dispatch({
    type: GET_COMPANY,
    payload: company,
  });
}

export async function updateCompany(dispatch, data, id) {
  const url = `/company/${id}`;
  let company;

  dispatch({
    type: COMPANY_LOADING,
  });

  await putData(ERROR_COMPANY, true, url, dispatch, data).then((response) => {
    company = response.company;
  });

  dispatch({
    type: UPDATE_COMPANY,
    payload: company,
  });
}

export async function setFormCompany(dispatch, data) {
  dispatch({
    type: SET_FORM_COMPANY,
    payload: data,
  });
}

export async function setContactsCompany(dispatch, users) {
  dispatch({
    type: SET_CONTACTS_COMPANY,
    payload: users,
  });
}

export async function getCompanyList(dispatch) {
  const url = '/company/list';
  let companyList;
  await getData(ERROR_COMPANY, url, dispatch, true).then((response) => {
    companyList = response.data;
  });
  dispatch({
    type: GET_COMPANY_LIST,
    payload: companyList,
  });
}

/// DOESN'T DISPATCH ANYTHING
export function updateCompanyPresence(bool, id) {
  return new Promise((resolve, reject) => {
    const config = {
      timeout: 1000 * 60,
      headers: { Authorization: localStorage.getItem('token') },
    };

    axios.put(`${API_URL}/company/${id}`, { presence: bool }, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject();
      });
  });
}
