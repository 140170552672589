import { useState, useEffect } from 'react';

export default function useDimension() {
  const [dimension, setDimension] = useState({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return function () {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function handleResize(e) {
    // console.log( window.innerHeight);
    setDimension({ width: window.innerWidth, height: window.innerHeight });
  }

  return dimension;
}
