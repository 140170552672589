//= =====================
// General Actions
//= =====================

export const RESET_SUBMITTED = 'RESET_SUBMITTED';

//= =====================
// Auth Actions
//= =====================

export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LIST_CONTACTS = 'AUTH_LIST_CONTACTS';
export const AUTH_LIST_COMPANIES = 'AUTH_LIST_COMPANIES';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_GET_USER = 'AUTH_GET_USER';
export const RESET_ERROR = 'RESET_ERROR';
export const RESET_AUTH = 'RESET_AUTH';
export const ERROR_AUTH = 'ERROR_AUTH';

//= =====================
// Users Actions
//= =====================

export const UPDATE_USERS = 'UPDATE_USERS';
export const USERS_RESET_ERROR = 'USERS_RESET_ERROR';
export const USER_RESET_SUBMITTED = '';
export const USERS_LOADING = 'USERS_LOADING';
export const QUIZZ_USER = 'QUIZZ_USER';
export const VOTE_USER = 'VOTE_USER';
export const ERROR_USERS = 'ERROR_USERS';

//= =====================
// Exhibition Actions
//= =====================

export const GET_EXHIBITION  = 'GET_EXHIBITION';
export const EXHIBITION_LOADING  = 'EXHIBITION_LOADING';
export const ERROR_EXHIBITION  = 'ERROR_EXHIBITION';

//= =====================
// Animation Actions
//= =====================

export const GET_ANIMATION = 'GET_ANIMATION';
export const GET_ANIMATIONS = 'GET_ANIMATIONS';
export const DELETE_FILE = 'DELETE_FILE';
export const ADD_ANIMATION = 'ADD_ANIMATION';
export const UPDATE_ANIMATION = 'UPDATE_ANIMATION';
export const LOADING_ANIMATION = 'LOADING_ANIMATION';
export const ERROR_ANIMATION = 'ERROR_ANIMATION';

//= =====================
// Company Actions
//= =====================

export const GET_COMPANY = 'GET_COMPANY';
export const ADD_COMPANY_USER = 'ADD_COMPANY_USER';
export const UPDATE_COMPANY_USER = 'UPDATE_COMPANY_USER';
export const DELETE_COMPANY_USER = 'DELETE_COMPANY_USER';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const SET_CONTACTS_COMPANY = 'SET_CONTACTS_COMPANY';
export const SET_FORM_COMPANY = 'SET_FORM_COMPANY';
export const GET_COMPANY_LIST = 'GET_COMPANY_LIST';
export const COMPANY_USER_LOADING = 'COMPANY_USER_LOADING';
export const COMPANY_LOADING = 'COMPANY_LOADING';
export const COMPANY_RESET_SUBMITTED = 'COMPANY_RESET_SUBMITTED';
export const RESET_COMPANY_ERROR = 'RESET_COMPANY_ERROR';
export const RESET_COMPANY = 'RESET_COMPANY';
export const ERROR_COMPANY = 'ERROR_COMPANY';

//= =====================
// Showroom Actions
//= =====================

export const SET_FORM_SHOWROOM = 'SET_FORM_SHOWROOM';
export const CREATE_SHOWROOM = 'CREATE_SHOWROOM';
export const UPDATE_SHOWROOM = 'UPDATE_SHOWROOM';
export const SHOWROOM_LOADING = 'SHOWROOM_LOADING';
export const ERROR_SHOWROOM = 'ERROR_SHOWROOM';

//= =====================
// Montage Actions
//= =====================

export const GET_MONTAGE = 'GET_MONTAGE';
export const ADD_MONTAGE = 'ADD_MONTAGE';
export const UPDATE_MONTAGE = 'UPDATE_MONTAGE';
export const MONTAGE_LOADING = 'MONTAGE_LOADING';
export const ERROR_MONTAGE = 'ERROR_MONTAGE';

//= =====================
// Products Actions
//= =====================

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const POST_PRODUCT = 'POST_PRODUCT';
export const PUT_PRODUCT = 'PUT_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const GET_LIST_FILTERS = 'GET_LIST_FILTERS';
export const GET_PRODUCTS_FLASH = 'GET_PRODUCTS_FLASH';
export const GET_PRODUCT_BY_ID = 'GET_PRODUCT_BY_ID';
export const SET_PRODUCTS_FILTERS = 'SET_PRODUCTS_FILTERS';
export const RESET_SELECTED_PRODUCT = 'RESET_SELECTED_PRODUCT';
export const RESET_ERROR_PRODUCTS = 'RESET_ERROR_PRODUCTS';
export const PRODUCTS_LOADING = 'PRODUCTS_LOADING';
export const ERROR_PRODUCTS = 'ERROR_PRODUCTS';

//= =====================
// Order Actions
//= =====================

export const GET_ORDERS = 'GET_ORDERS';
export const GET_ORDER_BY_ID = 'GET_ORDER_BY_ID';
export const VALIDATE_ORDER = 'VALIDATE_ORDER';
export const VALIDATE_ORDER_ERROR = 'VALIDATE_ORDER_ERROR';
export const RESET_CONFIRMED_ORDER = 'RESET_CONFIRMED_ORDER';
export const CREATE_ORDER = 'CREATE_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const LOADING_ORDERS = 'LOADING_ORDERS';
export const ERROR_ORDERS = 'ERROR_ORDERS';

//= =====================
// Provider Actions
//= =====================

export const GET_PROVIDER = 'GET_PROVIDER';
export const PROVIDER_LOADING = 'PROVIDER_LOADING';
export const ERROR_PROVIDER = 'ERROR_PROVIDER';

//= =====================
// Notification Actions
//= =====================

export const DEVICE_REGISTERED_NOTIFICATIONS = 'DEVICE_REGISTERED_NOTIFICATIONS';
export const ERROR_NOTIFICATIONS = 'ERROR_NOTIFICATIONS';

//= =====================
// Theme Actions
//= =====================

export const MODAL_REGLEMENT_QUIZZ = 'MODAL_REGLEMENT_QUIZZ';
