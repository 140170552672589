import React, { useEffect, useState } from 'react';
import axios from 'axios';

import DustBinIcon from '../../../assets/images/icons/dustbin_icon_dark.svg';
import DustBinIconHover from '../../../assets/images/icons/dustbin_icon_hover.svg';

import { API_URL } from '../../../actions/index';

export default function ListFiles({ files, replace }) {
  const [arrFiles, setArrFiles] = useState();

  useEffect(() => {
    setArrFiles(files);
  }, [files]);

  function deleteFiles(fileName) {
    // _deleteFile(file.split("/")[0],file.split("/")[1])
    const url = `${API_URL}/animation/removefile/${fileName.split('/')[0]}`;

    const config = {
      timeout: 1000 * 60,
      headers: { Authorization: localStorage.getItem('token') },
    };

    axios.post(url, { fileName: fileName.split('/')[1] }, config)
      .then(() => {
        const filteredFiles = arrFiles.filter((file) => file !== fileName);
        if (filteredFiles.length > 0) {
          setArrFiles(filteredFiles);
        } else {
          setArrFiles(null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      { arrFiles
        ? <ul className="list-files">
          {arrFiles.map((file, i) => <li key={`file-${i}`}>
            {replace ? file.replace(replace, '') : file}
            <div className="delete" onClick={() => deleteFiles(file)}>
              <img src={DustBinIcon} alt={'supprimer'} />
              <img src={DustBinIconHover} alt={'supprimer active'} />
            </div>
          </li>)}
        </ul> : null
      }
    </>

  );
}
