/* eslint-disable */
import axios from 'axios';
import { saveAs } from 'file-saver';

import download from 'download';
import { API_URL, postData ,putData, deleteData } from './index';

import {
  UPDATE_USERS,
  VOTE_USER,
  QUIZZ_USER,
  USERS_LOADING,
  ERROR_USERS
} from "./types"

export async function updateCreateMutipleUsers(dispatch,updatedUsers,newUsers,deletedUsers){
  let addValidated = false;
  let errorUser = false
  let updatedValidated = false;
  let deletedValidated = false;

  let resNewUsers = []
  let resUpdatedUsers = []

  dispatch({
    type : USERS_LOADING
  })

  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index]);
    }
  }

  //CREATE USERS
  if(newUsers && newUsers.length>0){
    await asyncForEach(newUsers, async (user) => {
      let url = '/user'
      await postData( ERROR_USERS, true, url, dispatch , user).then((response)=>{
        resNewUsers.push(response.user)
      }).catch((error)=>{
        console.log(error);
      })
    })
    if(newUsers.length === resNewUsers.length){
      addValidated = true
    }else{
      errorUser = true
    }
  }else{
    addValidated = true
  }

  //DELETE USERS
  if(deletedUsers && deletedUsers.length>0){
    let resDeletedUsers = []
    await asyncForEach(deletedUsers, async (user) => {
      let url = '/user/'+user._id
      await deleteData( ERROR_USERS, url, dispatch , true).then((response)=>{
        resDeletedUsers.push(response.message)
      })
    })
    if(deletedUsers.length === resDeletedUsers.length){
      deletedValidated = true
    }
  }else{
    deletedValidated = true
  }

  if(updatedUsers && updatedUsers.length>0){
    resUpdatedUsers = []
    await asyncForEach(updatedUsers, async (user) => {
      let url = '/user/'+user._id
      await putData( ERROR_USERS, true, url, dispatch , user).then((response)=>{
        resUpdatedUsers.push(response.user)
      })
    })
    if(updatedUsers.length === resUpdatedUsers.length){
      updatedValidated = true
    }
  }else{
    updatedValidated = true
  }

  if(addValidated && updatedValidated && deletedValidated){
    dispatch({
      type : UPDATE_USERS,
      payload : [...resNewUsers,...resUpdatedUsers]
    })
  }


}

export function getAllBadges(data, filename = "Badges"){

  let token = localStorage.getItem('token');

  let config = {
    responseType: 'blob',
    headers: {
      Authorization: token
    }
  };

  return new Promise(async (resolve)=>{
    await axios.post(`${API_URL}/user/badge`, data ,config).then(function (response) {
      let blob = new Blob([response.data])

      saveAs(blob,filename+'.pdf')
      resolve()

    })
    .catch(function (error) {
      console.log(error);
    })
    resolve()
  })


}

export function getBadge(userId, filename = "Mon_badge"){

  let token = localStorage.getItem('token');

  let config = {
    responseType: 'blob',
    headers: {
      Authorization: token
    }
  };

  return new Promise(async (resolve) => {
    axios.get(`${API_URL}/user/badge/${userId}`, config).then(function (response) {
      let blob = new Blob([response.data])
      
      saveAs(blob,filename+'.pdf')
      resolve()

    })
    .catch(function (error) {
      console.log(error);
    })
  })


}

export async function updateTrophyVote(dispatch,data){
  let url = '/user/trophy/'+localStorage.getItem('id')
  return new Promise(async (resolve)=>{
    dispatch({
      type : USERS_LOADING
    })

    let user;
    await putData( ERROR_USERS, true, url, dispatch , data).then((response)=>{
      user = response
    })

    resolve(user)


    dispatch({
      type : VOTE_USER,
      payload : user,
    })
  })

}

export async function updateFeedback(dispatch,data){
  let url = '/user/feedback/'+localStorage.getItem('id')

  return new Promise((resolve)=>{
    let user;
    putData( ERROR_USERS, true, url, dispatch , data).then((response)=>{
      user = response
      resolve(response)
    })
  })

}

export async function updateQuizz(dispatch,data){
  let url = '/user/quizz/'+localStorage.getItem('id')

  return new Promise((resolve)=>{
    let user;
    putData( ERROR_USERS, true, url, dispatch , data).then((response)=>{
      user = response
      dispatch({
        type : QUIZZ_USER,
        payload : user,
      })
      resolve(response)
    })
  })



}
