import React, { useState } from 'react';
import styles from './input-image.module.scss'; // You can create a CSS file for styling

const InputImage = ({
  defaultValue,
  disabled,
  handleChange,
  handleDelete,
  accept = 'image/*',
}) => {
  const [imageSrc, setImageSrc] = useState(defaultValue || null);
  const [error, setError] = useState('');

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file.size > 1 * 1024 * 1024) {
      setError('Le poids du fichier ne doit pas dépasse 1Mo.');
      return;
    } else {
      setError('');
    }
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
    if (handleChange) handleChange(file);
  };

  const handleDeleteClick = () => {
    setImageSrc(null);
    if (handleDelete) handleDelete();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file.size > 1 * 1024 * 1024) {
      setError('Le poids du fichier ne doit pas dépasse 1Mo.');
      return;
    } else {
      setError('');
    }
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
    if (handleChange) handleChange(file);

  };

  return (
    <div className={styles.inputFileContainer}>
      <input
        type="file"
        accept={accept}
        onChange={handleFileChange}
        id="fileInput"
        disabled={disabled}
        className={styles.inputFile}
      />
      <label htmlFor="fileInput">
        <div
          className={`${styles.dropZone} ${imageSrc ? styles.hasImage : ''} ${disabled ? styles.disabled : ''}`}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          {imageSrc ? (
            <div className={styles.imageContainer}>
              <img src={imageSrc} alt="Uploaded" />
            </div>
          ) : (
            <p>Déposer l'image<br/>d'illustration</p>
          )}
        </div>
      </label>
      <div className={styles.info}>
        {error && (
          <p className={styles.error}>{error}</p>
        )}
        {!disabled && <label htmlFor="fileInput">
          Ajouter une image
        </label>}
        {(imageSrc && !disabled) && (
          <p className={styles.delete} onClick={handleDeleteClick}>Supprimer l'image</p>
        )}
      </div>
    </div>
  );
};

export default InputImage;
