import React, { useState, useEffect } from 'react';
import { useSpring, animated, config } from 'react-spring';
import IconPromo from '../../assets/images/icons/icon-promo.svg';

export default function () {
  const [trigger, setTrigger] = useState(true);

  const { x } = useSpring({
    x: trigger ? 0 : 1,
    config: { ...config.wobbly },
  });

  useEffect(() => {
    setTimeout(() => {
      setTrigger(!trigger);
    }, 7000);
  }, [trigger]);

  return (
    <div className="flash">
      <animated.div style={{
        transform: x.interpolate({
          range: [0, 0.5, 1],
          output: [1, 0.8, 1],
        }).interpolate((x) => `scale(${x})`),
      }}>
        <img src={IconPromo} alt="promo" />
      </animated.div>
    </div>
  );
}
