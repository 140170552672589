import {
  GET_PROVIDER,
  PROVIDER_LOADING,
  ERROR_PROVIDER,
} from '../actions/types';

const initialState = { providers: null, isLoading: false, error: null };
export default function providerReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROVIDER:
      const arrProviders = action.payload.filter((d) => d.showroomNumber || d.showroomNumber === 0);
      return { ...state, providers: arrProviders };
    case PROVIDER_LOADING:
      return { ...state, isLoading: true };
    case ERROR_PROVIDER:
      return { ...state, error: action.payload.error, isLoading: false };
    default:
      return { ...state };
  }
}
