import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import LogoLeclercMobile from '../../assets/images/logo-leclerc-mobile.svg';
import LogoLeclerc from '../../assets/images/logo-leclerc-header.svg';

export default function CGU() {
  const history = useHistory();
  useEffect(() => {
    const container = document.querySelector('.cgu');
    let scrollTo;
    if (history.location.hash === '#trophy') {
      scrollTo = document.getElementById('trophy');
    } else if (history.location.hash === '#quizz') {
      scrollTo = document.getElementById('quizz');
    }
    if (scrollTo) container.scrollTop = scrollTo.offsetTop + 60;
  }, []);

  return (
    <div className="cgu">
      <div className="header">
        <div className="logo">
          <img src={LogoLeclercMobile} alt="logo leclerc" />
          <img src={LogoLeclerc} alt="logo leclerc" />
          <h2>BTLec Ouest</h2>
        </div>
      </div>
      <div className="content">
        <button onClick={() => history.goBack()}>Retour</button>
        <h2>Assistance</h2>
        <p>
          Contact : Elodie LUSTEAU ou Sandrine RICHARD
        </p>
        <p>
          02 40 85 10 10
        </p>
        <p>
          salon@btlecouest.leclerc
        </p>
        <h2 id="trophy">ELECTION DU PLUS BEAU STAND</h2>
        <p>
          A l’occasion du Salon BT LEC OUEST qui se déroulera les 15 et 16 mai 2024, il sera le second jour procédé à l’élection du plus stand d’<b>électroménager</b> et du plus beau stand de <b>produits multimédia.</b>
        </p>
        <p>
          Toutes les personnes attachées à des Centres E.LECLERC (salariés ou dirigeants) et porteuse d’un badge peuvent participer à ces deux élections.
        </p>
        <p>
          Le vote s’effectuera électroniquement sur un support informatique dédié et à disposition de tous.
        </p>
        <p>
          Une liste alphabétique des exposants « électroménager » et une liste alphabétique des exposants « multimédia » seront consultables et le vote s’effectuera à l’aide du badge et par un simple clic sur le nom de l’exposant.
        </p>
        <p>
          Le vote est discrétionnaire.
        </p>
        <p>
          Le scrutin sera clos à 15h30 et le décompte effectué ci-après.
        </p>
        <p>
          Les deux vainqueurs se verront attribuer, à titre gratuit, un stand de 6m² pour le Salon BT LEC OUEST 2024.
        </p>
        <p>
          Les règles de ce scrutin seront à disposition de tous à l’accueil du Salon.
        </p>
        <h3>RESULTATS :</h3>
        <p>
          Le jeu sera clos le second jour à 15h30 et le relevé des réponses effectué dès après.
        </p>
        <p>
          Les Gagnants seront les fournisseurs ayant eu le plus de voix sachant que les voix de chacun des
          participants de BTLec Ouest compte pour 1/3 dans les résultats
        </p>
        <p>
          Pour le cas où il existerait des ex aequo le gagnant sera celui tiré au sort.
        </p>
        <p>
          Ce tirage au sort sera effectué par Madame Sandrine RICHARD.
        </p>
        <p>
          Le présent règlement du jeu sera disponible, à l’accueil du Salon.
        </p>
      </div>
    </div>
  );
}
