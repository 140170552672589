import React, {
  useState, useEffect, useMemo, useRef,
} from 'react';
import _ from 'lodash';
import { useGlobalContext } from '../../../GlobalContext';
import LineProduct from './LineProduct';

export default function ListProducts({ products, history }) {
  const isMounted = useRef();
  const [context, dispatch] = useGlobalContext();
  const [sortedProducts, setSortedProducts] = useState();
  const [sort, setSort] = useState({});
  const { productsReducer } = context;

  useEffect(() => {
    let cloneProducts = _.cloneDeep(products);

    if (sort && !_.isEmpty(sort)) {
      cloneProducts = _.orderBy(
        cloneProducts,
        sort.key,
        [sort.order,
          (d) => new Date(d.updatedAt).getTime(),
        ],
      );
    } else {
      cloneProducts = _.orderBy(
        cloneProducts,
        sort.key,
        [(d) => new Date(d.updatedAt).getTime()],
      );
    }

    setSortedProducts(cloneProducts);
  }, [sort, products]);

  function fnSortProducts(key, order) {
    const obj = {};
    obj.key = key;
    let updatedSort;
    if (!order) {
      if (sort.key === key && sort.order === 'desc') {
        updatedSort = {};
      } else if (sort.key === key && sort.order === 'asc') {
        obj.order = 'desc';
        updatedSort = obj;
      } else if (sort.key !== key) {
        obj.order = 'asc';
        updatedSort = obj;
      }
    } else if (sort.key === key && sort.order === order) {
      updatedSort = {};
    } else {
      obj.order = order;
      updatedSort = obj;
    }

    setSort(updatedSort);
  }

  function renderListProducts(){
    let list = sortedProducts.map((product,i)=>(
      <li key={`products-${i}`} style={{ cursor: 'pointer' }} onClick={() => history.push(`/edit-product-provider/${product._id}`)}>
        <LineProduct
          product={{...product}}
        />
      </li>
    ))

    return (
      <ul>
        {list}
      </ul>
    )

  }

  return useMemo(() => (
      <div className="list-products">
        <div className="sortBy hide-on-tablet">
          <div className="product-ref">
            <p onClick={() => fnSortProducts('ref')}>Référence</p>
            <SortBy keyName={'ref'} order={sort.key === 'ref' && sort.order} sort={fnSortProducts}/>
          </div>
          <div className="product-brand">
            <p onClick={() => fnSortProducts('brand')}>Marque</p>
            <SortBy keyName={'brand'} order={sort.key === 'brand' && sort.order} sort={fnSortProducts}/>
          </div>
          <div className="product-gencod">
            <p onClick={() => fnSortProducts('gencod')}>Gencod</p>
            <SortBy keyName={'gencod'} order={sort.key === 'gencod' && sort.order} sort={fnSortProducts}/>
          </div>
          <div className="product-packing">
            <p onClick={() => fnSortProducts('packing')}>Colisage</p>
            <SortBy keyName={'packing'} order={sort.key === 'packing' && sort.order} sort={fnSortProducts}/>
          </div>
          <div className="product-qty">
            <p onClick={() => fnSortProducts('count')}>Qté commandée</p>
            <SortBy keyName={'count'} order={sort.key === 'count' && sort.order} sort={fnSortProducts}/>
          </div>
          <div className="product-price">
            <p onClick={() => fnSortProducts('price')}>Prix d'achat</p>
            <SortBy keyName={'price'} order={sort.key === 'price' && sort.order} sort={fnSortProducts}/>
          </div>
        </div>
        {sortedProducts && renderListProducts()}
      </div>
  ), [sortedProducts, sort]);
}

const SortBy = ({ sort, order, keyName }) => (
    <div className="icon-sort" >
      <div className={order === 'desc' ? 'active' : ''} onClick={() => sort(keyName, 'desc')}>
      </div>
      <div className={order === 'asc' ? 'active' : ''} onClick={() => sort(keyName, 'asc')}>
      </div>
    </div>
);
