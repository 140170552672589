import React, { useState } from 'react';
import { Swipeable } from 'react-swipeable';
import { useTransition, animated } from 'react-spring';
import { useGlobalContext } from '../../GlobalContext';
import usePrevious from '../../../customHooks/usePrevious';
import useDimension from '../../../customHooks/useDimension';

import Checkbox from '../../partials/form_fields/Checkbox';
import Conditions from './Conditions';
import FormulaireMontage from './FormulaireMontage';
import Fichiers from './Fichiers';

export default function Montages() {
  const context = useGlobalContext()[0];
  const { montageReducer } = context;
  const { showRoomReducer } = context;
  const [index, setIndex] = useState(montageReducer.montage ? 1 : 0);
  const prevIndex = usePrevious(index);
  const dimension = useDimension();
  const [isChecked, setIsChecked] = useState(false);

  function goTo(targetIndex) {
    setIndex(targetIndex);
  }

  const components = [
    <Conditions key='conditions' setIsChecked={setIsChecked} isChecked={isChecked} goTo={goTo} mobile={true}/>,
    <FormulaireMontage key='form-montage' goTo={goTo} setIsChecked={setIsChecked} isChecked={isChecked}/>,
    <Fichiers key='files' goTo={goTo}/>,
  ];

  const transitions = useTransition(index, index, {
    from: (d) => {
      const x = prevIndex > d ? '-40%' : '40%';
      return { opacity: 0, transform: `translate3d(${x},0,0)` };
    },
    enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
    leave: (d) => {
      // d = index from (prevIndex)
      // index = to
      const x = d < index ? '-40%' : '40%';
      return { opacity: 0, transform: `translate3d(${x},0,0)` };
    },
  });

  function navLeft() {
    if (index === components.length - 1) return;
    let newIndex = index;
    newIndex++;
    setIndex(newIndex);
  }

  function navRight() {
    if (index === 1) return;
    let newIndex = index;
    newIndex--;
    setIndex(newIndex);
  }

  return (
    <>
      <Swipeable
        onSwipedLeft={(eventData) => navLeft(eventData)}
        onSwipedRight={(eventData) => navRight(eventData)}
      >
        <div className="montage">
          { dimension.width < 1200
            ? <>
              { index !== 0
                ? <div className="mobile-nav">
                  <ul>
                    <li className={index === 1 ? 'active' : ''} onClick={() => goTo(1)}>Ma demande</li>
                    <li className={index === 2 ? 'active' : ''} onClick={() => goTo(2)}>Fichiers</li>
                  </ul>
                </div> : null
              }
              {transitions.map(({ item, props, key }) => {
                const component = components[item];
                return <animated.div className={index !== 0 ? 'container-animated paddingTop' : 'container-animated'} key={key} style={{ ...props, position: 'absolute' }}>{component}</animated.div>;
              })}
            </>
            : <div className="content">
              <div className="title-page">
                <h2>Mon Montage</h2>
              </div>
              <div className="col">
                <Conditions setIsChecked={setIsChecked} isChecked={isChecked} mobile={false}/>
                <Fichiers showroomId={showRoomReducer.formData._id}/>
                <div className="content">
                  <Checkbox
                    value={isChecked}
                    toggleCheckbox={(val) => setIsChecked(!val)}
                    label={'J’ai bien pris connaissance des conditions ExpoNantes sur la gestion des déchets et m’engage à les respecter.'}
                  />
                </div>
              </div>
              <div className="col">
                <FormulaireMontage setIsChecked={setIsChecked} isChecked={isChecked}/>
              </div>
            </div>
          }

        </div>
      </Swipeable>
    </>
  );
}
