import React, { useMemo } from 'react';
import { Switch, Route } from 'react-router-dom';

import Inscription from './Inscription/Inscription';
import ModalSupportContact from '../../partials/ModalSupportContact';

const StoreSwitch = (props) => useMemo(
  () => (
      <>
      <ModalSupportContact/>
      <Switch>
        <Route exact path="/store/inscription" component={Inscription}/>
      </Switch>
      </>
  )
  // eslint-disable-next-line
  ,[props.location.pathname])

export default StoreSwitch;
