import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTransition, animated } from 'react-spring';
import Select from 'react-select';
import usePrevious from '../../../customHooks/usePrevious';
import { useGlobalContext } from '../../GlobalContext';
import { createMontage, updateMontage } from '../../../actions/montage';

import RecapitulatifMontage from './RecapitulatifMontage';

import InputField from '../../partials/form_fields/InputField';
import SelectItems from '../../partials/form_fields/SelectItems';
import SwitchField from '../../partials/form_fields/SwitchField';

import selectStyle from '../../partials/form_fields/selectStyle';

import { typeTrucks } from './typeTrucks';

export default function FormulaireMontage(props) {
  const [context, dispatch] = useGlobalContext();
  const { showRoomReducer } = context;
  const { companyReducer } = context;
  const { authReducer } = context;
  const { montageReducer } = context;

  const montageWasLoading = usePrevious(montageReducer.isLoading);

  const time = [
    '07:00', '08:00', '09:00', '10:00', '11:00', '12:00',
    '13:00', '14:00', '15:00', '16:00', '17:00', '18:00',
  ];

  const durationOptions = [
    { label: '00:30', value: 1 },
    { label: '01:00', value: 2 },
    { label: '01:30', value: 3 },
    { label: '02:00', value: 4 },
    { label: '02:30', value: 5 },
    { label: '03:00', value: 6 },
    { label: '03:30', value: 7 },
    { label: '04:00', value: 8 },
    { label: '04:30', value: 9 },
    { label: '05:00', value: 10 },
    { label: '05:30', value: 11 },
    { label: '06:00', value: 12 },
    { label: '06:30', value: 13 },
    { label: '07:00', value: 14 },
    { label: '07:30', value: 15 },
    { label: '08:00', value: 16 },
    { label: '08:30', value: 17 },
    { label: '09:00', value: 18 },
    { label: '09:30', value: 19 },
    { label: '10:00', value: 20 },
    { label: '10:30', value: 21 },
    { label: '11:00', value: 22 },
  ];

  const [data, setData] = useState({});
  const [selectedTime, setSelectedTime] = useState();

  const [duration, setDuration] = useState();
  const [truck, setTruck] = useState();
  const [delivery, setDelivery] = useState(false);
  const [presence, setPresence] = useState(false);

  const [forkliftTruck, setForkliftTruck] = useState(false);
  const [disabledTimes, setDisabledTimes] = useState([]);

  const [isDisabled, setIsDisabled] = useState(true);
  const [wasSubmitted, setWasSubmitted] = useState(false);

  const [showRoom, setShowRoom] = useState();

  const transitions = useTransition(wasSubmitted, null, {
    from: () => {
      if (!wasSubmitted) return;
      // eslint-disable-next-line consistent-return
      return { opacity: 0, transform: 'translate(0,100px)' };
    },
    enter: () => ({ opacity: 1, transform: 'translate(0,0)' }),
    leave: () => ({ opacity: 0, transform: 'translate(0,-100px)' }),
  });

  // const transitions = useTransition(wasSubmitted, null, {
  //   from: (d) => async (next, cancel) => {
  //     if(!wasSubmitted) return;
  //     return { opacity: 0, transform : 'translate(0,100px)' }
  //   },
  //   enter: { opacity: 1, transform : 'translate(0,0)' },
  //   leave: (d) => async (next, cancel) => {
  //     // return { opacity: 0, transform : 'translate(0,-100px)' }
  //     await next({ opacity: 1, transform : 'translate(0,0)' })
  //     await next({ opacity: 0, transform : 'translate(0,-100px)' })
  //   }
  // })

  // ACTIONS
  const _createMontage = (obj) => createMontage(dispatch, obj);
  const _updateMontage = (obj, id) => updateMontage(dispatch, obj, id);

  // INIT DATA
  useEffect(() => {
    if (!companyReducer.company) return;

    const init = {
      company: companyReducer.company._id,
      user: authReducer.user._id,
    };

    if (montageReducer.montage) {
      props.setIsChecked(true);

      const selectedTrucks = montageReducer.montage.truckType.map((truckValue) => ({ label: truckValue, value: truckValue }));

      init.contributorCount = montageReducer.montage.contributorCount;
      init.duration = montageReducer.montage.duration;
      init.time = montageReducer.montage.time;
      init.volume = montageReducer.montage.volume;
      init.presence = montageReducer.montage.presence;
      init.truckType = selectedTrucks;
      init.installationContact = montageReducer.montage.installationContact;
      init.deliveryContact = montageReducer.montage.deliveryContact;
      init.delivery = montageReducer.montage.delivery;
      init.forkliftTruck = montageReducer.montage.forkliftTruck;

      setTruck(selectedTrucks);
      setDuration(durationOptions.find((d) => d.label === montageReducer.montage.duration));
      setSelectedTime(montageReducer.montage.time);
      setPresence(montageReducer.montage.presence);
      setForkliftTruck(montageReducer.montage.forkliftTruck);
      setDelivery(montageReducer.montage.delivery);
    }

    setData(init);
  }, []);

  // HANDLE IS FORM VALID
  useEffect(() => {
    if (!props.isChecked) {
      setIsDisabled(true);
      return;
    }
    if (
      (data.contributorCount && data.contributorCount !== '' && data.contributorCount > 0)
      && (data.duration && data.duration.value !== '')
      && (data.volume && data.volume !== '' && data.volume > 0)
      && (data.time && data.time !== '')
      && data.truckType
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [
    data,
    props.isChecked,
  ]);

  // SET SHOWROOM
  useEffect(() => {
    setShowRoom(showRoomReducer.formData);
    return () => {
      setShowRoom(null);
    };
  }, [showRoomReducer.formData]);

  // HANDLE WAS SUBMITTED
  useEffect(() => {
    if (!montageReducer.isLoading && montageWasLoading) {
      document.querySelector('.montage').scrollTo(0, 0);
      setWasSubmitted(true);
    }
  }, [montageReducer.isLoading, montageWasLoading]);

  function handleChange(val, err, field) {
    switch (field) {
      case 'time':
        setSelectedTime(val);
        break;
      case 'duration':
        setDuration(val);
        break;
      case 'truckType':
        setTruck(val);
        break;
      case 'delivery':
        setDelivery(val);
        break;
      case 'forkliftTruck':
        setForkliftTruck(val);
        break;
      case 'presence':
        setPresence(val);
        break;
      default:
        break;
    }

    const updatedData = { ...data };

    if (err) {
      _.unset(updatedData, field, val);
      setData(updatedData);
    } else {
      _.set(updatedData, field, val);
      setData(updatedData);
    }
  }
  useEffect(() => {
    if (!duration) return;
    let hour = parseInt(duration.label.split(':')[0]);
    if (parseInt(duration.label.split(':')[1]) === 30) {
      hour += 1;
    }

    const items = time.slice(time.length - hour, time.length);
    setDisabledTimes(items);

    if (items.includes(selectedTime)) {
      handleChange(time[time.length - (items.length + 1)], 'time');
      setSelectedTime(time[time.length - (items.length + 1)]);
    }
  }, [duration]);

  function submit() {
    if (montageReducer.isLoading) return;
    const obj = _.cloneDeep(data);

    obj.truckType = obj.truckType.map((truckValue) => truckValue.label);
    obj.duration = obj.duration.label;

    if (montageReducer.montage && montageReducer.montage._id) {
      _updateMontage(obj, montageReducer.montage._id);
    } else {
      _createMontage(obj);
    }
  }

  function toggleForm() {
    setWasSubmitted(false);
  }

  const formulaire = (
    <div className="formulaire-montage">
      <div className="content">
        {(showRoom && showRoom.number && showRoom.isPlaced)
          && <h3>Numéro stand : {showRoom.number}</h3>
        }
        <div className="form">
          <p>Montage du mardi 14 mai 2024 de 7h à 19h</p>
          <div>
            <div className="container-field">
              <InputField
                id={'societe'}
                title={'Société'}
                value={(companyReducer.company && companyReducer.company.name) ? companyReducer.company.name : ''}
                required={false}
                disabled={true}
              />
            </div>
            <div className="container-field col-3">
              <InputField
                id={'contributor-count'}
                title={'Nombres de personnes'}
                value={data && data.contributorCount ? data.contributorCount : ''}
                placeholder={''}
                type={'number'}
                required={false}
                handleChange={(val) => handleChange(val, false, 'contributorCount')}
              />
              <div className="container-input">
                <label>Temps de montage</label>
                <Select
                  options={durationOptions}
                  value={duration}
                  isSearchable={false}
                  styles={selectStyle}
                  onChange={(val) => handleChange(val, false, 'duration')}
                />
              </div>
              <InputField
                id={'duration'}
                title={'Volume (en palettes)'}
                value={data && data.volume ? data.volume : ''}
                placeholder={''}
                type={'number'}
                handleChange={(val) => handleChange(val, false, 'volume')}
              />
            </div>
            <div className="container-field">
              <SwitchField
                checked={presence}
                handleChange={(val) => handleChange(val, false, 'presence')}
              >
                <p>
                  Présence pendant le montage ?<br/>
                </p>
              </SwitchField>
            </div>
            <div className="container-field time">
              <label>Heure de début du montage</label>
              <SelectItems
                items={time}
                selectedItems={selectedTime}
                disabledItems={disabledTimes}
                required={false}
                uniq={true}
                handleChange={(val) => handleChange(val, false, 'time')}
              />
            </div>
            <div className="container-input">
              <label>Type de camion</label>
              <Select
                options={typeTrucks}
                value={truck}
                isSearchable={false}
                styles={selectStyle}
                isMulti
                onChange={(val) => handleChange(val, false, 'truckType')}
              />
            </div>
            <div className="container-field">
              <SwitchField
                checked={forkliftTruck}
                handleChange={(val) => handleChange(val, false, 'forkliftTruck')}
              >
                <p>
                  Pour le déchargement avez-vous besoin du chariot élévateur ?<br/>
                  <span className="sub">Des transpalettes seront mis à votre disposition à l'accueil livraison.</span>
                </p>
              </SwitchField>
            </div>
          </div>
          <section className="container-coord">
            <div className="form-coord">
              <p>Coordonnées du livreur</p>
              <InputField
                id={'delivery-contact.name'}
                title={'Nom'}
                value={data && data.deliveryContact ? data.deliveryContact.name : ''}
                placeholder={''}
                type={'string'}
                handleChange={(val) => handleChange(val, false, 'deliveryContact.name')}
              />
              <InputField
                id={'delivery-contact.phone'}
                title={'Téléphone'}
                value={data && data.deliveryContact ? data.deliveryContact.phone : ''}
                placeholder={''}
                type={'string'}
                maskNumber={'## ## ## ## ##'}
                format={'phone'}
                handleChange={(val, err) => handleChange(val, err, 'deliveryContact.phone')}
              />
            </div>
            <div className="form-coord">
              <p>Coordonnées de l’installateur</p>
              <InputField
                id={'installation-contact-name'}
                title={'Nom'}
                value={data && data.installationContact ? data.installationContact.name : ''}
                placeholder={''}
                type={'string'}
                handleChange={(val) => handleChange(val, false, 'installationContact.name')}
              />
              <InputField
                id={'installation-contact-phone'}
                title={'Téléphone'}
                value={data && data.installationContact ? data.installationContact.phone : ''}
                placeholder={''}
                type={'string'}
                maskNumber={'## ## ## ## ##'}
                format={'phone'}
                handleChange={(val, err) => handleChange(val, err, 'installationContact.phone')}
              />
              <div className="container-field">
                <SwitchField
                  checked={delivery}
                  handleChange={(val) => handleChange(val, false, 'delivery')}
                  conditional={delivery
                  && <p>
                    <span className="sub">En cas de livraisons sèches (type chronopost) que les équipes BTLec Ouest réceptionnent à Exponantes, merci de préciser sur le colis le NOM et N° du stand.<br/>
                    + mention décharge de responsabilité</span>
                  </p>}
                >
                  <p>
                    J’ai une livraison de type sèche (Chronopost, DHL...)
                  </p>
                </SwitchField>
              </div>
            </div>
          </section>
          <div className="container-button">
            <button className={isDisabled || montageReducer.isLoading ? 'disabled' : ''} onClick={submit}>
              Valider
              {montageReducer.isLoading && <span className="loader"></span>}
            </button>
            {!props.isChecked && <p>Vous devez confirmer avoir pris connaissance des conditions ExpoNantes</p>}

          </div>
        </div>
      </div>
    </div>
  );

  const components = [
    formulaire,
    <RecapitulatifMontage
      key='RecapitulatifMontage'
      company={(companyReducer.company && companyReducer.company.name)
        ? companyReducer.company.name
        : '' }
      toggleForm={toggleForm}
    />,
  ];

  return (
    <>
      {transitions.map(({ item, props: transitionProps, key }) => {
        const component = components[item ? 1 : 0];
        return <animated.div className="form-animated" key={key} style={{ ...transitionProps, position: 'absolute', width: '100%' }}>{component}</animated.div>;
      })}
    </>
  );
}
