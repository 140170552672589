import React, { useState, useEffect } from 'react';
import { Swipeable } from 'react-swipeable';
import { useTransition, animated } from 'react-spring';
import {
  Switch, NavLink, Route, useHistory,
} from 'react-router-dom';
import useDimension from '../../../customHooks/useDimension';
import usePrevious from '../../../customHooks/usePrevious';

import Transports from './Transports';
import Hebergements from './Hebergements';
import Restauration from './Restauration';
import Horaires from './Horaires';
import Cocktail from './Cocktail';
// import Header from '../../partials/Header/Header';
import ModalSupportContact from '../../partials/ModalSupportContact';

export default function Informations() {
  const history = useHistory();
  const dimension = useDimension();

  const arrRoute = [
    '/informations/',
    '/informations/transports',
    '/informations/hebergements',
    '/informations/restauration',
    '/informations/cocktail',
  ];
  const [index, setIndex] = useState();
  const prevIndex = usePrevious(index);

  const [left, setLeft] = useState(0);

  const transitions = useTransition(index, index, {
    from: (d) => {
      const x = prevIndex > d ? '-40%' : '40%';
      return { opacity: 0, transform: `translate3d(${x},0,0)` };
    },
    enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
    leave: (d) => {
      // d = index from (prevIndex)
      // index = to
      const x = d < index ? '-40%' : '40%';
      return { opacity: 0, transform: `translate3d(${x},0,0)` };
    },
  });

  useEffect(() => {
    let newIndex = arrRoute.indexOf(history.location.pathname);
    if (newIndex === -1) newIndex = 0;
    setIndex(newIndex);
  }, [history.location.pathname]);

  useEffect(() => {
    if (index === undefined || dimension.width > 767) {
      if (left !== 0) setLeft(0);
    } else {
      const links = document.querySelectorAll('.informations-navigation>a');
      let t = links[index].offsetLeft + (links[index].offsetWidth / 2);
      t -= (window.innerWidth / 2);
      setLeft(-t);
    }
  }, [index, dimension.width]);

  function navLeft() {
    if (index === arrRoute.length - 1) return;
    let newIndex = index;
    newIndex++;
    history.push(arrRoute[newIndex]);
    setIndex(newIndex);
  }

  function navRight() {
    if (index === 0) return;
    let newIndex = index;
    newIndex--;
    history.push(arrRoute[newIndex]);
    setIndex(newIndex);
  }

  return (
    <>
    <ModalSupportContact/>
    <Swipeable
      onSwipedLeft={(eventData) => navLeft(eventData)}
      onSwipedRight={(eventData) => navRight(eventData)}
    >
      <div className="informations">
        <div className="informations-navigation" style={{ transform: `translateX(${left}px)` }}>
          <NavLink exact to="/informations/">Les horaires</NavLink>
          <NavLink exact to="/informations/transports">Se rendre au salon</NavLink>
          <NavLink exact to="/informations/hebergements">Hébergements</NavLink>
          <NavLink exact to="/informations/restauration">Restauration</NavLink>
          <NavLink exact to="/informations/after">Cocktail after work</NavLink>
        </div>
        {
          transitions.map(({ props, key }) => (
            <animated.div key={key} className="container-informations" style={{ ...props }}>
              <Switch>
                <Route exact path={'/informations/'} component={Horaires}/>
                <Route exact path={'/informations/transports'} component={Transports}/>
                <Route exact path={'/informations/hebergements'} component={Hebergements}/>
                <Route exact path={'/informations/restauration'} component={Restauration}/>
                <Route exact path={'/informations/after'} component={Cocktail}/>
              </Switch>
            </animated.div>
          ))
        }
    </div>
    </Swipeable>
    </>
  );
}
