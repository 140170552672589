import axios from 'axios';
import {
  ERROR_AUTH,
} from './types';

export const API_URL = process.env.REACT_APP_API_URL;

export function getData(errorType, url, dispatch, isAuthReq) {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;
    const token = localStorage.getItem('token');

    const config = {
      headers: {
        Authorization: token,
      },
    };

    axios.get(requestUrl, config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject();
        errorHandler(dispatch, error, errorType);
      });
  });
}

export function deleteData(errorType, url, dispatch, isAuthReq) {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;
    const token = localStorage.getItem('token');

    const config = {
      headers: {
        Authorization: token,
      },
    };

    axios.delete(requestUrl, config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        errorHandler(dispatch, error, errorType);
      });
  });
}

export function postData(errorType, isAuthReq, url, dispatch, data) {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;

    const config = {
      timeout: 1000 * 60,
    };

    if (isAuthReq) {
      config.headers = { Authorization: localStorage.getItem('token') };
    }

    axios.post(requestUrl, data, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        errorHandler(dispatch, error, errorType);
        reject();
      });
  });
}

export function postDataFormData(errorType, isAuthReq, url, dispatch, data) {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;

    const config = {
      timeout: 1000 * 60,
      headers: {
        "Content-Type": "multipart/form-data",
      }
    };

    if (isAuthReq) {
      config.headers.Authorization = localStorage.getItem('token');
    }

    axios.post(requestUrl, data, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        errorHandler(dispatch, error, errorType);
        reject();
      });
  });
}

export function putData(errorType, isAuthReq, url, dispatch, data) {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;
    // console.log(requestUrl);

    const config = {
      timeout: 1000 * 60,
    };

    if (isAuthReq) {
      config.headers = { Authorization: localStorage.getItem('token') };
    }

    axios.put(requestUrl, data, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject();
        errorHandler(dispatch, error, errorType);
      });
  });
}

export function putDataFormData(errorType, isAuthReq, url, dispatch, data) {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;
    // console.log(requestUrl);

    const config = {
      timeout: 1000 * 60,
      headers: {
        "Content-Type": "multipart/form-data",
      }
    };

    if (isAuthReq) {
      config.headers.Authorization = localStorage.getItem('token');
    }

    axios.put(requestUrl, data, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject();
        errorHandler(dispatch, error, errorType);
      });
  });
}

export function errorHandler(dispatch, error, type) {
  console.log('Error type: ', type);
  console.log(error);

  if (type === ERROR_AUTH && error.response !== undefined && error.response.status === 401) {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
    }
  }

  if (window.location.hash !== '#/acdlec'
    && window.location.hash !== '#/login'
    && error.response.status === 401) {
    window.location.assign('/#/');
  }

  dispatch({
    type,
    payload: error.response.data,
  });
}
