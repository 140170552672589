import React, {
  useMemo, useState, useEffect, useContext,
} from 'react';
import _ from 'lodash';
import { useTransition, animated } from 'react-spring';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useGlobalContext } from '../../../GlobalContext';

import { StepProvider } from './StepContext';
import ModalContext from '../../../ModalContext';

import HeaderStandForm from './HeaderStandForm';
import Step1 from './step1/Step1';
import Step2 from './step2/Step2';
import Recapitulatif from './recapitulatif/Recapitulatif';
import SubmitSucceed from './SubmitSucceed';
import ModalSupportContact from '../../../partials/ModalSupportContact';

const StandForm = (props) => {
  const context = useGlobalContext()[0];
  const { authReducer } = context;
  const { showRoomReducer } = context;
  const history = useHistory();

  const modalContext = useContext(ModalContext);

  const transitions = useTransition(history.location, (location) => location.pathname, {
    from: { opacity: 0, transform: 'translate(40%,0)' },
    enter: { opacity: 1, transform: 'translate(0,0)' },
    leave: { opacity: 0, transform: 'translate(-40%,0)' },
  });

  // STATE / CONTEXT
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);

  // NOTE : set step 1 if there are all needed data
  useEffect(() => {
    if (_.isEmpty(showRoomReducer.formData)) return;

    if (
      showRoomReducer.formData.option
     && showRoomReducer.formData.option.direction
     && showRoomReducer.formData.dimension) {
      setStep1(true);
    }

    if (
      showRoomReducer.formData.option
     && showRoomReducer.formData.option.customDimension
     && showRoomReducer.formData.option.customDimension.L
     && showRoomReducer.formData.option.customDimension.l
     && showRoomReducer.formData.dimension) {
      setStep1(true);
    }
  }, [showRoomReducer.formData]);

  // NOTE : set step 2 if there are all needed data
  useEffect(() => {
    if (_.isEmpty(showRoomReducer.formData) || !showRoomReducer.formData.option) return;

    if (showRoomReducer.formData.option.shape) {
      if (
        (
          (showRoomReducer.formData.option.storage
          && parseInt(showRoomReducer.formData.option.storageSize) > 0)
          || !showRoomReducer.formData.option.storage
        )
        && (
          showRoomReducer.formData.option.carpet
          || (showRoomReducer.formData.option.customCarpet) // && !!showRoomReducer.formData.option.carpetDesc
        )
      ) {
        setStep2(true);
      } else if (
        (showRoomReducer.formData.option.storage && parseInt(showRoomReducer.formData.option.storageSize) === 0)
        || (
          !showRoomReducer.formData.option.carpet || !showRoomReducer.formData.option.customCarpet
          || (!showRoomReducer.formData.option.customCarpet)
        )
      ) {
        setStep2(false);
      }
    }
  }, [showRoomReducer.formData]);

  return useMemo(() => {
    const StepProviderValue = {
      step1,
      setStep1,
      step2,
      setStep2,
    };

    return (
      <>
      <div className="stand-form" style={{ overflowX: 'hidden' }}>
        <StepProvider value={StepProviderValue}>
          <div className="content">
            <HeaderStandForm {...props} user={authReducer.user}/>
          </div>
          <ModalSupportContact />
          {showRoomReducer.formData.isValid ? (
            <div className="container-step">
              <div className="content">
                <Route exact path="/stand/*" render={
                () => <Recapitulatif/>
                }/>
              </div>
            </div>
          ) : (
            <>
              {
                transitions.map(({ item, props, key }) => (
                  <animated.div key={key} className="container-step" style={{ ...props }}>
                    <div className="content">
                      <Switch location={item}>
                        <Route exact path="/stand/step-1/" render={
                          () => <Step1/>
                        }/>
                        <Route exact path="/stand/step-2/:id?" render={
                          () => <Step2/>
                        }/>
                        <Route exact path="/stand/recapitulatif" render={
                          () => <Recapitulatif/>
                        }/>
                        <Route exact path="/stand/submit-succeed" component={SubmitSucceed}/>
                      </Switch>
                    </div>
                  </animated.div>
                ))
              }
            </>
          )}

        </StepProvider>
      </div>
      </>
    );
    // eslint-disable-next-line
  },[
    history.location.pathname,
    step1,
    step2,
    modalContext.isModalOpen,
  ]);
};

export default StandForm;
