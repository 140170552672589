import {
  getData,
  postData,
  putData,
  deleteData,
} from './index';

import {
  // SET_COUNT_PRODUCT,
  GET_PRODUCT_BY_ID,
  GET_PRODUCTS,
  POST_PRODUCT,
  PUT_PRODUCT,
  DELETE_PRODUCT,
  GET_LIST_FILTERS,
  GET_PRODUCTS_FLASH,
  PRODUCTS_LOADING,
  RESET_SELECTED_PRODUCT,
  SET_PRODUCTS_FILTERS,
  RESET_ERROR_PRODUCTS,
  ERROR_PRODUCTS,
} from './types';

export async function getProductByID(dispatch, id) {
  const url = `/product/${id}`;

  dispatch({
    type: PRODUCTS_LOADING,
  });

  let product;
  await getData(ERROR_PRODUCTS, url, dispatch, true).then((response) => {
    product = response.data.product;
  });

  dispatch({
    type: GET_PRODUCT_BY_ID,
    payload: product,
  });
}

export async function setProductsFilters(dispatch, filters) {
  dispatch({
    type: SET_PRODUCTS_FILTERS,
    payload: filters,
  });
}

export async function resetSelectedProduct(dispatch, filters) {
  dispatch({
    type: RESET_SELECTED_PRODUCT,
  });
}

export async function getProductsFlash(dispatch) {
  const url = '/product/flash';

  dispatch({
    type: PRODUCTS_LOADING,
  });

  let products;
  await getData(ERROR_PRODUCTS, url, dispatch, true).then((response) => {
    products = response.data.products;
  });

  dispatch({
    type: GET_PRODUCTS_FLASH,
    payload: products,
  });
}

export async function getListFilters(dispatch) {
  const url = '/product/list';

  let list;
  await getData(ERROR_PRODUCTS, url, dispatch, true).then((response) => {
    list = response.data;
  });

  dispatch({
    type: GET_LIST_FILTERS,
    payload: list,
  });
}

export async function getProductsFiltered(dispatch, data) {
  const url = '/product/filtered';

  const token = localStorage.getItem('token');
  if (!token) return;

  dispatch({
    type: PRODUCTS_LOADING,
  });

  let products;
  let count;
  await postData(ERROR_PRODUCTS, true, url, dispatch, data).then((response) => {
    count = response.count;
    products = response.products;
  });

  dispatch({
    type: GET_PRODUCTS,
    payload: { products, count, page: data.page },
  });
}

export async function getProducts(dispatch) {
  const url = '/product';

  const token = localStorage.getItem('token');
  if (!token) return;

  dispatch({
    type: PRODUCTS_LOADING,
  });

  let products;
  await getData(ERROR_PRODUCTS, url, dispatch, true).then((response) => {
    products = response.data.products;
  });

  dispatch({
    type: GET_PRODUCTS,
    payload: products,
  });
}

export async function getProductsProvider(dispatch, id) {
  const url = '/product/company';

  dispatch({
    type: PRODUCTS_LOADING,
  });

  let products;
  let ca;
  await postData(ERROR_PRODUCTS, true, url, dispatch, { companyId: id }).then((response) => {
    products = response.products;
    ca = response.ca;
  }).catch((err) => {
    products = [];
  });

  dispatch({
    type: GET_PRODUCTS,
    payload: {
      products, count: products.length, ca, providerProducts: true,
    },
  });
}

export async function postProduct(dispatch, data) {
  const url = '/product';

  const token = localStorage.getItem('token');
  if (!token) return;

  dispatch({
    type: PRODUCTS_LOADING,
  });

  const response = await postData(ERROR_PRODUCTS, true, url, dispatch, data);

  console.log(response);

  dispatch({
    type: POST_PRODUCT,
    payload: {},
  });

  return response;
}

export async function putProduct(dispatch, data) {
  const url = `/product/${data._id}`;

  const token = localStorage.getItem('token');
  if (!token) return;

  dispatch({
    type: PRODUCTS_LOADING,
  });

  const response = await putData(ERROR_PRODUCTS, true, url, dispatch, data);

  dispatch({
    type: PUT_PRODUCT,
    payload: response.product,
  });
  
  return response;
}

export async function deleteProduct(dispatch, productId) {
  const url = `/product/${productId}`;

  const token = localStorage.getItem('token');
  if (!token) return;

  dispatch({
    type: PRODUCTS_LOADING,
  });

  const response = await deleteData(ERROR_PRODUCTS, url, dispatch);

  dispatch({
    type: DELETE_PRODUCT,
    payload: {
      product: productId,
    },
  });

  return response;
}

export async function resetErrorProducts(dispatch) {
  dispatch({
    type: RESET_ERROR_PRODUCTS,
  });
};