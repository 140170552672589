import React, {
  useState, useEffect, useMemo, useRef
} from 'react';
import axios from 'axios';
import _, { forEach } from "lodash";
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { MdDeleteForever } from 'react-icons/md';
import { useGlobalContext } from '../../../GlobalContext';
import {
  getProductByID,
  postProduct,
  putProduct,
  resetSelectedProduct,
  resetErrorProducts,
  deleteProduct,
} from '../../../../actions/products';
import TooltipInfo from '../../../partials/TooltipInfo';
import UploadFile from '../../../partials/form_fields/UploadFile';
import { PatternFormat } from 'react-number-format';
import InputField from '../../../partials/form_fields/InputField';
import SelectItems from '../../../partials/form_fields/SelectItems';
import selectStyle from '../../../partials/form_fields/selectStyle';
import InputImage from '../../../partials/form_fields/InputImage';
import IconDiscount from '../../../../assets/images/icons/icon-discount.svg'
import IconGift from '../../../../assets/images/icons/icon-gift-solid.svg'
import IconPunch from '../../../../assets/images/icons/icon-punch.svg'
import styles from './edit-product.module.scss';

const availabilityOptions = [
  { label: "De suite", value: "right now" },
  { label: "Autre", value: "other" },
];

const uploadFile = async (file) => {

  const formData = new FormData();
  formData.append('file', file);

  const headers = {
    headers: {
      "Authorization": localStorage.getItem('token'),
      "Content-Type": "multipart/form-data",
      // 'Content-Type': `multipart/form-data; boundary=${formData.getBoundary()}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/file`,
    formData,
    headers,
  );
  return response?.data;
};

const deleteFile = async (fileId) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/file/${fileId}`, {
    method: 'DELETE',
    headers: {
      "Authorization": localStorage.getItem('token'),
    },
  });
  return response;
};

const EditProduct = ({ history }) => {
  const params = useParams();
  const [context, dispatch] = useGlobalContext();
  const [formData, setFormData] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [init, setInit] = useState(false);
  const [file, setFile] = useState(); // Only for create Product
  const [customerBenefit, setCustomerBenefit] = useState();
  const {
    companyReducer,
    productsReducer,
  } = context;
  const { product, isLoading, error } = productsReducer;
  const { company } = companyReducer;
  const inputFilesRef = useRef();

  //ACTIONS
  const _getProductByID = (id) => getProductByID(dispatch, id);
  const _postProduct = (product) => postProduct(dispatch, product);
  const _putProduct = (product) => putProduct(dispatch, product);
  const _deleteProduct = (id) => deleteProduct(dispatch, id);
  const _resetSelectedProduct = () => resetSelectedProduct(dispatch);
  const _resetErrorProducts = () => resetErrorProducts(dispatch);
  
  useEffect(() => {
    if (!params.id) {
      setInit(true);
      return;
    };
    _getProductByID(params.id);
  }, [params.id]);

  useEffect(() => {
    return () => {
      _resetSelectedProduct();
      _resetErrorProducts();
    }
  }, []);

  useEffect(() => {
    if (!product) return;

    setFormData({
      ref: product.ref,
      brand: product?.brand || '',
      gencod: product.gencod,
      description: product.description,
      product: product.product,
      packing: product.packing,
      price: product.price,
      pricePA: product?.pricePA || '',
      box: product?.box || '',
      availability: product.availability,
      availabilityComment: product?.availabilityComment || '',
      deee: product?.deee,
      tax: product?.tax,
      gift: product?.gift || '',
      punch: product?.punch || '',
      discount: product?.discount || '',
    });


    if (product.discount) {
      setCustomerBenefit('discount');
    } else if (product.gift) {
      setCustomerBenefit('gift');
    } else if (product.punch) {
      setCustomerBenefit('punch');
    }
  
    setInit(true);

  }, [productsReducer.product]);

  useEffect(() => {
    const tax = Number.parseFloat(formData?.tax);
    const deee = Number.parseFloat(formData?.deee);

    // console.group('Required');
    // console.log('gencod', `/${formData?.gencod}/`, formData?.gencod?.length);
    // console.log('price', formData.price);
    // console.log('deee', deee);
    // console.log('tax', tax);
    // console.log('packing', formData?.packing);
    // console.log('box', formData?.box);
    // console.log('formData?.image', formData?.image);
    // console.log('product?.image', product?.image);
    // console.groupEnd();

    if (
      formData?.ref &&
      (formData?.gencod && formData?.gencod.length === 13) &&
      formData?.description &&
      ((formData?.packing !== 'box' && formData?.packing) || (formData?.packing === 'box' && formData.box)) &&
      formData?.price &&
      formData?.product &&
      formData?.availability &&
      (formData?.image || product?.image) &&
      (typeof tax === "number" && !Number.isNaN(tax)) &&
      (typeof deee === "number" && !Number.isNaN(deee)) &&
      (
        (customerBenefit === 'gift' && formData?.gift)
        || (customerBenefit === 'punch' && formData?.punch)
        || (customerBenefit === 'discount' && formData?.discount)
      )
    ) {
      // console.log('Valid');
      setIsValid(true)
    } else {
      // console.log('Not valid');
      setIsValid(false)
    }

  }, [formData, customerBenefit]);

  // useEffect(() => {
  //   if (props.animationDone) {
  //     inputFilesRef.current.setFileName();
  //   }
  // }, [props.animationDone]);

  const submit = async () => {
    if (product && !['pending', 'correction'].includes(product?.status)) return;

    if (!isValid) return;
    
    if (product) {
      const data = { ...formData };

      const response = await _putProduct({
        _id: product._id,
        ...data
      });
    } else {
      const data = { ...formData };

      let fileId = null;
      if (file) {
        const dataFile = await uploadFile(file);
        // const dataFile = await responseFile.json();
        if (dataFile.message === 'file saved') fileId = dataFile.files[0]._id;
      }

      const response = await _postProduct({
        company: companyReducer.company._id,
        ...data,
        file: fileId,
      });
      if (response.message === 'product saved') history.push('/products-provider');
    }
  };

  const handleChangeImage = async (img) => {
    if (product && !['pending', 'correction'].includes(product?.status)) return;

    _resetErrorProducts();
    if (!product) {
      const toBase64 = (file) => new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = () => reject(null);
      });

      const base64 = await toBase64(img);
      if (base64) handleChange(base64, null, "image");
    } else {
      if (product.image) await deleteFile(product.image._id);
      const data = await uploadFile(img);
      // const data = await response.json();
      if (data.message === 'file saved') {
        _putProduct({
          _id: product._id,
          ...formData,
          image: data.files[0]._id,
        })
      }
    }
  };

  const handleDeleteImage = async () => {
    if ((product && !['pending', 'correction'].includes(product?.status)) || !product) return;
    if (!product) {
      setFile(null);
      return;
    }
    _resetErrorProducts();
    if (product.image) await deleteFile(product.image._id);
    _putProduct({
      _id: product._id,
      ...formData,
      image: null,
    });
  };

  const handleChange = (val, error, field) => {
    _resetErrorProducts();
    let updatedData = { ...formData };
    if (error) {
      _.unset(updatedData, field, val);
    } else {
      _.set(updatedData, field, val);
    }

    if (field === 'packing' && val !== 'box') {
      _.set(updatedData, 'box', '');
    }

    if (field === 'availability' && val !== 'other') {
      _.set(updatedData, 'availabilityComment', '');
    }

    setFormData(updatedData)
  };
  
  const handleChangeFile = async (file) => {
    if (product && !['pending', 'correction'].includes(product?.status)) return;
    if (!product) {
      setFile(file);
      return;
    }

    _resetErrorProducts();

    if (file) {
      if (product.file) await deleteFile(product.file._id);
      const data = await uploadFile(file);
      // const data = await response.json();
      if (data.message === 'file saved') {
        _putProduct({
          _id: product._id,
          ...formData,
          file: data.files[0]._id,
        })
      }
    } else if (product.file) {
      deleteFile(product.file._id).then(() => {
        _putProduct({
          _id: product._id,
          ...formData,
          file: null,
        });
        inputFilesRef?.current?.setFileName();
      });
    }


  };

  const handleDeleteProduct = async () => {
    if (product && !['pending', 'correction'].includes(product?.status)) return;
    const response = await _deleteProduct(product._id);
    if (response.status === 200 ) history.push('/products-provider');
  };

  const handleCustomerBenefit = (benefit) => {
    if (product && (product.status === 'valid' || product.status === 'refused')) return;

    _resetErrorProducts();

    let updatedData = { ...formData };
    setCustomerBenefit(benefit);

    _.set(updatedData, 'discount', '');
    _.set(updatedData, 'punch', '');
    _.set(updatedData, 'gift', '');

    setFormData(updatedData)
  };

  const errorMessage = useMemo(() => {
    let message = error;
    if (error === 'This product gencod is already in use.') {
      message = 'Ce gencod est déjà utilisé.';
    }
    return message;
  }, [error]);

  const defaultImageValue = useMemo(() => {
    if (!product?.image) return null;
    const uri = `${process.env.REACT_APP_API_URL}/files/${product.image.path.replace('upload/','')}`;
    return uri;
  }, [product?.image]);

  const optionsProduct = useMemo(() => company.products.map((product) => ({ label: product, value: product })), [company]);

  // handle value discount
  useEffect(() => {
    if (formData.price && formData.pricePA && customerBenefit === 'discount') {
      let discount = 100 - (formData.price * 100) / formData.pricePA;
      discount = parseFloat(discount).toFixed(2);
      if (discount < 0) discount = '0';
      handleChange(discount, false, "discount");
    } else {
      handleChange('', false, "discount");
    }
  }, [
    formData.price,
    formData.pricePA,
    customerBenefit
  ]);

  return (
    <div className={styles.formProduct}>
      <div className="content large">
        <div className={styles.header}>
          <h1>Ajout d'un nouveau produit</h1>
          {(
            !product ||
            product?.status && ['pending', 'correction'].includes(product.status)
          ) && (
            <button
              className={!isValid ? "disabled" : ''}
              onClick={() => submit()}
            >
              {product ? 'Modifier' : 'Ajouter'}
              {isLoading && (
                <div className={styles.loader}></div>
              )}
            </button>
          )}
        </div>
        {errorMessage && (
          <p className={styles.error}>{errorMessage}</p>
        )}
        {((!params.id && init) || (params.id && product && init)) && (
          <div className={styles.form}>
            <div className={styles.info}>
              <div className={styles.row}>
              <h4>Info</h4>
              {product && (
                <p className={`${styles.status} ${styles[product.status]}`}>
                  {product.status === 'pending' && 'En attente'}
                  {product.status === 'valid' && 'Validée'}
                  {product.status === 'refused' && 'Refusée'}
                  {product.status === 'correction' && 'À corriger'}
                </p>
              )}
              </div>
              <div className={styles.row}>
                <InputField
                  id='ref'
                  defaultValue={formData.ref || ""}
                  title="Référence produit"
                  placeholder=""
                  textTransform="uppercase"
                  type="string"
                  maxLength={19}
                  required={true}
                  disabled={product && (product.status === 'valid' || product.status === 'refused')}
                  handleChange={(val, error) => handleChange(val, error, "ref")}
                />
                <div className={styles.selectField}>
                  <label>Famille de produit*</label>
                  <Select
                    onChange={(val) => {
                      handleChange(val?.value, null, "product");
                    }}
                    options={optionsProduct}
                    value={!formData?.product ? null : optionsProduct.filter((d) => d.value === formData?.product)}
                    isSearchable={false}
                    styles={selectStyle}
                    isClearable
                    isDisabled={product && (product.status === 'valid' || product.status === 'refused')}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <InputField
                  id='brand'
                  defaultValue={formData.brand || ""}
                  title="Marque"
                  placeholder=""
                  type="string"
                  textTransform='uppercase'
                  maxLength={30}
                  required={true}
                  disabled={product && (product.status === 'valid' || product.status === 'refused')}
                  handleChange={(val, error) => handleChange(val, error, "brand")}
                />
                {/* <InputField
                  id='gencod'
                  defaultValue={formData.gencod || ""}
                  title="Gencod"
                  placeholder=""
                  type="string"
                  required={true}
                  maxLength={13}
                  inputMode='numeric'
                  disabled={product && (product.status === 'valid' || product.status === 'refused')}
                  handleChange={(val, error) => handleChange(val, error, "gencod")}
                /> */}
                <div className={`container-input ${styles.gencod}`}>
                  <label>Gencod*</label>
                  <PatternFormat
                    id='gencod'
                    defaultValue={formData.gencod || ""}
                    format="#############"
                    placeholder='_____________'
                    mask='_'
                    disabled={product && (product.status === 'valid' || product.status === 'refused')}
                    onChange={(e) => {
                      handleChange(e.target.value.trim(), !e.target.value, "gencod");
                    }}
                  />
                </div>

              </div>
              <div className={styles.row}>
                <div>
                  <label>
                    Image d'illustration*
                    <TooltipInfo text={
                      `L'image du produit doit être au format .png, .jpg ou .jpeg et avoir un poids maximum de 1Mo.`
                    }/>
                  </label>
                  <InputImage
                    defaultValue={defaultImageValue}
                    disabled={product && (product.status === 'valid' || product.status === 'refused')}
                    handleChange={handleChangeImage}
                    handleDelete={handleDeleteImage}
                    accept='image/png, image/jpeg'
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div>
                  <label>Designation du produit*</label>
                  <textarea
                    rows="5" cols="63"
                    value={formData.description || ""}
                    maxLength='100'
                    disabled={product && (product.status === 'valid' || product.status === 'refused')}
                    onChange={(e) => {
                      handleChange(e.target.value, null, "description");
                    }}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <UploadFile
                  ref={inputFilesRef}
                  isClearable
                  handleChange={(file) => handleChangeFile(file)}
                  defaultFilename={product?.file?.name}
                  buttonText={"Joindre une fiche produit"}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, image/png, image/jpeg, application/pdf"
                >
                  <p className="format">(pdf, jpg ou png, 4Mo)</p>
                </UploadFile>
                </div>
              <div className={styles.row}>
                <div className={styles.selectItemsFields}>
                  <label>Colisage</label>
                  <SelectItems
                    items={["Colis", "Box"]}
                    selectedItems={formData.packing === 'box' ? ['Box'] : ["Colis"]}
                    uniq={true}
                    handleChange={(val) => {
                      if (val === 'Box') handleChange('box', false, 'packing')
                      else handleChange('', false, 'packing')
                    }}
                  />
                </div>
              </div>
              <div className={styles.row}>
                {(formData.packing !== 'box') ? (
                  <InputField
                    id='packing'
                    defaultValue={formData.packing || ""}
                    title="Nbre d'UVC par colis"
                    placeholder=""
                    type="number"
                    allowEmpty={true}
                    required={true}
                    disabled={product && (product.status === 'valid' || product.status === 'refused')}
                    handleChange={(val, error) => handleChange(val, error, "packing")}
                  />
                ) : (
                  <div className={styles.box}>
                    <label>Détails box*</label>
                    <textarea
                      rows="5" cols="63"
                      value={formData.box || ""}
                      disabled={product && (product.status === 'valid' || product.status === 'refused')}
                      onChange={(e) => {
                        handleChange(e.target.value, null, "box");
                      }}
                    />
                    <p className={styles.sub}>
                      Indiquez Gencod, libellé, colisage, PA
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.price}>
              <h4>Tarif</h4>
              <div className={styles.row}>
                <InputField
                  id='price'
                  defaultValue={formData.price || ""}
                  title="Prix net salon"
                  placeholder=""
                  type="number"
                  step='any'
                  allowEmpty={true}
                  decimalScale={2}
                  required={true}
                  disabled={product && (product.status === 'valid' || product.status === 'refused')}
                  handleChange={(val, error) => {
                    handleChange(val, error, "price");
                  }}
                />
                <InputField
                  id='pricePA'
                  defaultValue={formData.pricePA || ""}
                  title="PA permanent (si permanent)"
                  placeholder=""
                  type="number"
                  step='any'
                  allowEmpty={true}
                  decimalScale={2}
                  required={false}
                  disabled={product && (product.status === 'valid' || product.status === 'refused')}
                  handleChange={(val, error) => handleChange(val, error, "pricePA")}
                />
              </div>
              <div className={styles.row}>
                <InputField
                  id='deee'
                  defaultValue={formData.deee || ""}
                  title="DEEE"
                  placeholder=""
                  type="number"
                  step='any'
                  allowEmpty={true}
                  decimalScale={2}
                  required={true}
                  disabled={product && (product.status === 'valid' || product.status === 'refused')}
                  handleChange={(val, error) => handleChange(val, error, "deee")}
                />
                <div className={styles.tax}>
                  <label>
                    Autres taxes*
                    <TooltipInfo text={
                      `A renseigner pour les taxes Eco mobilier et/ou SORECOP. Indiquer 0 si pas d'autre taxe`
                    }/>
                  </label>
                  <InputField
                    id='tax'
                    defaultValue={formData.tax || ""}
                    title=""
                    placeholder=""
                    type="number"
                    step='any'
                    allowEmpty={true}
                    decimalScale={2}
                    required={true}
                    disabled={product && (product.status === 'valid' || product.status === 'refused')}
                    handleChange={(val, error) => handleChange(val, error, "tax")}
                  />
                </div>
              </div>
              <div className={styles.more}>
                <label>Avantage</label>
                <ul className={product && (product.status === 'valid' || product.status === 'refused') ? styles.disabled : ''}>
                  <li>
                    <div
                      onClick={() => handleCustomerBenefit('gift')}
                      className={customerBenefit === 'gift' ? `${styles.circle} ${styles.selected}` : styles.circle}
                    >
                      <img src={IconGift} alt="cadeau" />
                    </div>
                    <p onClick={() => handleCustomerBenefit('gift')}>
                      Cadeau
                    </p>
                  </li>
                  <li>
                    <div
                      className={customerBenefit === 'punch' ? `${styles.circle} ${styles.selected}` : styles.circle}
                      onClick={() => handleCustomerBenefit('punch')}
                    >
                      <img src={IconPunch} alt="offre cout de poing" />
                    </div>
                    <p onClick={() => handleCustomerBenefit('punch')}>
                      Coup de poing
                    </p>
                  </li>
                  <li>
                    <div
                      className={customerBenefit === 'discount' ? `${styles.circle} ${styles.selected}` : styles.circle}
                      onClick={() => handleCustomerBenefit('discount')}
                    >
                      <img src={IconDiscount} alt="promotion" />
                    </div>
                    <p onClick={() => handleCustomerBenefit('discount')}>
                      Promotion
                    </p>
                  </li>
                </ul>
                {customerBenefit === 'gift' && (
                  <InputField
                    id='gift'
                    defaultValue={formData.gift || ""}
                    title="Cadeau"
                    placeholder=""
                    type="string"
                    disabled={product && (product.status === 'valid' || product.status === 'refused')}
                    handleChange={(val, error) => handleChange(val, error, "gift")}
                  />
                )}
                {customerBenefit === 'punch' && (
                  <InputField
                    id='punch'
                    defaultValue={formData.punch || ""}
                    title="Coup de poing"
                    placeholder=""
                    type="string"
                    disabled={product && (product.status === 'valid' || product.status === 'refused')}
                    handleChange={(val, error) => handleChange(val, error, "punch")}
                  />
                )}
                {customerBenefit === 'discount' && (
                  <InputField
                    id='discount'
                    defaultValue={formData.discount || ""}
                    value={formData.discount}
                    title="Pourcentage de réduction"
                    placeholder=""
                    type="number"
                    step='any'
                    allowEmpty={true}
                    decimalScale={2}
                    required={true}
                    disabled={true}
                    // disabled={product && (product.status === 'valid' || product.status === 'refused')}
                    // handleChange={(val, error) => handleChange(val, error, "discount")}
                  />
                )}
              </div>
            </div>
            <div className={styles.availability}>
              <h4>Disponibilité</h4>
              <div className={styles.row}>
                <div className={styles.selectField}>
                  <label>Disponibilité produit*</label>
                  <Select
                    onChange={(val) => {
                      handleChange(val?.value, null, "availability");
                    }}
                    options={availabilityOptions}
                    value={!formData?.availability ? null : availabilityOptions.filter((d) => d.value === formData?.availability)}
                    isSearchable={false}
                    styles={selectStyle}
                    isClearable
                    isDisabled={product && (product.status === 'valid' || product.status === 'refused')}
                  />
                </div>
              </div>
              <div className={styles.row}>
                {formData.availability === 'other' && (
                  <div className={styles.availabilityComment}>
                    <label>Délai de livraison</label>
                    <textarea
                      rows="5" cols="63"
                      value={formData.availabilityComment || ""}
                      disabled={product && (product.status === 'valid' || product.status === 'refused')}
                      onChange={(e) => {
                        handleChange(e.target.value, null, "availabilityComment");
                      }}
                    />
                  </div>
                )} 
              </div>
            </div>
          </div>
        )}
        {product && ['pending', 'correction'].includes(product.status) && (
          <div className={styles.delete}>
            <p onClick={() => handleDeleteProduct()}>
              <MdDeleteForever size={20} />
              <span>Supprimer l'article</span>
            </p>
          </div>
        )}
      </div>

    </div>
  )
};

export default EditProduct;
