import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../../../actions';
import Loader from '../../../partials/Loader';
import gfkLogo from '../../../../assets/images/gfk-logo.jpg';

const getConferences = () => new Promise((resolve, reject) => {
  const requestUrl = `${API_URL}/conference`;
  const token = localStorage.getItem('token');

  const config = {
    headers: {
      Authorization: token,
    },
  };

  axios.get(requestUrl, config)
    .then((response) => {
      resolve(response.data.conferences);
    })
    .catch((error) => {
      reject(error);
    });
});

export default function Conferences() {
  const [isLoading, setLoading] = useState(true);
  const [conferences, setConferences] = useState([]);

  useEffect(() => {
    getConferences().then((conferences) => {
      setConferences(conferences);
      setLoading(false);
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    });
  }, []);

  return (
    <div className="conferences content">
      {isLoading && <Loader />}
      <div className={"conferences-list"}>
        {conferences.map(({
          title, label, description, logo, type,
        }, index) => (
          <div className={`conference ${type || ''}`} key={`conference-${index}`}>
            {label && <p className="label">{label}</p>}
            {logo === 'gfk' && <img src={gfkLogo} alt="logo gfk" />}
            <h2>
              <span dangerouslySetInnerHTML={{ __html: `${title} <br />` }} />
              <span dangerouslySetInnerHTML={{ __html: description }} />
            </h2>
          </div>
        ))}
      </div>
    </div>
  );
}
