import React, { useRef } from 'react';
import IconDownload from '../../assets/images/icons/icon-download.svg';

export default function DownloadFile({
  filename, desc, url, img,
}) {
  const linkDownload = useRef();

  return (
    <div className="container-files">
      {img
        ? <img src={img} alt="preview" /> : null
      }
      <div className="container-filename">
        { filename
          ? <p className="filename">{filename}</p> : null
        }
        <p>{desc}</p>
      </div>
      <a ref={linkDownload} href={url} target="_blank" rel="noreferrer">
        <button>
        { filename
          ? <>Télécharger</> : <img src={IconDownload} alt={`Télécharger ${filename}`}/>
        }
        </button>
      </a>
    </div>
  );
}
