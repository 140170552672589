import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import useInitCompany from '../customHooks/useInitCompany';
import { useGlobalContext } from './GlobalContext';

import { ModalProvider } from './ModalContext';

import Loader from './partials/Loader';
import Header from './partials/Header/Header';

export default function HandleRole(props) {
  const context = useGlobalContext()[0];
  const { authReducer } = context;
  const initCompany = useInitCompany();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const ModalProviderValue = {
    isModalOpen,
    setIsModalOpen,
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      history.push('/');
    }
  }, [authReducer.isAuthenticated]);

  // MOUNT ONLY WHEN WE GET initCompany
  // IF PROPS initCompany EXIST,
  // BE SURE IT'S EQUAL TO THE CURRENT initCompany
  // WITH WHOME THE USER IS ASSOCIATED
  useEffect(() => {
    if (!initCompany.role) return;

    // SHOULD BE UNCOMMENTED
    if (initCompany.role === 'Member'
     && (history.location.pathname === '/store/inscription'
      || /subscribre/.test(history.location.pathname)
     )) {
      history.push('/salon');
    }

    if ((initCompany.type && initCompany.type === props.companyType) || (initCompany.type && !props.companyType)) {
      setIsLoading(false);
    } else if (initCompany.type && initCompany.type !== props.companyType) {
      setIsLoading(true);
      history.push('/');
    }
  }, [
    initCompany.type,
    initCompany.role,
    history.location.pathname,
  ]);

  return useMemo(() => (
      <>
        { isLoading
          ? <Loader/>
          : <ModalProvider value={ModalProviderValue}>
            <Header/>
            {props.children}
          </ModalProvider>
        }
      </>
  ), [
    isLoading,
    initCompany.type,
    initCompany.role,
    history.location.pathname,
    ModalProviderValue.isModalOpen,
  ]);
}
