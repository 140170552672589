import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Switch from 'react-switch';
import Select from 'react-select';
import { useGlobalContext } from '../../../../GlobalContext';
import usePrevious from '../../../../../customHooks/usePrevious';
import useDispatch from '../../../../../customHooks/useDispatch';
import useDimension from '../../../../../customHooks/useDimension';

import { updateCompanyUser, addCompanyUser, resetCompanyError } from '../../../../../actions/company';
import InputField from '../../../../partials/form_fields/InputField';
import SelectItems from '../../../../partials/form_fields/SelectItems';
import IconCocktail from '../../../../../assets/images/icons/icon-cocktail.svg';

import selectStyle from '../../../../partials/form_fields/selectStyle';

export default function FormContact({
  activities, user, isLoading, cancel,
}) {
  const [formData, setFormData] = useState({});
  const [optionsActivities, setOptionsActivities] = useState();
  const [isAfterworkChecked, setIsAfterworkChecked] = useState(false);
  const [selectedPresenceDays, setSelectedPresenceDays] = useState([]);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [presenceDays, setPresenceDays] = useState();

  const [context, dispatch] = useGlobalContext();
  const { companyReducer } = context;

  const wasLoading = usePrevious(isLoading);
  const dimension = useDimension();

  const [isEmailError, setIsEmailError] = useState(false);

  // ACTIONS
  const _updateCompanyUser = (data, id) => updateCompanyUser(dispatch, data, id);
  const _addCompanyUser = (data) => addCompanyUser(dispatch, data);
  const _resetCompanyError = useDispatch(resetCompanyError, dispatch);

  // HANDLE ERROR ON EMAIL
  useEffect(() => {
    setIsEmailError(/email/.test(companyReducer.error));
  }, [companyReducer.error]);

  // SET ACTIVITIES OPTIONS
  useEffect(() => {
    const options = activities.map((opt) => (
      { label: opt.label, value: opt.label }
    ));
    options.push({ label: 'AUCUN', value: null });
    setOptionsActivities(options);
    // eslint-disable-next-line
  }, [])

  // RESET COMPANY ERROR WHEN UNMOUNT
  useEffect(
    () => () => {
      _resetCompanyError();
    }
    // eslint-disable-next-line
  , [])

  useEffect(() => {
    const days = companyReducer.companyList.days.filter((d) => d.value !== 'cocktail');
    const combineDays = { value: null, label: null, combined: true };
    days.forEach((day) => {
      combineDays.value = combineDays.value ? `${combineDays.value}/${day.value}` : day.value;
      combineDays.label = combineDays.label ? `${combineDays.label} et ${day.label}` : day.label;
    });
    setPresenceDays([
      combineDays,
      ...companyReducer.companyList.days,
      { label: 'Ne sera pas présent', value: false },
    ]);
  }, []);

  // INIT ACTIVITIES
  useEffect(() => {
    if (!user) return;
    if (user.profile.activity) {
      // console.log(.filter(d => d !== null));
      const activities = user.profile.activity.map((d) => {
        if (d === null) {
          return { label: 'AUCUN', value: null };
        }
        return { label: d, value: d };
      });
      setSelectedActivities(activities);
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!user || !presenceDays) return;

    if (user.presence) {
      setSelectedPresenceDays(user.presence);
      setIsAfterworkChecked(user.presence.includes('cocktail'));
    }
  }, [presenceDays]);

  // CLOSE FORM CONTACTS AFTER SUBMIT
  useEffect(() => {
    if (wasLoading && !isLoading) {
      cancel();
    }
  }, [isLoading]);

  // HANDLE IS READY TO SUBMIT
  useEffect(() => {
    const emailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i;
    let data;

    // SUBMIT UPDATE USER
    if (user) {
      const sanitizeUser = user.email.split('//')[0];
      const initialProfile = {
        profile: user.profile,
        email: sanitizeUser,
        presence: user.presence,
      };
      data = _.merge(
        {},
        initialProfile,
        formData,
      );
    }

    // SUBMIT CREATE USER
    if (!user) {
      data = {
        profile: formData.profile,
        email: formData.email,
        presence: formData.presence,
      };
    }

    if (
      (data.profile
        && (data.profile.lastName && data.profile.lastName !== '')
        && (data.profile.firstName && data.profile.firstName !== '')
        && (data.profile.function && data.profile.function !== '')
        && (data.profile.activity && data.profile.activity.length > 0)
      ) && (
        data.email
        && emailReg.test(data.email)
      ) && (data.presence)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [formData]);

  function handleChange(val, error, field) {
    const updatedData = { ...formData };

    if (field === 'profile.activity') {
      setSelectedActivities(val || []);
    }

    if (field === 'presence' && val) {
      const found = presenceDays.find((day) => day.label === val);
      setSelectedPresenceDays([found.value]);
      if (isAfterworkChecked) {
        _.set(updatedData, field, [found.value, 'cocktail']);
      } else {
        _.set(updatedData, field, [found.value]);
      }
    } else {
      _.set(updatedData, field, val);
    }

    setFormData(updatedData);
  }

  function submit() {
    if (!isValid || companyReducer.isLoading) return;
    if (user) {
      submitUdpateUser();
    } else {
      submitCreateUser();
    }
  }

  function submitUdpateUser() {
    const initialData = {
      profile: user.profile,
      presence: user.presence,
    };
    delete initialData.profile.activity;

    const mergedData = _.merge(
      {},
      initialData,
      formData,
    );

    mergedData.company = user.company;
    mergedData.profile.activity = selectedActivities.map((d) => d.value);

    /// HANDLE PRESENCE DAYS
    // PREVENT ISSUE CAUSED BY MERGING presence day of different length
    if (formData.presence) mergedData.presence = formData.presence;
    mergedData.presence = mergedData.presence.filter((d) => !!d);

    mergedData.afterwork = mergedData.afterwork;
    if (mergedData.afterwork) {
      mergedData.presence = [...mergedData.presence, 'cocktail'];
    } else {
      mergedData.presence = mergedData.presence.filter((d) => d !== 'cocktail');
    }
    delete mergedData.afterwork;

    const isPresentEveryDays = !!mergedData.presence.find((d) => {
      const combined = presenceDays.find((day) => (day.value === d && day.combined));
      return combined;
    });

    if (isPresentEveryDays) {
      const combined = presenceDays.find((day) => day.combined);
      mergedData.presence = mergedData.presence.filter((d) => d !== combined.value);
      mergedData.presence.push(...combined.value.split('/'));
    }
    _updateCompanyUser(mergedData, user._id);
  }

  function submitCreateUser() {
    const obj = _.cloneDeep(formData);
    obj.company = companyReducer.company._id;

    /// HANDLE PRESENCE DAYS
    obj.presence = obj.presence.filter((d) => !!d);

    const isPresentEveryDays = !!obj.presence.find((d) => {
      const combined = presenceDays.find((day) => (day.value === d && day.combined));
      return combined;
    });

    if (isPresentEveryDays) {
      const combined = presenceDays.find((day) => day.combined);
      obj.presence = obj.presence.filter((d) => d !== combined.value);
      obj.presence.push(...combined.value.split('/'));
    }

    if (obj.afterwork) {
      obj.presence = [...obj.presence, 'cocktail'];
    } else {
      obj.presence = obj.presence.filter((d) => d !== 'cocktail');
    }

    if (obj.profile.activity) {
      obj.profile.activity = obj.profile.activity.map((d) => d.value);
    }
    obj.type = companyReducer.company.type;
    _addCompanyUser(obj);
  }

  function toggleAfterwork(val) {
    const updatedData = { ...formData };
    _.set(updatedData, 'afterwork', val);
    setFormData(updatedData);
    setIsAfterworkChecked(val);
  }

  function getSelectedItems() {
    const notPresent = presenceDays.find((day) => day.value === false);
    let found = presenceDays.filter((day) => selectedPresenceDays.includes(day.value));
    const combined = presenceDays.find((day) => day.combined);

    let presenceDaysWithoutCocktail = selectedPresenceDays.filter((d) => d !== 'cocktail');
    presenceDaysWithoutCocktail = [...new Set(presenceDaysWithoutCocktail)];
    if (_.difference(combined.value.split('/'), presenceDaysWithoutCocktail).length === 0) {
      found = selectedPresenceDays.filter((d) => !combined.value.split('/').includes(d));
      found.push(combined.value);
      found = presenceDays.filter((day) => found.includes(day.value));
    }

    if (found && found.length > 0) {
      return found.map((d) => d.label);
    }
    if (user) return [notPresent.label];
    return [];
  }

  return (
    <MobileWrapper isMobile={dimension.width < 767}>
      <section className={dimension.width > 767 ? 'module-contact' : 'module-contact mobile'}>

        {dimension.width < 767
          ? <h2>
            Inscription d’un collaborateur
          </h2> : null
        }
        <InputField
          id={user ? `lastName_${user._id}` : 'lastName'}
          value={user ? user.profile.lastName : ''}
          title={'Nom'}
          placeholder={''}
          type={'string'}
          textTransform={'uppercase'}
          accentCharacters={false}
          required={true}
          disabled={false}
          handleChange={(val, error) => handleChange(val, error, 'profile.lastName')}
        />
        <InputField
          id={user ? `firstName_${user._id}` : 'firstName'}
          value={user ? user.profile.firstName : ''}
          title={'Prénom'}
          placeholder={''}
          type={'string'}
          textTransform={'uppercase'}
          accentCharacters={false}
          required={true}
          disabled={false}
          handleChange={(val, error) => {
            handleChange(val, error, 'profile.firstName');
          }}
        />
        <InputField
          id={user ? `function_${user._id}` : 'function'}
          value={user ? user.profile.function : ''}
          title={'Fonction'}
          placeholder={''}
          type={'string'}
          textTransform={'uppercase'}
          required={true}
          disabled={false}
          maxLength={'30'}
          handleChange={(val, error) => handleChange(val, error, 'profile.function')}
        />
        <div className="container-email">
          <InputField
            id={user ? `email_${user._id}` : 'email'}
            value={user ? user.email.split('//')[0] : ''}
            title={'Email'}
            placeholder={''}
            type={'string'}
            format={'email'}
            required={true}
            disabled={!!user}
            handleChange={(val, error) => handleChange(val, error, 'email')}
          />
          {isEmailError && companyReducer.error
            ? <p className="warn">{companyReducer.error}</p> : null
          }
        </div>
        <div className="container-input">
          <label>Lieu de vente<span className="required">*</span></label>
          <Select
            onChange={(val) => handleChange(val, null, 'profile.activity')}
            options={optionsActivities}
            value={selectedActivities}
            isMulti={true}
            isSearchable={false}
            styles={selectStyle}
          />
        </div>
        <div className="container-input container-select-items">
          <label>Présence sur le salon <span className="required">*</span></label>
          {(presenceDays && presenceDays.length > 0)
            && <SelectItems
              items={presenceDays.filter((d) => d.label !== 'Cocktail').map((day) => day.label)}
              selectedItems={getSelectedItems()}
              required={true}
              uniq={true}
              handleChange={(val, error) => handleChange(val, error, 'presence')}
            />
          }
        </div>
        <div className="container-switch">
          <p> <img key="cocktail selected" src={IconCocktail} alt="cocktail" />Présence au cocktail After Work du 15 mai à 17h30<span className="required">*</span></p>
          <Switch
            onColor='#080'
            offColor='#FF0009'
            onChange={toggleAfterwork}
            checked={isAfterworkChecked}
          />
        </div>
        <div className="container-button">
          <button className="secondary" onClick={cancel}>Fermer</button>
          <button className={!isValid ? 'disabled' : ''} onClick={submit}>
            {user && user._id
              ? 'Modifier' : 'Ajouter'
            }
            {companyReducer.isLoading
              ? <div className="loader"></div> : null
            }
          </button>
        </div>
        {!isEmailError && companyReducer.error
          ? <p className="warn">{companyReducer.error}</p> : null
        }
      </section>
    </MobileWrapper>
  );
}

const MobileWrapper = ({ isMobile, wrapper, children }) => (
  isMobile
    ? <div className="container-mobile">
        <div className="overlay-module-contact"></div>
        {children}
      </div> : children
);
