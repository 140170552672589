import axios from 'axios';
import { saveAs } from 'file-saver';
import {
  API_URL, getData, postData, putData,
} from './index';

import {
  GET_MONTAGE,
  ADD_MONTAGE,
  UPDATE_MONTAGE,
  MONTAGE_LOADING,
  ERROR_MONTAGE,
} from './types';

export async function getMontage(dispatch, id) {
  const url = `/assembly/${id}`;
  let assembly;

  dispatch({
    type: MONTAGE_LOADING,
  });

  await getData(ERROR_MONTAGE, url, dispatch, true).then((response) => {
    assembly = response.data;
  });

  dispatch({
    type: GET_MONTAGE,
    payload: assembly,
  });
}

export function getAssemblyTicket(showroomID, filename = 'Etiquette_montage') {
  const token = localStorage.getItem('token');

  const config = {
    responseType: 'blob',
    headers: {
      Authorization: token,
    },
  };

  return new Promise(async (resolve) => {
    axios.get(`${API_URL}/showroom/label/${showroomID}`, config).then((response) => {
      const blob = new Blob([response.data]);

      saveAs(blob, `${filename}.pdf`);
      resolve();
    })
      .catch((error) => {
        console.log(error);
      });
  });
}

export async function createMontage(dispatch, data) {
  const url = '/assembly';

  dispatch({
    type: MONTAGE_LOADING,
  });

  let assembly;
  await postData(ERROR_MONTAGE, true, url, dispatch, data).then((response) => {
    console.log('response');
    console.log(response);
    assembly = response.assembly;
  });

  dispatch({
    type: ADD_MONTAGE,
    payload: assembly,
  });
}

export async function updateMontage(dispatch, data, id) {
  const url = `/assembly/${id}`;

  dispatch({
    type: MONTAGE_LOADING,
  });

  let assembly;
  await putData(ERROR_MONTAGE, true, url, dispatch, data).then((response) => {
    console.log(response);
    assembly = response.assembly;
  });

  dispatch({
    type: UPDATE_MONTAGE,
    payload: assembly,
  });
}
