import React, { useState, useEffect, useContext } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import { useGlobalContext } from '../../../GlobalContext';
import useDimension from '../../../../customHooks/useDimension';

import Checkmark from '../../../../assets/images/checkmark-circle-outline.svg';

import StepContext from './StepContext';

export default function HeaderStandForm(props) {
  const context = useGlobalContext()[0];
  const stepContext = useContext(StepContext);
  const { showRoomReducer } = context;
  const [active, setActive] = useState();
  const dimension = useDimension();

  const history = useHistory();
  const [subPart, setSubPart] = useState(null);

  useEffect(() => {
    if (
      history.location.pathname === '/stand/step-2'
      && (history.location.hash === '#1' || history.location.hash === '#2')
    ) {
      setSubPart(`2-${history.location.hash}`);
    }
  }, [history.location.hash]);

  useEffect(() => {
    setActive(props.match.params.step);
  }, [props.match.params.step]);

  function renderNavLink() {
    const currentActive = showRoomReducer.formData.isValid ? 'recapitulatif' : active;
    return (
      <>
        <li className={currentActive === 'step-1' ? 'nav-step active' : 'nav-step'} >
          <NavLink to="/stand/step-1" >1</NavLink>
          {stepContext.step1
            ? <div className="checkmark"><img src={Checkmark} alt="step 1 checked"/></div> : null
          }
        </li>
        <li className={currentActive === 'step-2' ? 'nav-step active' : 'nav-step'} >
          { subPart
            ? <div className="sub-nav">
              <NavLink to="/stand/step-2#1" >
                <div className={subPart === '2-#1' ? 'dot selected' : 'dot'}></div>
              </NavLink>
              <NavLink to="/stand/step-2#2" >
                <div className={subPart === '2-#2' ? 'dot selected' : 'dot'}></div>
              </NavLink>
            </div> : null
          }
          <NavLink to={dimension.width > 767 ? '/stand/step-2' : '/stand/step-2#1'} >2</NavLink>
          {stepContext.step2
            ? <div className="checkmark"><img src={Checkmark} alt="step 2 checked"/></div> : null
          }
        </li>
        <li className={currentActive === 'recapitulatif' ? 'nav-step active' : 'nav-step'} >
          <NavLink to="/stand/recapitulatif" >3</NavLink>
        </li>
      </>
    );
  }

  const getStyle = () => {
    const style = {};
    if (subPart) style.paddingTop = '15px';
    if (showRoomReducer.formData.isValid) style.pointerEvents = 'none';
    return style;
  }

  return (
    <div className={'nav-provider-from'}>
      <div className="title-page">
        <h2>Mon stand</h2>
      </div>
      <ul style={getStyle()}>
        {renderNavLink()}
      </ul>
    </div>
  );
}
