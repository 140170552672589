import React, { useState, useEffect } from 'react';

export default function SelectBoolean(props) {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    let value;
    if (props.nullable && (props.bool === null || props.bool === undefined)) {
      value = [];
    } else {
      value = props.bool ? 'Oui' : 'Non';
    }
    setSelected(value);
  }, [props.bool]);

  function toggleItems(value) {
    const bool = value === 'Oui';
    props.handleChange(bool);
  }

  function isSelected(id) {
    return selected.includes(id);
  }

  return (
    <div className="select-items">
      <ul>
        {props.items
          ? <>
          {props.items.map((item, i) => (
            <li
              key={`${item}-${i}`}
             className={isSelected(item) ? 'selected' : ''}
             onClick={() => toggleItems(item)}
             id={item}>
             <span>
              &nbsp;{item}&nbsp;
             </span>
            </li>
          ))}
          </> : null
        }
      </ul>
    </div>
  );
}
