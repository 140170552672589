import moment from 'moment';

export const dateStart = moment('15/05/2024 8:30', 'DD/MM/YYYY HH:mm');
export const dateEnd = moment('16/05/2024 16:30', 'DD/MM/YYYY HH:mm');
//
export const dateEndQuizz = moment('16/05/2024 15:30', 'DD/MM/YYYY HH:mm');

export const PRODUCTS_PER_REQUEST = 10;

// export const dateStart = moment("13/09/2023 8:30", "DD/MM/YYYY HH:mm")
// export const dateEnd = moment("14/09/2023 18:00", "DD/MM/YYYY HH:mm")

// export const dateEndQuizz = moment("14/09/2023 15:30", "DD/MM/YYYY HH:mm")
