import React, { useState } from 'react';
import { Swipeable } from 'react-swipeable';

import { useTransition, animated, interpolate } from 'react-spring';
import usePrevious from '../../../customHooks/usePrevious';

export default function Slider({ components }) {
  const [index, setIndex] = useState(0);
  const prevIndex = usePrevious(index);

  const transitions = useTransition(index, index, {
    from: (d) => {
      const y = prevIndex > d ? -1 : 1;
      return { x: 0, y, opacity: 0 };
    },
    enter: { x: 1, y: 0, opacity: 1 },
    leave: (d) => {
      const y = d < index ? -1 : 1;
      return { x: 0, y, opacity: 0 };
    },
  });

  function navLeft() {
    if (index === components.length - 1) return;
    let newIndex = index;
    newIndex++;
    setIndex(newIndex);
  }

  function navRight() {
    if (index === 0) return;
    let newIndex = index;
    newIndex--;
    setIndex(newIndex);
  }

  return (
    <Swipeable
      onSwipedLeft={(eventData) => navLeft(eventData)}
      onSwipedRight={(eventData) => navRight(eventData)}
    >
      <div className="slider">
        {transitions.map(({ item, props, key }) => {
          const component = components[item];
          return <animated.div className={''} key={key} style={
            {
              position: 'absolute',
              opacity: props.opacity,
              transform: interpolate([props.y], (y) => `translate3d(${y * 40}px,0,0)`),

            }
          }>{component}</animated.div>;
        })}
        <ul className="slider-nav">
          {components.map((d, i) => <li key={`${i}`} className={index === i ? 'actived' : ''} onClick={() => setIndex(i)}></li>)}
        </ul>
      </div>
    </Swipeable>
  );
}
