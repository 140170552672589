import React, {
  forwardRef,
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
} from 'react';
import { NavLink } from 'react-router-dom';
import { useSpring, animated, config } from 'react-spring';

const AnimatedTile = ({ children }, ref) => {
  const tileRef = useRef();

  const [tiles, setTiles] = useState();
  const [animationOn, setAnimationOn] = useState(true);

  const { x } = useSpring({
    x: animationOn ? 0 : 1,
    config: { ...config.wobbly },
  });

  useImperativeHandle(ref, () => ({
    trigger: () => setAnimationOn(!animationOn),
  }));

  return (
    <animated.div style={{
      transform: x.interpolate({
        range: [0, 0.5, 1],
        output: [1, 0.8, 1],
      }).interpolate((x) => `scale(${x})`),
    }}>
      {children}
    </animated.div>

  );
};

export default forwardRef(AnimatedTile);
