/* eslint-disable */
export const typeTrucks = [
  {
    label: "Véhicule léger (voiture)",
    value: "Véhicule léger (voiture)"
  },
  {
    label: "Fourgon 3,5t (12m3 jusqu’à 30m3)",
    value: "Fourgon 3,5t (12m3 jusqu’à 30m3)"
  },
  {
    label: "Porteur 19t",
    value: "Porteur 19t"
  },
  {
    label: "Semi-remorque (+19t)",
    value: "Semi-remorque (+19t)"
  }
]
