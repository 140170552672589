import React, { useContext } from 'react';
import ModalContext from '../ModalContext';
import IconMail from '../../assets/images/icons/mail_icon.svg';
import IconPhone from '../../assets/images/icons/phone_icon.svg';

export default function ModalSupportContact() {
  const modalContext = useContext(ModalContext);

  return (
    <>
      { modalContext.isModalOpen
        ? <div className="modal-support">
          <p className="back" onClick={() => modalContext.setIsModalOpen(false)}>Retour</p>
          <h3>Une question ?</h3>
          <p>Contactez <br/>Sandrine Richard ou Elodie Lusteau</p>
          <p className="phone">
            <img src={IconPhone} alt="numéro de téléphone" />
            <a href="tel:02-40-85-10-10">02 40 85 10 10</a>
          </p>
          <p className="email">
            <img src={IconMail} alt="adresse email" />
            <a href="mailto:salon@btlecouest.leclerc">salon@btlecouest.leclerc</a>
          </p>
        </div> : null
      }
    </>
  );
}
