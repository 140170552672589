import React, {
  useState, useEffect, useRef, useContext,
} from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import Select from 'react-select';
import { useGlobalContext } from '../../../../GlobalContext';
import StepContext from '../StepContext';

import useDimension from '../../../../../customHooks/useDimension';

import { setFormShowroom } from '../../../../../actions/showroom';

import InputField from '../../../../partials/form_fields/InputField';
import Emplacement1 from '../../../../../assets/images/emplacement-1.svg';
import Emplacement2 from '../../../../../assets/images/emplacement-2.svg';

import selectStyle from '../../../../partials/form_fields/selectStyle';

const options = [
  { label: '1 Emplacement', value: 1 },
  { label: '2 Emplacements', value: 2 },
  { label: '3 Emplacements', value: 3 },
  { label: '4 Emplacements', value: 4 },
  { label: '5 Emplacements', value: 5 },
  { label: '6 Emplacements', value: 6 },
  { label: '7 Emplacements', value: 7 },
  { label: '8 Emplacements', value: 8 },
  { label: '9 Emplacements', value: 9 },
  { label: '10 Emplacements', value: 10 },
];

export default function Step2(props) {
  const [context, dispatch] = useGlobalContext();
  const hooksDimension = useDimension();
  const { showRoomReducer } = context;
  const { companyReducer } = context;
  const { authReducer } = context;
  const stepContext = useContext(StepContext);

  const [initialCustomDimension, setInitialCustomDimension] = useState();
  const [nbEmplacement, setNbEmplacement] = useState();
  const [direction, setDirection] = useState(null);
  const [dimension, setDimension] = useState(null);
  const [updatedChoice, setUpdatedChoice] = useState();

  const customDimension = useRef();
  const history = useHistory();

  // ACTIONS
  const _setFormShowroom = (data) => setFormShowroom(dispatch, data);

  /// INIT FORMDATA WITH COMPANYREDUCER AND AUTHREDUCER
  useEffect(() => {
    if (!companyReducer.company || !authReducer.user) return;
    const updatedData = _.cloneDeep(showRoomReducer.formData);
    updatedData.company = companyReducer.company._id;
    updatedData.user = authReducer.user._id;
    _setFormShowroom(updatedData);
  }, [
    companyReducer.company,
    authReducer.user,
  ]);

  /// INIT STEP 1 WITH FORMDATA FROM SHOWROOMREDUCER
  useEffect(() => {
    if (props.isLoading) return;

    if (showRoomReducer.formData
      && showRoomReducer.formData.option
      && showRoomReducer.formData.option.direction
    ) {
      setDirection(showRoomReducer.formData.option.direction);
    } else {
      setDirection('l');
    }

    if ((showRoomReducer.formData.option && !showRoomReducer.formData.option.customDimension)
      && showRoomReducer.formData.dimension) {
      const found = options.find((option) => option.value === showRoomReducer.formData.dimension / 6);
      setNbEmplacement(found);
    }

    if (showRoomReducer.formData.option
      && showRoomReducer.formData.option.customDimension
    ) {
      setInitialCustomDimension(showRoomReducer.formData.option.customDimension);
    }
  }, [props.isLoading]);

  // STEP CHECKED
  useEffect(() => {
    if (
      nbEmplacement
      || (customDimension.current && customDimension.current.l && customDimension.current.L)
    ) {
      stepContext.setStep1(true);
    } else {
      stepContext.setStep1(false);
    }
  }, [nbEmplacement, customDimension.current]);

  // WHEN LEAVING STEP 3
  // UPDATE showroom form data
  useEffect(() => {
    if (history.location.pathname !== '/stand/step-1') {
      if (updatedChoice === 'option-1') {
        const updatedData = _.cloneDeep(showRoomReducer.formData);

        let dimension;
        if (direction === 'l') {
          dimension = 3 * (nbEmplacement.value * 2);
        }
        if (direction === 'L') {
          dimension = 2 * (nbEmplacement.value * 3);
        }

        if (updatedData.option
          && updatedData.option.customDimension
        ) {
          delete updatedData.option.customDimension;
        }

        updatedData.dimension = dimension;
        if (!updatedData.option) updatedData.option = {};
        updatedData.option.direction = direction;

        _setFormShowroom(updatedData);
      }

      if (updatedChoice === 'option-2') {
        const updatedData = _.cloneDeep(showRoomReducer.formData);

        const dimension = customDimension.current.l * customDimension.current.L;

        updatedData.dimension = dimension;
        updatedData.option.direction = null;
        if (customDimension.current && customDimension.current.l && customDimension.current.L) {
          updatedData.customDimension = customDimension.current;
        }

        _setFormShowroom(updatedData);
      }
    }
  }, [history.location.pathname]);

  // OPTION 1
  function selectNbEmplacement(val) {
    let dimension;
    if (direction === 'l') {
      dimension = 3 * (val.value * 2);
    }
    if (direction === 'L') {
      dimension = 2 * (val.value * 3);
    }

    const updatedData = _.cloneDeep(showRoomReducer.formData);
    updatedData.dimension = dimension;
    delete updatedData.customDimension;
    _setFormShowroom(updatedData);
    setDimension(dimension);
    setNbEmplacement(val);

    setUpdatedChoice('option-1');
  }

  // OPTION 1
  function selectDirection(val) {
    const updatedData = _.cloneDeep(showRoomReducer.formData);
    if (!updatedData.option) updatedData.option = {};
    updatedData.option.direction = val;

    let dimension;

    if (nbEmplacement && val === 'l') {
      dimension = 3 * (nbEmplacement.value * 2);
    }
    if (nbEmplacement && val === 'L') {
      dimension = 2 * (nbEmplacement.value * 3);
    }

    updatedData.dimension = dimension;
    _setFormShowroom(updatedData);
    setDirection(val);

    setUpdatedChoice('option-1');
  }

  // OPTION 2
  function changeCustomDimension(val, field) {
    const updatedData = _.cloneDeep(showRoomReducer.formData);
    let dimension;

    if (!updatedData.option) updatedData.option = {};

    if (customDimension.current && customDimension.current.L && field === 'l') {
      dimension = customDimension.current.L * val;
      updatedData.option.customDimension = {
        l: val,
        L: customDimension.current.L,
      };
    }

    if (customDimension.current && customDimension.current.l && field === 'L') {
      dimension = customDimension.current.l * val;
      updatedData.option.customDimension = {
        l: customDimension.current.l,
        L: val,
      };
    }

    let obj;
    if (!customDimension.current) {
      obj = {};
    } else {
      obj = { ...customDimension.current };
    }
    obj[field] = val;
    customDimension.current = obj;

    if (dimension) {
      updatedData.dimension = dimension;
    }

    _setFormShowroom(updatedData);

    setUpdatedChoice('option-2');
  }

  function nextStep(choice) {
    setUpdatedChoice(choice);

    if (hooksDimension.width > 767) {
      history.push('/stand/step-2');
    } else {
      history.push('/stand/step-2#1');
    }
  }

  function total() {
    if (!nbEmplacement || !direction) return 0;
    if (direction === 'l') {
      return ((3 * (nbEmplacement.value * 2)) / 6) * 5700;
    }
    if (direction === 'L') {
      return ((2 * (nbEmplacement.value * 3)) / 6) * 5700;
    }
  }

  return (
    <div className="step step1">
      <p className="section-title">Location de l'espace d'exposition</p>
      <div className="choix-2-options">
        <section className="option choix-2-option-1">
            <div>
              <h4 >Option 1</h4>
              <p>
                Multiplication des stands de base <br/>
                (x1, x2, x3, x4)
              </p>
              <Select
               value={nbEmplacement}
               onChange={selectNbEmplacement}
               options={options}
               isSearchable={false}
               styles={selectStyle}
              />
              <div className="total">
                <p>TOTAL</p>
                <p>{`${total()}€`}</p>
              </div>
              <button className={nbEmplacement ? '' : 'disabled'} onClick={() => nextStep('option-1')} >Choisir</button>
            </div>
            { nbEmplacement && nbEmplacement.value > 1

              ? <div className="option-1">
                <div className={direction === 'l' ? 'selected' : ''} onClick={() => selectDirection('l')}>
                  <img src={Emplacement1} alt="emplacement type 1"/>
                  <p>
                    Emplacements<br/>
                    Dos à dos: 3 x { nbEmplacement ? nbEmplacement.value * 2 : 0}m
                  </p>
                </div>
                <div className={direction === 'L' ? 'selected' : ''} onClick={() => selectDirection('L')}>
                  <img src={Emplacement2} alt="emplacement type 2"/>
                  <p>
                    Emplacements<br/>
                    côte à côte: { nbEmplacement ? nbEmplacement.value * 3 : 0} x 2 m
                  </p>
                </div>
              </div> : null
            }

        </section>

        <section className="option choix-2-option-2">
          <h4>Option 2</h4>
          <p>
            Surface sur mesure avec au minimum <br/>
            6m2 + ajout de m2 supplémentaires<br/>
            <span className="sub">(1100 € / m2 supplémentaire)</span>
          </p>
          <div className="input-dimension">
            <span>L</span>
            <InputField
              value={initialCustomDimension ? initialCustomDimension.L : ''}
              placeholder={''}
              type={'number'}
              required={false}
              loading={false}
              handleChange={(val) => changeCustomDimension(val, 'L')}
            />
            <span>
            x P
            </span>
            <InputField
              value={initialCustomDimension ? initialCustomDimension.l : ''}
              placeholder={''}
              type={'number'}
              required={false}
              loading={false}
              handleChange={(val) => changeCustomDimension(val, 'l')}
            />
          </div>
          { customDimension.current && customDimension.current.l && customDimension.current.L
            && customDimension.current.l * customDimension.current.L > 5
            ? <button onClick={() => nextStep('option-2')}>Choisir</button>
            : <button className="disabled">Choisir</button>
          }
          { customDimension.current && customDimension.current.l && customDimension.current.L
            && customDimension.current.l * customDimension.current.L < 6
            ? <>
              <p className="warn">Merci de saisir une dimension supérieure à 6m2</p>
            </> : null
          }
        </section>

      </div>

    </div>

  );
}
