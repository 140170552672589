import React, { useState, useEffect } from 'react';
import { useHistory, NavLink } from 'react-router-dom';

import _ from 'lodash';
import axios from 'axios';

import { API_URL } from '../../actions/index';

import InputField from '../partials/form_fields/InputField';

import LogoLeclerc from '../../assets/images/logo-leclerc.svg';
import LoginImg from '../../assets/images/login-img.png';
import IconLock from '../../assets/images/icons/lock_icon.svg';
import IconMail from '../../assets/images/icons/mail_grey_icon.svg';

export default function ResetPassword() {
  const [formData, setFormData] = useState({});
  const [error, setError] = useState();
  const [validatedPassword, setValidatedPassword] = useState(false);
  const [message, setMessage] = useState();

  const history = useHistory();

  useEffect(() => {
    if (formData.password
      && formData.passwordConfirm
      && formData.password === formData.passwordConfirm
      && /(?=.*\d).{6,}/.test(formData.password)
      && !validatedPassword
    ) {
      setValidatedPassword(true);
    } else if (validatedPassword) {
      setValidatedPassword(false);
    }
  }, [formData]);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        history.push('/');
      }, 3000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [message]);

  function handleChangeField(val, field) {
    const updatedData = _.cloneDeep(formData);
    _.set(updatedData, field, val);
    setFormData(updatedData);
  }

  function RequestResetPassword() {
    let token = '';
    if (history.location.hash) {
      token = history.location.hash.replace('#', '');
    }

    const requestUrl = `${API_URL}/auth/reset-password/${token}`;

    axios.post(requestUrl, { password: formData.password })
      .then(() => {
        setMessage('Votre mot de passe à été changé, vous allez être redirigé vers la page de connexion');
      })
      .catch((err) => {
        setError({ message: err.response.data.error });
      });
  }

  function handlePressEnter(e) {
    if (e.key !== 'Enter') return;
    if (validatedPassword) {
      RequestResetPassword();
    }
  }

  return (
    <div className="page reset-password" >
      <div className="content">
        <div className="left">
          <img src={LoginImg} alt="Salon multimedia et electro" />
        </div>
        <div className="form-login" onKeyPress={handlePressEnter}>
          <img src={LogoLeclerc} alt="logo leclerc" />
          <h1>BTLec Ouest</h1>
          <h2>Salon Multimédia et Electro 15/16 mai 2024</h2>
          <h2>Réinitialiser mot de passe</h2>
          <div className="field-icon">
            <InputField
              value={''}
              title={''}
              placeholder={'Saissisez votre nouveau mot de passe'}
              type={'password'}
              required={true}
              handleChange={(val) => handleChangeField(val, 'password')}
            />
            <img src={IconLock} alt="mot de passe" />
          </div>
          <div className="field-icon">
            <InputField
              value={''}
              title={''}
              placeholder={'Confirmez votre mot de nouveau passe'}
              type={'password'}
              required={true}
              handleChange={(val) => handleChangeField(val, 'passwordConfirm')}
            />
            <img src={IconMail} alt="adresse email" />
          </div>
          <div className="centered">
            <p><span className="sub">Le mot de passe doit contenir aux moins 6 caractères et un 1 chiffre</span></p>
          </div>
          <div className="centered">
            <button className={validatedPassword ? '' : 'disabled'} onClick={RequestResetPassword}>Valider</button>
          </div>
          {error
            ? <p className="warn">{error.message}</p> : null
          }
          {message
            ? <p className="succeed">{message}</p> : null
          }
          <div className="centered">
            <NavLink to="/">Login</NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
