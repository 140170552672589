export function formatDataShowrooms(data) {
  const showroomData = {
    dimension: data.dimension,
    company: data.company,
    user: data.user,
    name: data?.name || '',
    option: {
      direction: data.option.direction,
      shape: data.option.shape,
      carpet: data.option.carpet,
      customCarpet: data.option.customCarpet ? data.option.customCarpet : false,
      // "carpetDesc": data.option.carpetDesc,
      water: data.option.water ? data.option.water : false,
      waterType: data.option.waterType,
      kit: data.option.kit ? data.option.kit : false,
      countKit: data.option.countKit,
      power: data.option.power ? data.option.power : false,
      storage: data.option.storage ? data.option.storage : false,
      storageSize: data.option.storageSize,
      standiste: {
        name: '',
        phone: '',
        email: '',
      },
    },
  };

  if (data.customDimension) {
    showroomData.option.customDimension = data.customDimension;
  }

  if (data.option.standiste) {
    showroomData.option.standiste = {
      name: data.option.standiste.name ? data.option.standiste.name : '',
      phone: data.option.standiste.phone ? data.option.standiste.phone : '',
      email: data.option.standiste.email ? data.option.standiste.email : '',
    };
  }

  return showroomData;
}

export function calculPrice(data) {
  const obj = {};
  let total = 0;

  if (data.option
    && data.option.direction
    && data.dimension
  ) {
    const { dimension } = data;
    total = (dimension / 6) * 5700;
  }

  if (data.option
    && data.option.customDimension
    && data.option.customDimension.l
    && data.option.customDimension.L
  ) {
    const dimension = data.option.customDimension.l * data.option.customDimension.L;
    total = ((Math.floor(dimension / 6)) * 5700) + (dimension % 6) * 1100;
  }

  obj.totalNoOptions = total;
  obj.totalOptions = 0;

  if (data.option
    && data.option.storageSize) {
    obj.storagePrice = data.option.storageSize * 50;
    obj.totalOptions += obj.storagePrice;
    total += (data.option.storageSize * 50);
  }

  if (data.option
    && data.option.power) {
    total += 275;
    obj.powerPrice = 275;
    obj.totalOptions += obj.powerPrice;
  }

  if (data.option
    && data.option.water) {
    total += 370;
    obj.waterPrice = 370;
    obj.totalOptions += obj.waterPrice;
  }

  if (data.option
    && data.option.internet) {
    total += 300;
    obj.internetPrice = 300;
    obj.totalOptions += obj.internetPrice;
  }

  obj.total = total;
  return obj;
}
