import { useState, useEffect } from 'react';

export default function useFormInput(initialValue, placeholder, type = 'text', required = false, allowEmpty = false, decimalScale) {
  const [value, setValue] = useState(initialValue);
  const [inputType, setInputType] = useState(type === 'string' ? 'text' : type);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  function handleChange(e, textTransform, accentCharacters = true) {
    if (e.target.value === '' && inputType === 'number' && !allowEmpty) {
      setValue(0);
    } else {
      let transformedValue = e.target.value;
      if (textTransform === 'uppercase') transformedValue = (e.target.value || '').toUpperCase();
      if (!accentCharacters) transformedValue = transformedValue.normalize("NFD").replace(/[\u0300-\u036f]/g, '');

      if (type === 'number' && transformedValue && decimalScale) {
        const decimal = transformedValue.split('.')[1];
        if (decimal?.length > decimalScale) {
          transformedValue = parseFloat(transformedValue).toFixed(decimalScale).toString();
        }
      }

      setValue(transformedValue);
    }
  }

  function setType(newType) {
    setInputType(newType);
  }

  const objDOMAttribute = {
    value,
    type: inputType,
    placeholder,
    onChange: handleChange,
  };

  if (type === 'number') {
    objDOMAttribute.min = 0;
  }

  return {
    DOMAttribute: objDOMAttribute,
    required,
    setType,
    setValue,
  };
}
