import React, {
  useEffect, useState, useContext, useRef,
} from 'react';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';
import StepContext from '../StepContext';
import SupportContact from '../../../../partials/SupportContact';

import { useGlobalContext } from '../../../../GlobalContext';
import { setFormShowroom } from '../../../../../actions/showroom';

import IconWarn from '../../../../../assets/images/icons/warn_icon.svg';
import shapeU from '../../../../../assets/images/shape-U.svg';
import shapeL from '../../../../../assets/images/shape-L.svg';
import shapeBack from '../../../../../assets/images/shape-back.svg';
import shapeOpen from '../../../../../assets/images/shape-open.svg';

export default function Shape(props) {
  const [context, dispatch] = useGlobalContext();
  const [selectedShape, setSelectedShape] = useState(null);
  const { showRoomReducer } = context;
  const stepContext = useContext(StepContext);
  const mounted = useRef();

  // ACTIONS
  const _setFormShowroom = (data) => setFormShowroom(dispatch, data);

  /// INIT SHAPE WITH FORMDATA FROM SHOWROOMREDUCER
  useEffect(() => {
    if (_.isEmpty(showRoomReducer.formData) || mounted.current) return;

    /// SHOULD BE CHANGE AND USE ISLOADING INSTEAD OF REF MOUNTED
    mounted.current = true;

    if (showRoomReducer.formData.option
      && showRoomReducer.formData.option.shape) {
      setSelectedShape(showRoomReducer.formData.option.shape);
    }
  }, [showRoomReducer.formData]);

  useEffect(() => {
    if (selectedShape) stepContext.setStep2(true);
  }, [selectedShape]);

  function selectShape(shape) {
    const updatedData = _.cloneDeep(showRoomReducer.formData);
    if (!updatedData.option) updatedData.option = {};
    updatedData.option.shape = shape;
    setSelectedShape(shape);
    _setFormShowroom(updatedData);
  }

  return (
    <div className="shape">
      <section>
        <ul>
          <li onClick={() => selectShape('U')} className={selectedShape === 'U' ? 'selected' : '' }>
            <img src={shapeU} alt="shape U"/>
            <p>En u</p>
          </li>
          <li onClick={() => selectShape('L')} className={selectedShape === 'L' ? 'selected' : '' }>
            <img src={shapeL} alt="shape L"/>
            <p>En L</p>
          </li>
          <li onClick={() => selectShape('back')} className={selectedShape === 'back' ? 'selected' : '' }>
            <img src={shapeBack} alt="shape back"/>
            <p>Cloison de fond</p>
          </li>
          <li onClick={() => selectShape('open')} className={selectedShape === 'open' ? 'selected' : '' }>
            <img src={shapeOpen} alt="shape open"/>
            <p>En îlot</p>
          </li>
        </ul>
        { selectedShape === 'open'
          ? <p className="warn-shape"><img src={IconWarn} alt="warning"/>Le stand n'aura pas de "voisins" et le fournisseur ne pourra pas mettre de cloisons personnelles en bordure de stand.</p> : null
        }
      </section>
      <p className='sub'><b>BTLec Ouest se garde le droit d’ajuster les cloisons en fonction des contraintes d’emplacement</b></p>
      { props.part === '#1'
        ? <div className="container-support">
          <SupportContact pageForm={true}/>
          {selectedShape
            ? <NavLink to="/stand/step-2#2"><button>Valider</button></NavLink>
            : <button className="disabled">Valider</button>
          }
        </div> : null
      }
    </div>
  );
}
