import React from 'react';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import { useGlobalContext } from '../../../GlobalContext';
import { deleteCompanyUser } from '../../../../actions/company';

import IconCocktailGrey from '../../../../assets/images/icons/icon-cocktail-grey.svg';
import IconCocktailWhite from '../../../../assets/images/icons/icon-cocktail-white.svg';
// import IconPencil from '../../../../assets/images/icons/icon-pencil.svg';
import IconPencilWhite from '../../../../assets/images/icons/icon-pencil-white.svg';
import IconPencilHover from '../../../../assets/images/icons/icon-pencil-hover.svg';
import DustBinIcon from '../../../../assets/images/icons/dustbin_icon.svg';
import DustBinIconWhite from '../../../../assets/images/icons/dustbin_icon-white.svg';
import DustBinIconHover from '../../../../assets/images/icons/dustbin_icon_hover.svg';

import FormContributors from './FormContributors';

export default function LineUser({
  user, isLoading, edit, selectUser, selectedUser, cancel,
}) {
  const [context, dispatch] = useGlobalContext();
  const { companyReducer } = context;

  // ACTIONS
  const _deleteCompanyUser = (id) => deleteCompanyUser(dispatch, id);

  function renderPresenceDays(days = []) {
    if (!companyReducer.companyList) return;
    let arr = [];

    const found = companyReducer.companyList.days.filter((opt) => days.includes(opt.value));

    if (found) {
      arr = found.map((d) => d.label);
    }

    return (
      <ul >
        <li className="only-on-tablet"><label>Présence sur le salon :</label></li>
        {companyReducer.companyList.days.map((d, i) => (
            <li key={`day-${i}`} className={_.includes(arr, d.label) ? 'day' : 'day disabled'}>
              <p className={d.value === 'cocktail' ? 'cocktail' : ''}><span>{d.label}</span>
              { d.value === 'cocktail'
                ? <>
                  { _.includes(arr, d.label)
                    ? <img key="cocktail selected" src={IconCocktailWhite} alt="cocktail"/>
                    : <img key="cocktail" src={IconCocktailGrey} alt="cocktail"/>
                  }
                </> : null
              }</p>
            </li>
        ))}
      </ul>
    );
  }

  function deleteUser() {
    const isConfirm = window.confirm('Attention, cette action supprimera l’utilisateur. Si il est déclaré comme “autre contact” de votre société, il ne recevra plus de mail. Voulez-vous continuer ?');
    if (isConfirm === true) {
      _deleteCompanyUser(user._id);
    }
  }

  function toggleFormContact() {
    if (edit) {
      cancel();
    } else {
      selectUser(user._id);
    }
  }

  function getClassName() {
    if (selectedUser && selectedUser !== user._id) {
      return 'line-user fade-out';
    }
    return isLoading && isLoading.action === 'delete' ? 'line-user loading' : 'line-user';
  }

  return (
    <>
    {!edit
      && <div className={getClassName()}>
        <div className="container hide-on-mobile">
          <div><p className="name">{user.profile.firstName} {user.profile.lastName}</p></div>
          <div><p>{user.profile.function}</p></div>
          <div><p>{user.email.split('//')[0]}</p></div>
        </div>
        <div className="presence-days">{renderPresenceDays(user.presence)}</div>
        <div className="edit">
          <div className="user only-on-mobile">
            <div><p className="name">{user.profile.firstName} {user.profile.lastName}</p></div>
            <div><p>{user.profile.function}</p></div>
            <div><p>{user.email.split('//')[0]}</p></div>
          </div>
          <div className="toggle-edit" onClick={toggleFormContact}>
            <label className="only-on-desktop">Modification</label>
            <img src={IconPencilHover} className="hide-on-mobile" alt={`modifier ${user.profile.firstName} ${user.profile.lastName}`} />
            <img src={IconPencilHover} className="hide-on-mobile" alt={`modifier ${user.profile.firstName} ${user.profile.lastName}`} />
            <img className="only-on-mobile" src={IconPencilWhite} alt={`modifier ${user.profile.firstName} ${user.profile.lastName}`} />
          </div>
          { user.role !== 'Owner'
            && <div className="delete" onClick={deleteUser}>
              <img className="hide-on-mobile" src={DustBinIcon} data-tip="Supprimer" alt={`supprimer ${user.profile.firstName} ${user.profile.lastName}`} />
              <img className="hide-on-mobile" src={DustBinIconHover} data-tip="Supprimer" alt={`supprimer ${user.profile.firstName} ${user.profile.lastName}`} />
              <img className="only-on-mobile" src={DustBinIconWhite} alt={`supprimer ${user.profile.firstName} ${user.profile.lastName}`} />
            </div>
          }
        </div>
        { isLoading && isLoading.action === 'update'
          ? <div className="loader"></div>
          : null
        }

      </div>
    }
    {edit
      && <FormContributors key={`update_${user._id}`} isLoading={isLoading} user={user} cancel={cancel}/>
    }
      <ReactTooltip globalEventOff="click" effect="solid" className="custom-tooltip hide-on-tablet" backgroundColor="#005FBF"/>
    </>
  );
}
