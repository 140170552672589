import {
  getData
} from './index';

import {
  GET_EXHIBITION,
  EXHIBITION_LOADING,
  ERROR_EXHIBITION,
} from './types';

export async function getExhibition(dispatch, id) {
  const url = '/exhibition';
  let exhibition;

  dispatch({
    type: EXHIBITION_LOADING,
  });

  await getData(ERROR_EXHIBITION, url, dispatch, true).then((response) => {
    exhibition = response.data.exhibition;
  });

  dispatch({
    type: GET_EXHIBITION,
    payload: exhibition,
  });
}
