import {
  /// when submit from /subscription (fournisseur page)
  ///
  UPDATE_USERS,
  /// /
  SET_CONTACTS_COMPANY,
  SET_FORM_COMPANY,
  ADD_COMPANY_USER,
  UPDATE_COMPANY_USER,
  DELETE_COMPANY_USER,
  UPDATE_COMPANY,
  GET_COMPANY,
  GET_COMPANY_LIST,
  COMPANY_LOADING,
  COMPANY_USER_LOADING,
  COMPANY_RESET_SUBMITTED,
  RESET_COMPANY_ERROR,
  RESET_COMPANY,
  ERROR_COMPANY,
} from '../actions/types';

const initialState = {
  company: null,
  formData: null,
  companyUsers: null,
  companyList: null,
  isLoading: false,
  isUserLoading: null,
  error: null,
  submitted: false,
};

let updateCompanyUser;
let updateIsUserLoading;

export default function companyReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COMPANY:
      return {
        ...state,
        company: action.payload.company,
        companyUsers: action.payload.users,
        isUserLoading: {
          map: new Map(),
          timestamp: new Date().getTime(),
        },
        isLoading: false,
        error: null,
      };
    case SET_FORM_COMPANY:
      return { ...state, formData: action.payload };
    case SET_CONTACTS_COMPANY:
      return { ...state, companyUsers: action.payload, error: null };
    case GET_COMPANY_LIST:
      return { ...state, companyList: action.payload, error: null };
    case ADD_COMPANY_USER:
      const newUser = action.payload.user;

      updateIsUserLoading = state.isUserLoading.map;
      updateIsUserLoading.delete('add');

      updateCompanyUser = [...state.companyUsers, newUser];
      return {
        ...state,
        companyUsers: updateCompanyUser,
        isUserLoading: {
          map: updateIsUserLoading,
          timestamp: new Date().getTime(),
        },
        error: null,
      };
    case UPDATE_COMPANY_USER:
      updateCompanyUser = [];
      const arr = [...state.companyUsers];
      arr.forEach((user) => {
        if (user._id === action.payload.user._id) {
          updateCompanyUser.push(action.payload.user);
        } else {
          updateCompanyUser.push(user);
        }
      });

      updateIsUserLoading = state.isUserLoading.map;
      updateIsUserLoading.delete(action.key);

      return {
        ...state,
        companyUsers: updateCompanyUser,
        isUserLoading: {
          map: updateIsUserLoading,
          timestamp: new Date().getTime(),
        },
        error: null,
      };
    case DELETE_COMPANY_USER:
      updateCompanyUser = [...state.companyUsers];
      updateIsUserLoading = state.isUserLoading.map;
      updateIsUserLoading.delete(action.payload);

      updateCompanyUser = updateCompanyUser.filter((user) => user._id !== action.payload);
      return {
        ...state,
        companyUsers: updateCompanyUser,
        isUserLoading: {
          map: updateIsUserLoading,
          timestamp: new Date().getTime(),
        },
        error: null,
      };
    case UPDATE_COMPANY:
      return {
        ...state, submitted: true, isLoading: false, error: null,
      };
    case UPDATE_USERS: // when submit from /subscription (fournisseur page)
      return { ...state, companyUsers: action.payload };
    case COMPANY_RESET_SUBMITTED:
      return { ...state, submitted: false };
    case COMPANY_LOADING:
      return { ...state, isLoading: true, submitted: false };
    case COMPANY_USER_LOADING:
      updateIsUserLoading = state.isUserLoading.map;
      updateIsUserLoading.set(action.payload.key, action.payload);
      return {
        ...state,
        isUserLoading: {
          map: updateIsUserLoading,
          timestamp: new Date().getTime(),
        },
      };
    case RESET_COMPANY:
      return {
        company: null, formData: null, companyUsers: null, companyList: null, isLoading: false, error: null, submitted: false,
      };
    case RESET_COMPANY_ERROR:
      return { ...state, error: null };
    case ERROR_COMPANY:
      let message = action.payload.error;
      if (message === 'That email address is already in use.') {
        message = 'Cette adresse email est déjà utilisé';
      }
      return { ...state, error: message };
    default:
      return { ...state };
  }
}
