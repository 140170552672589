import { useState, useEffect } from 'react';
import moment from 'moment';
import { dateStart, dateEnd, dateEndQuizz } from '../constant/index';

export default function useEventTakingPlace(event) {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    // Sept index = 8, get month start at 0
    const dStart = dateStart;
    let dEnd;

    if (event === 'quizz') {
      dEnd = dateEndQuizz;
    } else {
      dEnd = dateEnd;
    }

    const isBetween = moment().isBetween(dStart, dEnd);
    if (isBetween) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, []);

  return isActive;
}
