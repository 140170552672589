import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import InputNumber from '../../../partials/form_fields/InputNumber';
import { useGlobalContext } from '../../../GlobalContext';
import { setCountProduct } from '../../../../actions/products';

import { fnFormat } from '../../../../helpers/helpers';

export default function LineProduct({ product }) {
  const dispatch = useGlobalContext()[1];

  function renderStatus() {
    var backgroundColor = "#FF8900";
  
    if(product.status === "valid") {
      backgroundColor = "#32A860";
    }
    if(product.status === "correction") {
      backgroundColor = "#F4D300";
    }
    if(product.status === "refused") {
      backgroundColor = "#FF0009";
    }
    return (
      <div className="product-status" style={{ backgroundColor,   boxShadow: `0px 0px 2px 2px rgba(236, 162, 92, 0.0)` }}></div>
    );
  
  };

  return useMemo(() => (
      <div>
        <div className="product-ref hide-on-tablet">
          <p>{product.ref}</p>
        </div>
        <div className="product-brand hide-on-tablet">
          <p>{product.brand}</p>
        </div>
        <div className="product-gencod hide-on-tablet">
          <p>{product.gencod}</p>
        </div>
        <div className="product-packing hide-on-tablet">
          <p>{product.packing}</p>
        </div>
        <div className="product-qty hide-on-tablet">
          {product.packing === 'box' ? (
            <p>{`${product.count} box`}</p>
          ) : (
            <p>{product.count * product.packing} UV</p>
          )}
        </div>
        <div className="product-price hide-on-tablet">
          <p>{product.price ? `${fnFormat(product.price)}€` : '---'}</p>
        </div>
        <div className="product-ref-brand only-on-tablet">
          <p>Réf : {product.ref}</p>
          <p>Gencod : {product.gencod}</p>
          <p>{product.brand}</p>
        </div>
        <div className="product-packing-qty only-on-tablet">
          {product.packing === 'box' ? (
            <p>Qté commandée en colis : {`${product.count} box`}</p>
          ) : (
            <p>Qté commandée en colis : {`${product.count * product.packing} UV (${product.count}x${product.packing})`}</p>
          )}
          <p>Colisage : {product.packing}</p>
          <p>{product.price ? `${fnFormat(product.price)}€` : '---'}</p>

        </div>
        {renderStatus()}
      </div>
  ), [product]);
}
