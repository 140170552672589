import React, { createContext, useContext, useReducer } from 'react';

export const GlobalContext = createContext({
  name: '',
});

export const GlobalProvider = function ({ initialState, reducers, children }) {
  return (
    <GlobalContext.Provider value={useReducer(reducers, initialState)}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
