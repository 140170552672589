import React, { useState, useEffect } from 'react';
import useDimension from '../../../../customHooks/useDimension';

export default function Block(props) {
  const dimension = useDimension();
  const [isOpen, setIsOpen] = useState(false);
  // const [isMobile, setIsMobile] = useState(dimension.width < 768)

  // useEffect(() => {

  //   if (!isMobile && dimension.width < 768) {
  //     setIsMobile(true)
  //   } else if (isMobile && dimension.width >= 768) {
  //     setIsMobile(false)
  //   }

  // }, [dimension.width])

  // function renderClass() {
  //   let open = isOpen ? "open" : ""
  //   return isMobile ? "title-mobile " + open : ""
  // }

  function renderPastille() {
    let className;
    if (props.domains.includes('OCCASION')) {
      className = 'pastille violet';
    } else if (props.stand === '0') {
      className = 'pastille expert';
    } else if ((props.domains[0] === 'MULTIMEDIA' || props.domains[1] === 'MULTIMEDIA')
      && (props.domains[0] === 'MAISON' || props.domains[1] === 'MAISON')) {
      className = 'pastille multimedia-maison';
    } else if (
      props.domains[0] === 'ACHATS INDIRECTS'
      || props.domains.includes('EXPERTISE')
      || props.domains.includes('ECONOMAT')
    ) {
      className = 'pastille green';
    } else if (props.domains[0] === 'MAISON') {
      className = 'pastille maison';
    } else if (props.domains[0] === 'MULTIMEDIA') {
      className = 'pastille multimedia';
    }
    return <div className={className}></div>;
  }

  return (
    <div className="block">
      {props.image && isOpen
        ? <img src={props.image} alt={props.title} /> : null
      }
      <h3 >
        <span>
          <div className="sub-info">
            {(props.stand || props.stand === 0) && <div className="stand">
              <p>
                {props.stand === '0' ? 'Expertise' : props.stand}
              </p>
              {renderPastille()}
            </div>}
            <p>
              {props.provider}
              {!isOpen && props.banner
                && <span className='banner hide-on-desktop'><br />{props.banner}</span>
              }
            </p>
          </div>
        </span>
        {console.log(props, props?.files.length > 0 || props.banner)}
        {props?.files.length > 0 || props.banner ? (
          <a onClick={() => setIsOpen(!isOpen)} className={isOpen ? 'show active' : 'show'}></a>
        ) : null }
      </h3>
      {isOpen ? props.children : null}

    </div>
  );
}
