import _ from 'lodash';

import {
  GET_ORDER_BY_ID,
  GET_ORDERS,
  CREATE_ORDER,
  UPDATE_ORDER,
  VALIDATE_ORDER,
  VALIDATE_ORDER_ERROR,
  RESET_CONFIRMED_ORDER,
  LOADING_ORDERS,
  // ERROR_ORDERS,
  SET_PRODUCTS_FILTERS,
  GET_PRODUCTS,
  GET_LIST_FILTERS,
  GET_PRODUCTS_FLASH,
  RESET_SELECTED_PRODUCT,
  GET_PRODUCT_BY_ID,
  PUT_PRODUCT,
  POST_PRODUCT,
  DELETE_PRODUCT,
  PRODUCTS_LOADING,
  RESET_ERROR_PRODUCTS,
  ERROR_PRODUCTS,
  AUTH_LOGOUT,
} from '../actions/types';

import { PRODUCTS_PER_REQUEST } from '../constant';

const initialState = {
  productsFlash: null,
  products: null,
  orders: null,
  order: null,
  confirmedOrder: null,
  product: null,
  listFilters: null,
  totalCount: null,
  filters: {
    search: null,
    sort: {},
    type: null,
    provider: null,
    page: null,
  },
  isLoading: false,
  isOrderLoading: false,
  error: null,
};

let updatedOrders;
let updatedOrder;
let updatedProducts;

export default function productsReducer(state = initialState, action) {
  switch (action.type) {
    ///
    case GET_ORDER_BY_ID:
      return { ...state, isOrderLoading: false, order: action.payload };
    case GET_ORDERS:
      updatedOrders = action.payload;
      updatedOrders = updatedOrders.filter((d) => d.products.length > 0);
      // IT SHOULDNT POSSIBLE TO HAVE AN ORDER WITHOUT PRODUCTS
      return { ...state, isOrderLoading: false, orders: updatedOrders };
    case CREATE_ORDER:
      updatedOrder = action.payload;
      if (state.orders) {
        updatedOrders = [...state.orders, updatedOrder];
      } else {
        updatedOrders = [updatedOrder];
      }
      return { ...state, isOrderLoading: false, orders: updatedOrders };
    case UPDATE_ORDER:
      updatedOrder = action.payload;
      if (action.payload.products) {
        updatedOrders = _.unionBy([updatedOrder], state.orders, '_id');
      } else {
        updatedOrders = state.orders.filter((order) => order._id !== updatedOrder._id);
      }

      return { ...state, isOrderLoading: false, orders: updatedOrders };
    case VALIDATE_ORDER:
      updatedOrders = state.orders.map((order) => {
        if (order._id === action.payload._id) {
          updatedOrder = { ...order, status: 'confirmed' };
          return updatedOrder;
        }
        return order;
      });
      return {
        ...state, isOrderLoading: false, orders: updatedOrders, order: updatedOrder, confirmedOrder: `order-${action.payload._id}`,
      };
    case VALIDATE_ORDER_ERROR:
      return { ...state, confirmedOrder: 'order-failed' };
    case RESET_CONFIRMED_ORDER:
      return { ...state, confirmedOrder: null };
    case LOADING_ORDERS:
      return { ...state, isOrderLoading: true };
    ///
    case GET_PRODUCTS_FLASH:
      return { ...state, productsFlash: action.payload, isLoading: false };
    case GET_PRODUCTS:
      if (action.payload.providerProducts) {
        updatedProducts = action.payload.products;
      } else {
        if (action.payload.page === 1) {
          updatedProducts = action.payload.products;
        } else if (action.payload.page > 1 && Array.isArray(state.products) && state.products.length > action.payload.page * PRODUCTS_PER_REQUEST) {
          const targetIndex = (action.payload.page - 1) * PRODUCTS_PER_REQUEST;
          const slice = state.products.splice(0, targetIndex);
          updatedProducts = [...slice, ...action.payload.products];
        } if (action.payload.page > 1 && Array.isArray(state.products) && state.products.length < action.payload.page * PRODUCTS_PER_REQUEST) {
          updatedProducts = [...state.products, ...action.payload.products];
        }
      }
      return {
        ...state,
        ca: action.payload.providerProducts ? action.payload.ca : null,
        products: updatedProducts,
        totalCount: action.payload.count,
        isLoading: false,
      };
    case GET_LIST_FILTERS:
      const { products, companies } = action.payload;
      let optionsProducts = [];
      if (products) optionsProducts = products.map((d) => ({ value: d, label: d }));
      return { ...state, listFilters: { products: optionsProducts, companies } };
    case SET_PRODUCTS_FILTERS:
      return { ...state, filters: action.payload };
    case RESET_SELECTED_PRODUCT:
      return { ...state, product: null, isLoading: false };
    case GET_PRODUCT_BY_ID:
      return { ...state, product: action.payload, isLoading: false };
    case PUT_PRODUCT :
      // reset products to load them again with update data
      // do it because of missing props order in response
      return {
        ...state,
        error: null,
        product : action.payload,
        products: null,
        isLoading : false,
      };
      case POST_PRODUCT :
      // reset products to load them again with update data
      // do it because of missing props order in response
      return {
        ...state,
        error: null,
        product : null,
        products: null,
        isLoading : false,
      };
    case DELETE_PRODUCT :
      return {
        ...state,
        product : null,
        products: state.products.filter((product) => product._id !== action.payload),
        isLoading : false,
      };
    case PRODUCTS_LOADING:
      return { ...state, isLoading: true };
    case AUTH_LOGOUT:
      return { ...initialState };
    case RESET_ERROR_PRODUCTS : 
      return {...state };
    case ERROR_PRODUCTS:
      return { ...state, error: action.payload.error, isLoading: false };
    default:
      return { ...state };
  }
}

function replaceByProduct(products, arrOfId) {
  const arrProducts = arrOfId.map((id) => {
    const product = products.find((product) => product._id === id);
    return product;
  });
  return arrProducts;
}
