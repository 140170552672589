import {
  // GET COMPANY CONTAIN ANIMATIONS
  GET_ANIMATIONS,
  GET_COMPANY,
  ADD_ANIMATION,
  UPDATE_ANIMATION,
  DELETE_FILE,
  LOADING_ANIMATION,
  ERROR_ANIMATION,
} from '../actions/types';

const initialState = { animations: null, isLoading: false, error: null };

let updateAnimation;

export default function animationReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COMPANY:
    case GET_ANIMATIONS:
      return { ...state, animations: action.payload.animations, isLoading: false };
    case ADD_ANIMATION:
      updateAnimation = state.animations ? [...state.animations] : [];
      updateAnimation = updateAnimation.filter((d) => d._id !== action.payload.animation._id);
      updateAnimation.push(action.payload.animation);
      return { ...state, animations: updateAnimation, isLoading: false };
    case UPDATE_ANIMATION:
      updateAnimation = [...state.animations];
      updateAnimation = updateAnimation.filter((d) => d._id !== action.payload.animation._id);
      updateAnimation.push(action.payload.animation);
      return { ...state, animations: updateAnimation, isLoading: false };
    case DELETE_FILE:
      updateAnimation = [...state.animations];

      updateAnimation.map((animation) => {
        if (!animation.dimensionP && !animation.dimensionL) {
          animation.files = animation.files.filter((file) => file !== action.payload);
          return animation;
        }
        animation.files = animation.files.filter((file) => file !== action.payload);
        return animation;
      });

      return { ...state, animations: updateAnimation, isLoading: false };
    case LOADING_ANIMATION:
      return { ...state, isLoading: action.origin ? action.origin : true };
    case ERROR_ANIMATION:
      return { ...state };
    default:
      return { ...state };
  }
}
